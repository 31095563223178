YUI.add('case-editor-app-search', function(Y) {
    "use strict";

    Y.CaseEditorAppSearch = Y.Base.create('CaseEditorAppSearch', Y.smst.CaseEditorAppBase, [], {

        template: null,

        initializer: function() {
            var instance = this;

            Y.once('smartsteuer:ready', function (config) {
                instance.config = config
            }, instance);
        },

        render : function() {
            this.constructor.superclass.render.apply(this);

            this.template = Y.Node.create(
                Y.HandlebarsTemplates.lookup('case-editor-search-template')()
            );
        },

        handleNavigation : function(navInfo) {
            this.fire('waiting',{msg:'Bitte warten…'});

            var term = navInfo.searchTerm;

	        // double check the term.
	        if(term.length < 3) {
                 this.get('container').setHTML('<h4>Bitte geben Sie mindestens 3 Zeichen ein.</h4>');
                 return;
            }

            var cfg = {
                 on: { success: function(id, o) {
                         this.fire('removeWaiting');
                         this.handleSuccess(id, o);
                     },
                     failure: function(id, o) {
	                     var error = JSON.parse(o.responseText);
                         this.fire('alert', {msg:error.message});
                     }
                 },
                 context: this,
                 method: 'POST',
                 data: {
                         searchTerm: term,
                         showCase: 'on',
                         showFields: 'on',
                         showHiddenFields: 'on'
                 }
            };

            Y.io("api/search", cfg);

            return this;
         },

	    /**
	     * Here we don't know where 'next' should go to. So we simply ignore any clicks on such a button.
	     * We simply overwrite our parents method and do nothing.
	     */
	    handleNext : function() {
		    // nothing to do. There is no 'next' in our case.
	    },

        /*
        _createLink : function(area) {
            return this._interviewBaseUrl +
                   "/"+area.id+
                   "/"+area.formId+
                   "/"+area.formIndex+
                   "/"+(area.multiFieldIndex != undefined ? area.multiFieldIndex : '-1')+
                   "/" + (area.multiFieldIndex != undefined ? 'true' : 'false')+
                   "/" + ((area.type != undefined && area.type == "multiFormBase") ? 'false' : 'true');
        },
         */
         appendArray : function(root, searchTerm, results, label, hidden, doNotShowZeroResults) {

             var link = Y.smst.CaseEditor.SECTIONS.getByName('interview').get('link'),
		         category,
	             container = this.template.one('.case-editor-search-result-item-action');

             if(results && (results.length > 0 || !doNotShowZeroResults)) {

	             category = Y.Node.create('<li class="case-editor-search-result-category-item">');
                 root.appendChild(category);

                 this.template.one('.case-editor-search-result-category-result-count').setContent('Suchbegriff <strong>'+searchTerm+'</strong> &#8212; <em>' + results.length + '&times;</em> gefunden in <em>' + label + '</em>');
                 category.appendChild(this.template.one('.case-editor-search-result-category-result-count').cloneNode(true));
                 var res = category.appendChild(Y.Node.create('<ul></ul>'));

                 for(var i=0; i<results.length; i++) {
	                 container.setHTML("");
                     if(!hidden && results[i].disabled != 'true') {
	                     var areas = results[i].resultAreas;
	                     if (areas) {
                             // Search results for MF items get a slightly different markup, as we may have more than one link target to display
                             container.addClass('case-editor-search-result-item-action-mf');
                             container.appendChild('<h3>Zum Bereich springen für:</h3>');
                             // We build a list which will contain the link buttons for the MF items
                             var mfresults = container.appendChild(Y.Node.create('<ul></ul>'));
		                     for (var j=0; j<areas.length; j++) {
			                     var href = link+'/'+areas[j].id+'/'+(areas[j].formId ? areas[j].formId : -1)+'/'+(areas[j].formIndex ? areas[j].formIndex : 1);
			                     if(areas[j].multiFieldIndex != null && areas[j].multiFieldIndex >= 0) {
				                     href += '/'+areas[j].multiFieldIndex;
			                     } else {
				                     href += '/-1';
			                     }
			                     href += '/true/true';
                                 // Remove the () from around the item label; we don't need it here
                                 var _label = areas[j].linkLabel.replace(/^\(/, '').replace(/\)$/, '');
			                     mfresults.appendChild(Y.Node.create('<li><a href="' + href + '" class="btn openArea case-editor-search-result-item-link">' + _label + ' <i class="fa fa-chevron-right"></i></a></li>'));
		                     }
	                     } else {
                             // Even if we have no MF areas for this search query, the mf-hit class is still being set (or left over?) on the results, so we remove it here
                             container.removeClass('case-editor-search-result-item-action-mf');
		                     var href = link+'/'+results[i].areaId+'/'+(results[i].formId ? results[i].formId : -1)+'/'+(results[i].formIndex ? results[i].formIndex : 1);
		                     if(typeof results[i].multiFieldIndex !== typeof undefined) {
			                     href += '/'+results[i].multiFieldIndex + '/true/true';
		                     }
		                     container.appendChild(Y.Node.create('<a href="' + href + '" class="btn openArea case-editor-search-result-item-link">Zum Bereich springen <i class="fa fa-chevron-right"></i></a><br>'));
	                     }
                     } else {
                        // This previously inserted the area name (as results[i].areaPath) into the alert text,
                        // but that can get very long and makes the alert very hard to read. Removed it -- nh
	                     container.removeClass('case-editor-search-result-item-action-mf');
	                     var invisibleMsg = "Dieser Bereich ist in Ihrem Steuerfall nicht sichtbar,"
                         + " daher ist ein Sprung an diese Stelle nicht möglich.\\n\\n" // need double backslash here to make linebreak work :)
                         + "Wenn Sie entsprechende Angaben machen, durch die dieser Bereich aktiviert wird,"
                         + " kann von diesem Suchergebnis aus dorthin gesprungen werden.";
	                     container.appendChild(Y.Node.create('<a href="#" class="btn openArea case-editor-search-result-item-link">Zum Bereich springen <i class="fa fa-chevron-right"></i></a><br>'));
	                     this.template.one('.case-editor-search-result-item-link').set('href', 'javascript:alert(\''+invisibleMsg+'\');');
                     }

                     this.template.one('.case-editor-search-result-item-title').setContent(results[i].area + " &rarr; " + results[i].label);
                     this.template.one('.case-editor-search-result-item-text').setContent(results[i].text);

                     res.appendChild(this.template.one('.case-editor-search-result-category-item .case-editor-search-result-item').cloneNode(true));
                 }
             }
         },

         handleSuccess : function(id, o) {

             var json = Y.JSON.parse(o.responseText);

             var root = Y.Node.create('<ul class="case-editor-search-result-category-list"></ul>');

             if(!json.interviewFields && !json.hiddenFields && !json.caseFields) {
                 this.template.one('.case-editor-search-result-category-item-no-results').setContent('Bitte wählen Sie mindestens einen Bereich!');
                 root.appendChild(this.template.one('.case-editor-search-result-category-item-no-results').cloneNode(true));
             }
             else {
                 this.appendArray(root, json.searchTerm, json.elsterFields, 'Elsterfeldern', false, true);
                 this.appendArray(root, json.searchTerm, json.interviewFields, 'Interview', false);
                 this.appendArray(root, json.searchTerm, json.hiddenFields, 'Bereichen, die in Ihrem Fall nicht aktiviert (sichtbar) sind', true);
                 this.appendArray(root, json.searchTerm, json.caseFields, 'Ihren Angaben', false);
             }

             var sub = Y.Node.create('<div></div>');
             sub.appendChild(root);

             this.get('container').setHTML(sub.getHTML());
         }



    }, {
        ATTRS : {

            container: {
                valueFn: function(){
                    // was: #case-search --> this is already in use by the search form in the case editor header!
                    return Y.Node.create('<div id="case-editor-search-result"/>');
                }
            }
        }
    });



}, '1.0.0', {
    requires:[
        'app',
        'base-build',
        'case-editor-app-base',
        'io-base',
        'node',
        'smst-lang'
    ]});