YUI.add('case-editor-app-interview-reports', function(Y) {
	"use strict";

	var Reports = Y.Base.create('CaseEditorAppInterviewReports', Y.Base, [], {

		_eventHandlers : [],

		initializer: function() {
			Y.onceAfter('smartsteuer:ready', this._initialize, this);
		},

		_initialize: function(config) {
			Y.log("Initializing inline reports", "DEBUG", "CaseEditorAppInterviewReports");

            this._eventHandlers.push(Y.on('interview:rendered', this._initReports, this));
            this._eventHandlers.push(Y.on('interview:summaryRendered', this._initReports, this));
		},

        destructor : function() {
            Y.smst.Lang.detachEventListener(this._eventHandlers);
        },

        _initReports : function() {

			Y.log("Handling reports after page rendered", "DEBUG", "CaseEditorAppInterviewReports");

			if(Y._currentState) {

				for(var i=0; i<Y._currentState.reports.length; i++) {

					var node = '<li class="case-editor-inline-report" data-id="'+ Y._currentState.reports[i]+'"'+
						' id="case-editor-inline-report-'+ Y._currentState.reports[i]+'"></li>';

					if(Y.one("#case-interview-form")) {

                        if (Y._currentState.reportPositions[i] == "0") {
                            Y.one("#case-interview-form").insert(
                            	'<ul style="margin-bottom: 20px;">' + node + '</ul>',
								"before"
							);
                        }
                        else if (Y._currentState.reportPositions[i] == "-1") {
                            Y.one('#case-editor-continue-container').insert("<ul>" + node + "</ul>", "before");
                        }
                        else {
							Y.one("#field_"+Y._currentState.reportPositions[i]).ancestor("li").insert(node, "after");
                        }
                    }
                    else  {
                    	Y.one('#case-editor-continue-container').insert(node, "before");
					}
				}

                this._loadInlineReports(Y.one('#case-editor-main'));
			}
		},

        _loadInlineReports : function(parent) {

			parent.all('.case-editor-inline-report').each( function (node) {
				var id = node.getData('id'),
					ioConfig;

				ioConfig = {
					on: {
						start: function(){
							node.setHTML('<h1 id="case-editor-waiting">Bitte warten…</h1>');
						},
						end: function(){
							node.all('.case-editor-waiting').remove(true);
						},
						success: function(id, o) {
							var response = Y.one(Y.config.doc.createDocumentFragment()),
								trimmedResponse;
							response.setHTML(o.responseText);
							trimmedResponse = response.one('.case-editor-report-table-container');
							node.setHTML(trimmedResponse);
							this.fire('reports-rendered');
						}
					}
				};
				Y.io('reports/render.html?useSessionCase=true&id='+id, ioConfig);
			});
		}


	}, {
		ATTRS : {
		}
    });

	Y.CaseEditorAppInterviewReports = new Reports();

}, '1.0.0', {
	requires:[
		'base-build',
		'node',
		'smart-handlebars-templates'
	]});
