// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-filing2', function (Y) {
    "use strict";

    Y.CaseEditorAppFiling2IntroductionView = Y.Base.create('CaseEditorAppFiling2troductionView', Y.HandlebarsView, [], {

        template: 'case-editor-filing2-introduction',

        render: function () {
            var that = this;
            that._render({
                filingState: that.get('filingState'),
                hasEuer: Y._currentState && Y._currentState.hasEuer
            });
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-preview" />');
                }
            }
        }
    });

    Y.CaseEditorAppFiling2PreviewView = Y.Base.create('CaseEditorAppFiling2PreviewView', Y.HandlebarsView, [], {

        template: 'case-editor-filing2-preview',

        render: function () {
            var that = this;
            that._render({
                filingState: that.get('filingState'),
                assessmentInfo: that.get('assessmentInfo'),
                csrfT: that.get('csrfT'),
                hasEuer: Y._currentState && Y._currentState.hasEuer,
                hasBusinessCase: Y._currentState && Y._currentState.hasBusinessCase
            });
        }

    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-preview" />');
                }
            },
            assessmentInfo: {},
            csrfT: {}
        }
    });

    Y.CaseEditorAppFiling2ProductView = Y.Base.create('CaseEditorAppFiling2ProductView', Y.HandlebarsView, [], {

        template: 'case-editor-filing2-product',

        events: {
            'label': {click: '_setLoading'}
        },

        _setLoading: function (e) {
            this.get('container').one('.case-editor-filing-module').addClass('is-loading');
        }

    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-product" />');
                }
            },
            filingState: {}
        }
    });

    Y.CaseEditorAppFiling2FilingView = Y.Base.create('CaseEditorAppFiling2FilingView', Y.HandlebarsView, [], {

        template: 'case-editor-filing2-filing',

        partials: ['case-editor-checkout-product',
            'case-editor-filing2-_enterCheckout',
            'case-editor-filing2-_startAuthentication',
            'case-editor-filing2-elsterNotAvailable'
        ],

        render: function () {
            var that = this;

            that._render({
                filingState: that.get('filingState'),
                stateInfo: that.get('stateInfo'),
                product: that.get('productInfo'),
                hasEuer: Y._currentState && Y._currentState.hasEuer,
                hasBusinessCase: Y._currentState && Y._currentState.hasBusinessCase
            });
        }

    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-filing" />');
                }
            },
            stateInfo: {},
            productInfo: {}
        }
    });

    Y.CaseEditorAppFiling2SuccessView = Y.Base.create('CaseEditorAppFiling2Success', Y.HandlebarsView, [], {

        template: 'case-editor-filing2-success'

    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-success" />');
                }
            }
        }
    });

    Y.CaseEditorAppFiling2ElsterIISuccessView = Y.Base.create('CaseEditorAppFiling2ElsterIISuccess', Y.HandlebarsView, [], {

        template: 'case-editor-filing2-successElster2'

    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-success" />');
                }
            }
        }
    });

    Y.CaseEditorAppFiling2FinalView = Y.Base.create('CaseEditorAppFiling2FinalView', Y.HandlebarsView, [], {

        template: 'case-editor-filing2-final',
        partials: [
            'case-editor-filing2-notSentWarning',
            'case-editor-filing2-notSentViaMailWarning',
            'case-editor-filing2-elsterNotAvailable'
        ],
        render: function () {
            var that = this;

            that._render({
                stateInfo: that.get('stateInfo'),
                filingState: that.get('filingState'),
                hasTaxArrears: that.get('hasTaxArrears'),
                hasEuer: Y._currentState && Y._currentState.hasEuer,
                smartsteuer: that.get('smartsteuer'),
                transferDecreeData: that.get('transferDecreeData'),
                filingLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link'),
                sendLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')
            });

            Y.one('#case-editor-main-header').setStyle('display', 'none');
            Y.one('#case-editor-main-body').setStyle('marginTop', '10px');

            Y.onceAfter('CaseEditor:activeSectionChange', function () {
                if (Y.one('#case-editor-main-header')) {
                    Y.one('#case-editor-main-header').setStyle('display', '');
                    Y.one('#case-editor-main-body').setStyle('marginTop', '0px');
                }
            });
        }

    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-final" />');
                }
            },
            stateInfo: {},
            hasEuer: {
                getter: function () {
                    return Y._currentState && Y._currentState.hasEuer;
                }
            },
            hasTaxArrears: {
                getter: function () {
                    return Y._currentState.hasTaxArrears;
                }
            },
            isShortInterview: {
                getter: function () {
                    return Y._currentState.isShortInterview;
                }
            },
            isShortInterviewUsingLongOption: {
                getter: function () {
                    return Y._currentState.isShortInterviewUsingLongOption;
                }
            },
            transferDecreeData: {
                getter: function () {
                    return Y._currentState.transferDecreeData || Y._currentState.transferDecreeData2;
                }
            },
            smartsteuer: {
                getter: function () {
                    return Y._config.smartsteuer;
                }
            }
        }
    });

    Y.CaseEditorAppFiling2SmartpayView = Y.Base.create('CaseEditorAppFiling2SmartpayView', Y.HandlebarsView, [Y.AppOverlays], {

        template: 'case-editor-filing2-smartpay',

        overlays: {
            'sepa-info': {
                header: "Vorschau: SEPA Lastschriftmandat",
                footer: '<button type="button" class="btn btn-hilited" id="ui-sepa-info-ok">OK</button>',
                template: 'case-editor-filing2-overlay-sepa',
                events: {
                    '#ui-sepa-info-ok': {
                        click: function (e, payload) {
                            e.halt();
                            payload.overlay.hide();
                        }
                    }
                },
                // don't add escape listeners and buttons
                notEscapable: false
            }
        },

        events: {
            '#ui-smartpay-step1': {
                click: function (e) {
                    e.halt();
                    Y.one('#ui-smartpay-info-step1').setStyle('display', 'none');
                    Y.one('#ui-smartpay-info-step2').setStyle('display', 'block');
                    Y.one('#ui-smartpay-info-step3').setStyle('display', 'none');
                }
            },
            '#ui-smartpay-step2': {
                click: function (e) {
                    e.halt();

                    if (!Y.one("input[name=payout]:checked")) {
                        alert('Bitte wählen Sie einen Betrag.');

                        return;
                    }

                    var value = Y.one(" input[name=payout]:checked").get("value");
                    Y.one('#smartpay-payout-selected').set('value', value);
                    Y.one('#smartpay-payout-selected-label').setContent(value);

                    Y.one('#ui-smartpay-info-step1').setStyle('display', 'none');
                    Y.one('#ui-smartpay-info-step2').setStyle('display', 'none');
                    Y.one('#ui-smartpay-info-step3').setStyle('display', 'block');

                    if (Y.one('.case-editor-smartpay-teaser')) {
                        Y.one('.case-editor-smartpay-teaser').setStyle('display', 'none');
                    }
                }
            },
            '#ui-smartpay-step3': {
                click: function (e) {
                    e.halt();

                    if (!Y.one("#acceptSepa").get("checked")) {
                        alert('Bitte erteilen Sie das SEPA-Lastschriftmandat.');

                        return;
                    }

                    if (!Y.one("#acceptSmartpay").get("checked")) {
                        alert('Bitte akzeptieren Sie die Bedingungen bzgl. des Widerrufsrechts.');

                        return;
                    }

                    Y.one('#ui-smartpay-info-step1').setStyle('display', 'none');
                    Y.one('#ui-smartpay-info-step2').setStyle('display', 'none');
                    Y.one('#ui-smartpay-info-step3').setStyle('display', 'none');
                    Y.one('#ui-smartpay-info-waiting').setStyle('display', 'block');

                    Y.io("api/smartpay/accept", {
                            method: 'GET',
                            on: {
                                success: function (id, o) {
                                    Y.one('#ui-smartpay-info-waiting').setStyle('display', 'none');
                                    Y.one('#ui-smartpay-info-step4').setStyle('display', 'block');

                                    Y.one('#ui-go-to-final').setContent('Weiter');
                                },
                                failure: function () {
                                    alert("Es ist ein Fehler aufgetreten - bitte wenden Sie sich an den Support!");
                                }
                            },
                            context: this,
                            data: {
                                "payout": Y.one(" input[name=payout]:checked").get("value")
                            }
                        }
                    );
                }
            },
            '#ui-smartpay-sepa-overlay': {
                click: function (e) {
                    e.halt();

                    this.showOverlay(
                        'sepa-info',
                        null, {
                            info: this.get('info'),
                            date: new Date().toLocaleDateString('de-DE')
                        }
                    );
                }
            }
        },

        render: function () {
            var that = this;

            Y.io("api/smartpay/info", {
                    method: 'GET',
                    on: {
                        success: function (id, o) {
                            var info = JSON.parse(o.responseText);
                            that.set('info', info);

                            that._render({
                                filingState: that.get('filingState'),
                                info: info
                            });
                        },
                        failure: function () {
                            alert("Es ist ein Fehler aufgetreten - bitte wenden Sie sich an den Support!");
                        }
                    },
                    context: that
                }
            );
        }
    }, {
        ATTRS: {
            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-editor-filing-smartpay" />');
                }
            },
            info: {
                value: null
            }
        }
    });

    Y.CaseEditorAppFiling2 = Y.Base.create('CaseEditorAppFiling2', Y.smst.CaseEditorAppBase, [Y.AppOverlays], {

        vueApp: null,

        _runningRequests: [],

        views: {
            introduction: {type: 'CaseEditorAppFiling2IntroductionView'},
            preview: {type: 'CaseEditorAppFiling2PreviewView'},
            product: {type: 'CaseEditorAppFiling2ProductView'},
            filing: {type: 'CaseEditorAppFiling2FilingView'},
            smartpay: {type: 'CaseEditorAppFiling2SmartpayView'},
            success: {type: 'CaseEditorAppFiling2SuccessView'},
            successElster2: {type: 'CaseEditorAppFiling2ElsterIISuccessView'},
            documents: {
                type: Y.CaseEditorAppFiling2Documents
            },
            'final': {type: 'CaseEditorAppFiling2FinalView'}
        },
        overlays: {
            'confirm-e-file': {
                header: "Daten übermitteln",
                footer: '<button type="button" id="e-file-confirm" class="btn btn-hilited">Daten jetzt übermitteln</button>',
                template: 'case-editor-filing2-overlay-confirm',
                events: {
                    '#e-file-confirm': {click: '_doEFileCase'},
                    '.yui3-widget': {keypress: '_doEFileCase'}
                }
            },
            'what-next': {
                header: "Wie geht es weiter?",
                width: 700,
                footer: '<button type="button" class="btn btn-hilited" id="ui-what-next-ok">OK</button>',
                template: 'case-editor-filing2-whatNextOverlay',
                events: {
                    '#ui-what-next-ok': {click: '_closeOverlay'},
                    '.yui3-widget': {keypress: '_closeOverlay'}
                },
                plugins: [
                    {
                        fn: Y.gorilla.widget.CancelButtonPlugin,
                        cfg: {
                            template: '<button type="button" class="btn ui-cancel-btn ui-display-none">Ok</button>',
                            section: Y.WidgetStdMod.FOOTER
                        }
                    }
                ],
                notEscapable: false
            },
            'filing-error': {
                header: "Es ist ein Fehler aufgetreten.",
                footer: '<button type="button" class="btn btn-hilited" id="ui-filing-error-ok">OK</button>',
                template: 'case-editor-filing2-overlay-filingError',
                events: {
                    '#ui-filing-error-ok': {click: '_closeOverlay'},
                    '.yui3-widget': {keypress: '_closeOverlay'},
                    '#ui-show-error-message': {
                        click: function () {
                            Y.one('#ui-error-message').setStyle('display', '');
                            Y.one('#ui-show-error-message').setStyle('display', 'none');
                        }
                    },
                    '#ui-hide-error-message': {
                        click: function () {
                            Y.one('#ui-error-message').setStyle('display', 'none');
                            Y.one('#ui-show-error-message').setStyle('display', '');
                        }
                    }
                },
                // don't add escape listeners and buttons
                notEscapable: false
            }
        },
        events: {
            '#ui-go-to-preview': {
                click: function (e) {
                    e.halt();
                    this.fire('open', {link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')});
                }
            },
            '.ui-choose-product': {
                click: function (e) {
                    e.halt();
                    this._chooseProduct()
                }
            },
            '#ui-go-to-filing-after-product': {
                click: function (e) {
                    e.halt();
                    this._chooseProduct(Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link'));
                }
            },
            '#ui-go-to-filing': {
                click: function (e) {
                    e.halt();
                    this.fire('open', {link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')});
                }
            },
            '#ui-go-to-print': {
                click: function (e) {
                    e.halt();
                    this.fire('open', {link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/print').get('link')});
                }
            },
            '#ui-go-to-elster2-finish': {
                click: function (e) {
                    e.halt();

                    var that = this;

                    that.fire('waiting', {msg: 'Bitte warten…'});

                    Y.io("elster/getFilingState", {
                            method: 'GET',
                            on: {
                                success: function (id, o) {
                                    that._updateFilingState(o, that);

                                    that.fire('removeWaiting');

									if(!that.get('filingState').sentWithElsterII) {
                                        var nextSection = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-final').get('link');

                                        //nothing left to do, mark subsections as ok
                                        Y.io('elster/saveInterviewSessionParam', {
                                            context: this,
                                            on: {
                                                success: function (id, o) {
                                                    //then go directly to finish
                                                    that.fire('open', {link: nextSection});
                                                }
                                            },
                                            data: {
                                                printed: 'true',
                                                signed: 'true',
                                                receiptsOk: 'true',
                                                sent: 'true'
                                            }
                                        });
                                    } else {
                                        //normal way, user has to send stuff per mail
                                        that.fire('open', {link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/print').get('link')});
                                    }
                                }
                            },
                            context: that
                        }
                    );

                }
            },
            '#ui-go-to-final': {
                click: function (e) {
                    e.halt();
                    this.fire('open', {link: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-final').get('link')});
                }
            },
            '#ui-do-elster-filing': {click: '_eFileCase'},
            '#ui-do-elster-filing-again': {click: '_eFileCase'},
            '#ui-start-checkout': {click: '_startCheckout'},
            '#ui-handle-yes-button': {click: '_handleYesButton'},
            '#ui-filing2-show-what-next': {
                click: function (e) {
                    e.halt();
                    this.showOverlay('what-next', null, {
                        hasEuer: Y._currentState && Y._currentState.hasEuer,
                        hasTaxArrears: Y._currentState && Y._currentState.hasTaxArrears
                    });
                }
            },
            // TODO: we should automatically check if data arrived
            '#ui-authentication-check': {
                click: function (e) {
                    window.location.reload(true);
                }
            },
            '#ui-why-authentication': {
                click: function (e) {
                    var yesMessage = "";
                    if (Y._currentFilingState.yesServiceURL) {
                        yesMessage = "<li>Sparkassenkunden und Kunden von Volksbanken Raiffeisenbanken können sich durch" +
                            " Online-Banking identifizieren. Voraussetzung: Die Bank nimmt bereits an diesem Service teil." +
                            "</li>"
                    }
                    this.fire('alert', {
                        msg: '<div class="app-alert app-alert-info"><i class="fa fa-info-circle"></i><div>' +
                            '<h3>Warum muss ich mich identifizieren?</h3>' +
                            '<p>Ihre Steuererklärung kann über smartsteuer digital und ohne Unterschrift auf ' +
                            'Papierdokumenten abgegeben werden. ' +
                            'Diese Art der digitalen Abgabe heisst "Elster 2". ' +
                            'Manchmal muss man sogar auf diese Art die Steuererklärung abgeben. Zum Beispiel dann, wenn man ' +
                            'eine "Gesonderte und einheitliche Feststelleung" eingereicht hat. ' +
                            '<p>' +
                            'In diesem Fall ist es notwendig dass wir Ihre Identität feststellen. ' +
                            'Sonst könnte nämlich jemand anders einfach in Ihrem Namen eine Steuererklärung abgeben.<p>' +
                            'So können Sie sich identifizieren:<p>' +
                            '<ul>' +
                            '<li>Sie zahlen mit PayPal und ' +
                            ' haben einen verifizierten PayPal-Account. ' +
                            '</li>' +
                            yesMessage +
                            '<li>' +
                            'Sie identifizieren sich mit Ihrer Webcam oder Ihrem Smartphone. Dazu werden Sie von einem ' +
                            '(menschlichen) Gesprächspartner kurz interviewt. Sie müssen Ihren Personalausweis zur Hand ' +
                            'haben und die Beleuchtung sollte ausreichend sein. ' +
                            '</li>' +
                            '</ul>' +
                            '<p>' +
                            '<p>Nachdem Sie sich auf eine dieser Arten ' +
                            'identifiziert haben, können Sie rein digital abgeben. ' +
                            'Leider gilt das nicht unbedingt auch für die Belege: Eventuell müssen Sie diese noch per Post ' +
                            'einreichen. ' +
                            'Auf jeden Fall wird aber Ihr Steuerfall schneller bearbeitet. ' +
                            '</p>' +
                            '<p>Auch gut: Die Identifizierung müssen Sie nur ein einziges Mal durchführen, sie gilt ' +
                            'auch für die Folgejahre.</p>' +
                            '<p>' +
                            'Haben Sie noch Fragen oder Probleme mit der Identifizierung? Wenden Sie sich gerne ' +
                            'an unseren Support: <br/>' +
                            '<p>' +
                            '<a href="mailto:hilfe@smartsteuer.de"><strong>hilfe@smartsteuer.de</strong></a>' +
                            '</p></div></div>'
                    });
                }
            }
            ,
            '#ui-yes-info': {
                click: function (e) {
                    this.fire('alert', {
                        msg: '<div style="max-width:960px; margin:0px auto">\n' +
                            '<h2 style="font-size:24px; font-weight:bold">Identifizieren durch Online-Banking</h2>\n' +
                            '<div style="text-align:center; display:flex; justify-content: space-between; padding:20px 0px">\n' +
                            '   <div>\n' +
                            '      <p><i class="fa fa-lock" style="font-size: 60px; color: #0080cc;" aria-hidden="true"></i></p>\n' +
                            '      <p style="font-weight: bold; color: #0080cc;"> Sicher -\n' +
                            '         <br>Ihre Daten sind verschlüsselt\n' +
                            '      </p>\n' +
                            '   </div>\n' +
                            '   <div>\n' +
                            '      <p><i class="fa fa-university" style="font-size: 60px; color: #0080cc;" aria-hidden="true"></i></p>\n' +
                            '      <p style="font-weight: bold; color: #0080cc; margin-top:5px" accesskey="" aria-hidden="true"> Einfach –\n' +
                            '         <br>Wie der Login bei Ihrer Bank\n' +
                            '   </p></div>\n' +
                            '   <div>\n' +
                            '      <p><i class="fa fa-clock-o" style="font-size: 60px; color: #0080cc;"></i></p>\n' +
                            '      <p style="font-weight: bold; color: #0080cc;"> Schnell -\n' +
                            '         <br>Keine lästigen Formulare\n' +
                            '      </p>\n' +
                            '   </div>\n' +
                            '</div>\n' +
                            '<hr>\n' +
                            '<p style="max-width: 600px; padding:20px 0px">Mit yes® können Sie sich einfach, schnell und rechtssicher durch Online-Banking identifizieren. ' +
                            'Voraussetzung: Sie sind Kunde bei einer Sparkasse oder Volksbank Raiffeisenbank die bereits an diesem Service teilnimmt. ' +
                            'Ihre Daten werden dabei weder weitergegeben noch gespeichert. </p>\n' +
                            '<hr>\n' +
                            '<h2 style="font-size:24px; font-weight:bold; padding:20px 0px">Das yes® Verfahren bei smartsteuer</h2>\n' +
                            '<div style="text-align:center; display:flex; justify-content: space-between;" id="spacer">\n' +
                            '   <div>\n' +
                            '      <span style="float:left; background: #0080cc; border-radius: 0.8em; -moz-border-radius: 0.8em; -webkit-border-radius: 0.8em; color: #ffffff; display: inline-block; font-weight: bold; line-height: 1.6em; margin-right: 5px; text-align: center; width: 1.6em;">1</span>\n' +
                            '      <p style="max-width: 300px; margin-top:50px; float-left"><span style="float:left; width:40%"><a href="#" class="btn btn-l btn-orange" style="word-spacing: 16px;">yes®</a></span></p><br>\n' +
                            '       <p style="float_left; max-width: 250px; padding-top:40px; text-align:left">' +
                            'Der Klick auf den Button bringt Sie zur Auswahl der Sparkassen und Volksbanken Raiffeisenbanken die bereits teilnehmen.</p>\n' +
                            '   </div>\n' +
                            '   <div> <span class="arrow arrow-right"></span> </div>\n' +
                            '   <div> <i class="fas fa-long-arrow-alt-right" style="font-size: 60px; color: #0080cc;"></i> </div>\n' +
                            '   <div style="text-align:left">\n' +
                            '      <span style="background: #0080cc; border-radius: 0.8em; -moz-border-radius: 0.8em; -webkit-border-radius: 0.8em; color: #ffffff; display: inline-block; font-weight: bold; line-height: 1.6em; margin-right: 5px; text-align: center; width: 1.6em; margin-bottom:25px">2</span>\n' +
                            '      <h3 style="font-size:20px">Ihre Hausbank</h3>\n' +
                            '      <p style="max-width: 250px; margin-top:19px">Ist Ihre Bank dabei? Sehr gut! Sie loggen sich bei Ihrer Bank ein und die Bank bestätigt Ihre Identität.</p>\n' +
                            '   </div>\n' +
                            '   <div> <i class="fas fa-long-arrow-alt-right" style="font-size: 60px; color: #0080cc;"></i> </div>\n' +
                            '   <div style="text-align:left">\n' +
                            '      <span style="background: #0080cc; border-radius: 0.8em; -moz-border-radius: 0.8em; -webkit-border-radius: 0.8em; color: #ffffff; display: inline-block; font-weight: bold; line-height: 1.6em; margin-right: 5px; text-align: center; width: 1.6em; margin-bottom:25px">3</span>\n' +
                            '      <h3 style="color: #0080cc; font-size:20px">smartsteuer</h3>\n' +
                            '      <p style="max-width: 250px; margin-top:19px">Wir erhalten die Bestätigung Ihrer Bank und können ab sofort Ihre Daten rein elektronisch an das Finanzamt übermitteln.</p>\n' +
                            '   </div>\n' +
                            '</div>\n' +
                            '</div>'
                    });
                }
            }
        },

        _cancelRunningRequests: function () {
            var that = this,
                requests = that._runningRequests,
                request;

            // reset the global field to not interfere with any ongoing async action.
            that._runningRequests = [];

            // now clean and cancel any running requests.
            if (requests) {
                while (0 < requests.length) {

                    request = requests.pop();
                    // make sure that this is cancelable.
                    request.abort && request.isInProgress() && request.abort();
                }
            }
        },

        /**
         * This will publish any events thrown by this class and register listeners on application events
         * (read: not DOM-events).
         */
        initializer: function () {
            var that = this;

            that.publish('newSubsection', {
                context: that,
                emitFacade: true
            });

            // we are going to cancel any running requests when ever a new section is activated.
            that.on('newSubsection', that._cancelRunningRequests, that);
        },

        destructor: function () {
            Y.all('.ui-case-editor-next').removeClass('btn-disabled').removeAttribute('disabled');
        },


        _closeOverlay: function (e, payload) {
            // only close on enter
            if (e.keyCode && 13 !== e.keyCode) {
                return;
            }
            e.halt();
            payload.overlay.hide();
        },

        render: function () {
            this.constructor.superclass.render.apply(this);

            Y.all('.ui-case-editor-next').addClass('btn-disabled').setAttribute('disabled', 'disabled');

            return this;
        },

        /**
         * This will publish new active sections to the case-editor. The information is needed to sync the navigation
         * accordingly. Also this app and any sub-apps (at this time only case-editor-app-filing2-documents) will cancel
         * any running requests to speed things up a little and to only show the most recent data.
         *
         * @param navInfo
         * @private
         */
        _activateSubSection: function (navInfo) {
            var area = navInfo.area,
                subArea = navInfo.subArea;

            // It is easier to handle all sub section activations here in one place. For the time being it is only
            // possible to go two levels deep. This should be enough for all of our use cases.
            if (area && !subArea) {
                this.fire('newSubSection', {section: 'filing2-process-' + area});
            } else if (subArea) {
                this.fire('newSubSection', {section: 'filing2-process-' + area + '/' + subArea});
            }
        },

        _updateFilingState: function (response) {
            var fs = Y.JSON.parse(response.responseText);
            this.set('filingState', fs);
            Y._currentFilingState = fs;
            return fs;
        },

        showView: function (view, config, options, callback) {
            this.constructor.superclass.showView.call(this, view, config, options, callback)
            // Y.one('#case-editor-main').addClass('case-editor-main-body')
            if (view === 'introduction') {
                Y.one('#case-editor-main').removeClass('case-editor-main-body')
                this._loadIntroductionApp()
            } else if (view === 'preview') {
                Y.one('#case-editor-main').removeClass('case-editor-main-body')
                this._loadPreviewApp()
            } else if (view === 'filing') {
                Y.one('#case-editor-main').removeClass('case-editor-main-body')
                this._loadCheckoutApp()
            } else if (view === 'final') {
                Y.one('#case-editor-main').removeClass('case-editor-main-body')
                this._loadFinalizedFilingApp()
            } else {
                if (view !== 'documents') {
                    // Y.one('#case-editor-main').addClass('case-editor-main-body')
                    Y.one('#case-editor-main-header').setStyle('display', '')
                    if (this.vueApp && Y.one('#app')) {
                        Y.one('#app').remove()

                        // Y.Node.create('<div id="case-filing"/>')
                        // this.vueApp.app.unmount()
                    }
                }
            }
        },

        // this is our new 'openArea'
        handleNavigation: function (navInfo) {
            Y.log('handleNavigation', 'DEBUG', 'CaseEditorAppFiling2');
            var that = this,
                cfg_assessment, cfg_filingState,
                area = navInfo.area;

            // remove default "Bitte warten..."
            that.fire('removeWaiting');
            // we will do an ajax request in a second, so we should indicate that we are waiting for its outcome.
            that.fire('waiting', {msg: 'Bitte warten…'});
            // as all these views rely on the filing state we will get it first with an ajax request
            // doing this here means we do this each time when moving

            // The navigation needs a little hint about the current section.
            // (case-editor only knows about top level sections)
            that._activateSubSection(navInfo);

            cfg_filingState = {
                method: 'GET',
                on: {
                    success: function (id, o) {
                        var fs = that._updateFilingState(o, that);
                        var data = {
                            filingState: fs,
                            filingLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link'),
                            sendLink: Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')
                        };
                        var blockWaiting = false;

                        if (!area) {
                            // The user clicked the parend node - we will show a short introduction to this process.
                            that.showView('introduction');
                        } else if ("preview" === area) {
                            cfg_assessment = {
                                method: 'GET',
                                on: {
                                    success: function (id, o) {
                                        var assessmentInfo = Y.JSON.parse(o.responseText);
                                        // after the preview was shown - we have to tell the server to adjust the server
                                        // state.
                                        that.showView("preview", {
                                                assessmentInfo: assessmentInfo,
                                                csrfT: that.config.tName + "=" + that.config.t
                                            }, function () {
                                                Y.io('elster/saveInterviewSessionParam?viewed=true');
                                            }
                                        );
                                    }
                                },
                                context: that
                            };
                            that._runningRequests.push(Y.io("api/state/getAssessmentInfo", cfg_assessment));
                        } else if ("product" === area) {
                            that.showView("product", data);
                        } else if ("filing" === area) {
                            blockWaiting = true;
                            that.updateAndLoadFilingView();
                            that._handleNavigationAfterLoad()
                        } else if ("buyingProcess" === area) {
                            blockWaiting = true;
                            that.updateAndLoadFilingView();
                            that._handleNavigationAfterLoad();
                        } else if ("documents" === area) {
                            // The documents area is handled by an extra app.
                            var cb = function cb() {
                                that.get('activeView').handleNavigation(navInfo);
                            };
                            that.showView("documents", data, {callback: cb});
                        } else if ("final" === area) {
                            that.showView("final", data);
                        } else {
                            Y.error('unknown filing area!');
                        }

                        if (Y.one('#case-editor-main-controls')) {
                            Y.one('#case-editor-main-controls').setStyle('display', 'none')
                        }

                        if (!blockWaiting) {
                            that.fire('removeWaiting');
                        }
                    }
                },
                context: that
            };
            that._runningRequests.push(Y.io("elster/getFilingState", cfg_filingState));
        },

        _chooseProduct: function (link) {
            var that = this;

            Y.io("elster/setProduct", {
                on: {
                    success: function (id, res) {
                        var filingState = that._updateFilingState(res);
                        that.showView("product", {filingState: filingState}, {update: true});
                    },
                    end: function () {
                        if (link) {
                            that.fire('open', {link: link});
                        }
                    }
                },
                data: {
                    product: Y.one('[name=product]:checked').get('value')
                }
            });
        },


        updateAndLoadFilingView: function () {
            var that = this,
                cfg_productInfo, cfg_filingState,
                filingState = that.get('filingState'),
                viewConfig = {
                    stateInfo: filingState,
                    filingState: filingState,
                    productInfo: null
                };
            // fixme: use an extra view per state.

            cfg_productInfo = {
                method: 'GET',
                on: {
                    success: function (id, o) {
                        // view config contains filingState and productInfo - faAddress is null
                        viewConfig.productInfo = Y.JSON.parse(o.responseText);
                        that.fire('removeWaiting');

                        that.showView("filing", viewConfig)
                    }
                },
                context: that
            };
            cfg_filingState = {
                method: 'GET',
                on: {
                    success: function (id, o) {
                        // view config contains filingState and faAddress - productInfo is null
                        viewConfig.filingState = Y.JSON.parse(o.responseText);

                        // view config contains only filingState - faAddress and productInfo are null
                        that.showView("filing", viewConfig);

                        that.fire('removeWaiting');
                    }
                },
                context: that
            };

            that.fire('removeWaiting')
            // the user needs to buy this case.
            that.showView('filing', viewConfig)

        },

        _handleNavigationAfterLoad: function () {
            Y.log('Content was loaded checking for extra actions to take…', 'DEBUG', 'CaseEditorAppFiling2');
            var shopOutcome = this.get('shopOutcome');
            // now we have to check if this request was initialized from out shop and if we have to react on the outcome
            // of a shopping tour.
            switch (shopOutcome) {
                // the user purchased something successfully we have to check if it was an actual case and start
                // the filing process...
                case 'ok':
                    Y.log('Shopping tour was a success starting filing…', 'DEBUG', 'CaseEditorAppFiling2');
                    break;
                // There was an error during the purchase. We don't know what happened we only can try to motivate the
                // user to try again.
                case 'error':
                    Y.log('Shopping-error: trying to explain…', 'DEBUG', 'CaseEditorAppFiling2');
                    this.fire('alert', {
                        msg: 'Bei der Bestellung ist ein Fehler aufgetreten. Sollte der Fehler ' +
                            'bestehen bleiben, wenden Sie sich bitte an unseren Support.'
                    });
                    break;
                // The user canceled the process - nothing else to do then logging this event.
                case 'canceled':
                    Y.log('User canceled shopping. Nothing to do.', 'DEBUG', 'CaseEditorAppFiling2');
                    break;
                // there is no default action
            }
            // If we end up beeing here - this is no problem. The page was rendered and the user can use it.
            // We simply don't have any extra magic which needs to happen.
        },
        _loadPreviewApp: function () {
            var that = this;
            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('analysis').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')
            // let currentYuiSectionUrl = window.location.href
            var currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process').get('link')

            var yuiSections = {previous: previousSectionLink, current: currentYuiSectionUrl, next: nextSectionLink}

            var csrfToken = that.config.t
            var tokenName = that.config.tName

            that.vueApp = onse.default.makePreviewApp({
                hasBusinessCase: Y._currentState.hasBusinessCase,
                yuiSections: yuiSections,
                csrfToken: csrfToken,
                tokenName: tokenName
            })

            if (Y.one('#case-editor-main-header')) {
                Y.one('#case-editor-main-header').setStyle('display', 'none')
            }

            that.vueApp.app.$mount('#vue-preview-app')

            return false
        },
        _loadIntroductionApp: function () {
            var that = this;
            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('analysis').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')
            // let currentYuiSectionUrl = window.location.href
            var currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process').get('link')

            var yuiSections = {previous: previousSectionLink, current: currentYuiSectionUrl, next: nextSectionLink}

            that.vueApp = onse.default.makeFilingIntroductionApp({
                yuiSections: yuiSections
            })

            //Remove case editor's main header before mounting the Vue Checkout App

            // Y.one('#case-editor-main').removeClass('case-editor-main-body')
            if (Y.one('#case-editor-main-header')) {
                Y.one('#case-editor-main-header').setStyle('display', 'none')
            }

            that.vueApp.app.$mount('#vue-introduction-app')

            return false

        },
        _loadCheckoutApp: function () {
            var that = this;
            var checkoutSteps = that.buildCheckoutSteps();

            var isAuthenticated = !this.get('filingState').needsWebIdAuthentication;

            var webidServiceUrl = this.get('filingState').webidServiceURL;

            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-preview').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/print').get('link')
            var currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')

			var yuiSections = {previous: previousSectionLink, current: currentYuiSectionUrl, next: nextSectionLink}
			var elsterFilingInfo = {
				mayUseElsterII: that.get('filingState').mayUseElsterII,
				sentWithElsterII: that.get('filingState').sentWithElsterII,
				needsElsterII: that.get('filingState').needsElsterII,
				elsterIIMailSendingNecessary: that.get('filingState').elsterIIMailSendingNecessary
			}

            that.vueApp = onse.default.makeCheckOutApp({
                components: checkoutSteps,
                authenticated: isAuthenticated,
                finApiEnabled: false, //this.get('filingState').finApiEnabled,
                webidServiceUrl: webidServiceUrl,
                vzYear: that.config.vz,
                elsterAvailableFrom: this.get('filingState').elsterAvailableFrom,
                checkoutServiceBaseUri: that.config.checkoutServiceBaseUri,
                yuiSections: yuiSections,
                elsterFilingInfo: elsterFilingInfo,
                boUser: Y._config.boUser
            });

            //Remove case editor's main header before mounting the Vue Checkout App
            // Y.one('#case-editor-main').removeClass('case-editor-main-body')
            if (Y.one('#case-editor-main-header')) {
                Y.one('#case-editor-main-header').setStyle('display', 'none')
                //     Y.one('#case-editor-main-header').remove()
            }

            that.vueApp.app.$mount('#vue-case-filing')

            return false;
        },

        buildCheckoutSteps: function () {
            var checkoutSteps = [];
            if (this.get('filingState').needsPaymentForFiling) {
                checkoutSteps.push('payment-overview')
                checkoutSteps.push('checkout-web-component')
            }
            var isAuthenticated = !this.get('filingState').needsWebIdAuthentication;
            if (!isAuthenticated) {
                checkoutSteps.push('verification-overview');
            }
            checkoutSteps.push('checkout-filing');
            return checkoutSteps;
        },

        _startCheckout: function (e) {
            e.halt();
            Y.log('in _startCheckout', 'DEBUG', 'CaseEditorAppFiling');
            if (SMST_config.onseClientName === 'wkd') {

                var errorHandler = function () {
                    alert('Der Aufruf des Shops ist zur Zeit leider nicht möglich.\nSollten Sie mehrmals diesen Fehler sehen, nehmen sie bitte Kontakt mit unserem Support auf.');
                }
                // we are in the wkd version. We have to redirect into their webshop instead of doing a checkout
                // we use the same mechanism as the ios app to access the category/productType, but we need also the
                // year here and the transactionId
                var productType = '';
                var year = '';
                var transactionId = '';

                var elem = Y.one('#ui-product-category');
                if (elem) {
                    productType = elem.getAttribute('data-category');
                }
                var elem = Y.one('#ui-product-year');
                if (elem) {
                    year = elem.getAttribute('data-year');
                }
                var elem = Y.one('#ui-product-transactionId');
                if (elem) {
                    transactionId = elem.getAttribute('data-transactionId');
                }
                if (!productType || !year || !transactionId) {
                    errorHandler();
                    return;
                }
                this._redirectToWkdShop(productType, year, transactionId);
            }
        },

        _redirectToWkdShop: function (productType, year, transId) {
            var url = this.config.wkdShopUrl;

            var urlHash = window.location.hash;

            if (urlHash.substring(0, 1) == '#') {
                urlHash = urlHash.substring(1);
            }
            var returnUrl = window.location.protocol + '//' + window.location.host + '/app?smstloc=' + urlHash + '&wkdShopTransactionId=' + transId;
            var errorUrl = window.location.protocol + '//' + window.location.host + '/app?smstloc=' + urlHash + '&wkdShopError=true';
            //year = 2017; // we provoke a redirect to the error url
            var form = Y.Node.create('<form action="' + url + '" method="POST">' +
                '<input type="hidden" name="smsYear" value="' + year + '" />' +
                '<input type="hidden" name="smsProductType" value="' + productType + '" />' +
                '<input type="hidden" name="smsReturnUrl" value="' + returnUrl + '" />' +
                '<input type="hidden" name="smsErrorUrl" value="' + errorUrl + '" />' +
                '</form>');

            Y.one('body').append(form);
            if (SMST_config.onseEnvironment == 'prod') {
                form.submit(); // on production systems, we submit the form automatically and
            } else {
                // in the other environments, we do not automatically jump into the shop but show some buttons/ links to
                // trigger this manually. so you can easily check the params with browser dev tools
                var b = Y.Node.create('<button type="submit">In den Shop springen</button>');
                form.append(b);
                var a = Y.Node.create('<a href="' + returnUrl + '">erfolgreichen Kauf simulieren (returnUrl)</a><br>');
                Y.one('body').append(a);
                a = Y.Node.create('<a href="' + errorUrl + '">abgebrochenen Kauf simulieren (errorUrl)</a><br>');
                Y.one('body').append(a);
            }
        },


        _handleYesButton: function (e) {
            e.halt();

            var successHandler = function (id, o) {
                Y.log('_handeYesButton request succeded', 'WARN', 'CaseEditorAppFiling2');
                var url = o.responseText;
                Y.log('response text: ' + url, 'WARN', 'CaseEditorAppFiling2');
                console.log('will open this url: ' + url); // in case I cannot read the other log :D
                window.open(url, '_blank');
            };

            var failureHandler = function (id, o) {
                Y.log('_handeYesButton request failed');
            };

            var cfg = {
                on: {
                    success: successHandler,
                    failure: failureHandler
                },
                method: 'POST',
                timeout: 5000
            };

            // goes to ElsterController.java/handleYesButton(...)
            var url = this.config.secureURI('elster/handleYesButton');

            Y.log('sending usernameToken request', 'WARN', 'CaseEditorAppFiling2');
            Y.io(url, cfg);

            return false; // supress browser default handler
        },

        _eFileCase: function () {
            var state = this.get('filingState');
            if (!state.hasErrors && !state.needsPaymentForFiling) {
                this.showOverlay('confirm-e-file');
            } else {
                // if we are here, something has seriously gone wrong, because the 'An das FA senden' button should only
                // be visible if the user has paid already. Maybe send an email to ourselves?
            }
        },

        _doEFileCase: function (e, payload) {
            var that = this,
                url, cfg;

            if (e.keyCode && 13 !== e.keyCode) {
                return;
            }
            e.halt();

            this.doElsterII = Y.one('#doElsterII') && Y.one('#doElsterII').get('checked') == true;

            url = 'elster/filingElster';
            cfg = {
                on: {
                    success: function (id, o) {
                        if ("ok" === o.responseText) {
                            Y.log('Successful send to ELSTER: ' + id, 'INFO', 'CaseEditorAppFiling2');

                            if (this.doElsterII && !this.get('filingState').elsterIIMailSendingNecessary) {
                                this.showView('successElster2');
                            } else {
                                this.showView('success');
                            }
                        } else {
                            Y.log('Failed to send to ELSTER: ' + id, 'WARN', 'CaseEditorAppFiling2');
                            this.showOverlay('filing-error', null, {boUser: that.config.boUser, error: o.responseText});
                        }
                    },
                    failure: function (id) {
                        Y.log('Failed to send to ELSTER: ' + id, 'WARN', 'CaseEditorAppFiling2');
                        this.showOverlay('filing-error', null, {boUser: that.config.boUser, error: o.responseText});
                    },
                    end: function () {
                        this.fire('removeWaiting');
                    }
                },
                data: {
                    doElsterII: this.doElsterII,
                    doSendReceipts: this.get('filingState').elsterIIMailSendingNecessary ? 'true' : 'false',
                },
                /*
                 WARNING: IE9 aborts transactions despite of timeout, see ONSE-4077
                 sync: true - would probably work in IE
                 timeout: 40000,
                 */
                context: that
            };

            that.fire('waiting', {msg: 'Bitte haben Sie einen Moment Geduld.<br>Wir übermitteln Ihre Daten an das Finanzamt…'});
            Y.io(url, cfg);
            payload.overlay.hide();
        },
        _loadFinalizedFilingApp: function () {
            var that = this

            var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/send').get('link')
            var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('decree').get('link')

            var currentSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-final').get('link')

            var yuiSections = {
                previous: previousSectionLink,
                current: currentSectionUrl,
                next: nextSectionLink
            }

            that.vueApp = onse.default.makeFinalizedFilingApp({
                notYetSentViaElster: that.get('filingState').notYetSentViaElster,
                transferDecreeData: Y._currentState.transferDecreeData,
                yuiSections: yuiSections
            })
            that.vueApp.app.$mount('#vue-filing-final')
        }
    }, {
        ATTRS: {

            container: {
                valueFn: function () {
                    return Y.Node.create('<div id="case-filing"/>');
                }
            },
            caseId: {readOnly: true}, // ToDo: do we need this??? check this
            shopOutcome: {readOnly: true},
            filingState: {readOnly: false}


        }
    });


}, '1.0.0', {
    requires: [
        'app',
        'base-build',
        'smart-handlebars-templates',
        'case-editor-app-base',
        'case-editor-app-filing2-documents',
        'io-base',
        'json-parse',
        'node',
        'smst-lang'
    ]
});
