// collection of all views which handle the representation of any of the case model classes.
YUI.add('case-editor-app-filing2-documents', function (Y) {
    'use strict'

    Y.CaseEditorAppFiling2DocumentsPrintView = Y.Base.create('CaseEditorAppFiling2DocumentsPrintView', Y.HandlebarsView, [], {
        template: 'case-editor-filing2-documents-print',
        param: 'printed',

        initializer: function () {
            this.get('container').delegate('click', this._handleClick, 'input', this)
        },

        _handleClick: function () {
            var cont = this.get('container')
            var tmpNode = cont.one('input')

            var checked = tmpNode.get('checked')
            var button = cont.one('#ui-go-to-filing2-sign')
            var pathFilled = true

            if (!button) {
                if (!this.get('filingState').documentsPrinted) pathFilled = false
            }

                if (checked && pathFilled) {
                    Y.io('elster/saveInterviewSessionParam?' + this.param + '=' + checked, {context: this})

                    button.removeClass('btn-disabled')
                } else {
                    button.addClass('btn-disabled')
                }
            }
        })

    Y.CaseEditorAppFiling2DocumentsSignView = Y.Base.create('CaseEditorAppFiling2DocumentsSignView',
        Y.CaseEditorAppFiling2DocumentsPrintView, [], {
            // we extend the print view (above) and just use another template
            template: 'case-editor-filing2-documents-sign',
            param: 'signed'

        })

    Y.CaseEditorAppFiling2DocumentsReceiptsView = Y.Base.create('CaseEditorAppFiling2DocumentsReceiptsView',
        Y.CaseEditorAppFiling2DocumentsPrintView, [], {
            // we extend the print view (above) and just use another template
            template: 'case-editor-filing2-documents-receipts',
            param: 'receiptsOk',

            initializer: function () {
                this.get('container').delegate('click', this._print, '#ui-print-receipts', this)
            },

            _print: function (e) {

                e.halt()

                window.frames["ui-print-frame"].document.write("<h3>Belegliste</h3>")
                window.frames["ui-print-frame"].document.write(Y.one('#ui-case-editor-receipts-print-content').get('innerHTML'))
                window.frames["ui-print-frame"].document.close()
                window.frames["ui-print-frame"].window.focus()

                var result = window.frames["ui-print-frame"].document.execCommand("print", false, null)
                if (!result) {
                    window.frames["ui-print-frame"].print()
                }

            }
        }, {
            ATTRS: {
                receipts: {
                    getter: function () {
                        var text = Y.UserCase.getValueFromCase("OF0011199", "Standard", 1)
                        var receipts = []

                        if (text && text.value && text.value != "") {
                            receipts = text.value.split("\n\n")
                        }

                        return receipts
                    }
                },
                receiptsPresent: {
                    getter: function () {
                        var text = Y.UserCase.getValueFromCase("OF0011199", "Standard", 1)

                        return text && text.value && text.value !== ""
                    }
                }
            }
        })

    Y.CaseEditorAppFiling2DocumentsSendView = Y.Base.create('CaseEditorAppFiling2DocumentsSendView',
        Y.CaseEditorAppFiling2DocumentsPrintView, [], {
            // we extend the print view (above) and just use another template
            template: 'case-editor-filing2-documents-send',
            param: 'sent'
        })


    Y.CaseEditorAppFiling2Documents = Y.Base.create('CaseEditorAppFiling2Documents',
        Y.smst.CaseEditorAppBase, [Y.AppOverlays], {
            vueApp: null,
            views: {
                print: {type: Y.CaseEditorAppFiling2DocumentsPrintView},
                sign: {type: Y.CaseEditorAppFiling2DocumentsSignView},
                receipts: {type: Y.CaseEditorAppFiling2DocumentsReceiptsView},
                send: {type: Y.CaseEditorAppFiling2DocumentsSendView}
            },

            getLink: function (name) {
                return Y.smst.CaseEditor.SECTIONS.getByName(name).get('link')
            },


            events: {
                '#ui-go-to-filing2-print': {
                    click: function (e) {
                        this.isDisabled(e) || this.fire('open', {link: this.getLink('filing2-process-documents/print')})
                    }
                },
                '#ui-go-to-filing2-sign': {
                    click: function (e) {
                        var that = this

                        if (that.isDisabled(e)) {
                            return
                        }

                        if (!that.get('filingState').sentWithElsterII) {
                            //normal flow
                            that.fire('open', {link: this.getLink("filing2-process-documents/sign")})
                        } else {
                            //skip
                            Y.io('elster/saveInterviewSessionParam', {
                                context: this,
                                on: {
                                    success: function (id, o) {
                                        that.fire('open', {link: this.getLink("filing2-process-documents/receipts")})
                                    }
                                },
                                data: {
                                    printed: 'true',
                                    signed: 'true',
                                    receiptsOk: 'false',
                                    sent: 'false'
                                }
                            })
                        }

                    }
                }
            },

            isDisabled: function (e) {
                if (e.currentTarget.hasClass('btn-disabled')) {
                    this.fire('alert', {msg: 'Bitte führen Sie alle Schritte in der angegebenen Reihenfolge durch und setzen Sie die entsprechenden Haken.'})
                    return true
                } else {
                    return false
                }
            },

            overlays: {},

            showView: function (view, config, options, callback) {
                this.constructor.superclass.showView.call(this, view, config, options, callback)
                var filingMethod = config.filingState.sentWithElsterII ? 'ELSTER_II' : 'ELSTER_I'
                var stateInfo = {
                    elsterAvailable: config.filingState.elsterAvailable,
                    notYetSentViaElster: config.filingState.notYetSentViaElster,
                    elster2MailSendingNecessary: config.filingState.elsterIIMailSendingNecessary,
                    documentsPrinted: config.filingState.documentsPrinted,
                    documentsSent: config.filingState.documentsSent,
                    receiptsOk: config.filingState.receiptsOk,
                    receiptsList: config.receiptsList,
                    mayUseElsterII: config.filingState.mayUseElsterII,
                    sentWithElsterII: config.filingState.sentWithElsterII,
                    elsterIIReceiptInfo: config.filingState.elsterIIReceiptInfo,
                    filingMethod: filingMethod,
                    view: view
                }
                Y.one('#case-editor-main').removeClass('case-editor-main-body')
                Y.one('#case-editor-main-header').setStyle('display', 'none')
                switch (view) {
                    case 'print':
                        this._loadPrintDocumentsApp(
                            stateInfo,
                            this.config.tName,
                            this.config.t)
                        break
                    case 'sign':
                        this._loadSignFilingDocumentsApp(config.filingState.documentsSigned, filingMethod)
                        break
                    case 'receipts':
                        this._loadPostFilingDocumentsAndReceiptsApp(stateInfo,
                            this.config.tName,
                            this.config.t)
                        break
                    case 'send':
                        this._loadPostFilingDocumentsAndReceiptsApp(stateInfo,
                            this.config.tName,
                            this.config.t)
                        break
                    default:
                        break
                }

                if (!this.vueApp && !Y.one('#app')) {
                    Y.one('#case-editor-main').addClass('case-editor-main-body')
                    Y.one('#case-editor-main-header').setStyle('display', '')
                }
            },

            _loadPrintDocumentsApp: function (stateInfo, tokenName, csrfT) {
                var that = this
                var filingSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-filing').get('link')
                var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/sign').get('link')

                var currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/print').get('link')

                var yuiSections = {
                    previous: filingSectionLink,
                    current: currentYuiSectionUrl,
                    next: nextSectionLink,
                }

                that.vueApp = onse.default.makePrintFilingDocumentsApp(
                    {
                        stateInfo: stateInfo,
                        yuiSections: yuiSections,
                        tokenName: tokenName,
                        csrfToken: csrfT
                    })

                that.vueApp.app.$mount('#vue-print-app')
            },

            _loadSignFilingDocumentsApp: function (documentsSigned, filingMethod) {
                var that = this
                var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/print').get('link')
                var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/receipts').get('link')
                var currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/sign').get('link')

                var yuiSections = {
                    previous: previousSectionLink,
                    current: currentYuiSectionUrl,
                    next: nextSectionLink,
                }
                that.vueApp = onse.default.makeSignFilingDocumentsApp({
                    documentsSigned: documentsSigned,
                    filingMethod: filingMethod,
                    notYetSentViaElster: that.get('filingState').notYetSentViaElster,
                    yuiSections: yuiSections
                })

                that.vueApp.app.$mount('#vue-sign-app')
            },

            _loadPostFilingDocumentsAndReceiptsApp: function (stateInfo, tokenName, csrfT) {
                var that = this
                var previous, next, nextView = ''
                if (stateInfo.view === 'send') {
                    previous = 'receipts'
                    next = 'final'
                    nextView = next
                } else {
                    previous = 'sign'
                    next = 'documents/send'
                    nextView = 'send'
                }
                var previousSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/' + previous).get('link')
                var nextSectionLink = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process/' + next).get('link')

                var currentYuiSectionUrl = Y.smst.CaseEditor.SECTIONS.getByName('filing2-process-documents/' + stateInfo.view).get('link')

                var yuiSections = {
                    previous: previousSectionLink,
                    current: currentYuiSectionUrl,
                    next: nextSectionLink,
                }
                that.vueApp = onse.default.makePostFilingDocumentsAndReceiptsApp({
                    stateInfo: stateInfo,
                    yuiSections: yuiSections,
                    nextView: nextView,
                    tokenName: tokenName,
                    csrfToken: csrfT
                })

                that.vueApp.app.$mount('#vue-' + stateInfo.view + '-app')
            },

            handleNavigation: function (navInfo) {
                var app = this
                var formId = navInfo.formId
                var data = {
                    filingState: app.get('filingState'),
                    filingLink: app.getLink('filing2-process-filing'),
                    receiptsLink: app.getLink('filing2-process-documents/receipts'),
                    signLink: app.getLink('filing2-process-documents/sign'),
                    sendLink: app.getLink('filing2-process-documents/send'),
                    receiptsList: app.get('receiptsList')
                }

                app.fire('removeWaiting')
                app.fire('waiting', {msg: 'Bitte warten...'})

                if (formId === 'print') {
                    app.showView('print', data)
                } else if (formId === 'sign') {
                    app.showView('sign', data)
                } else if (formId === 'receipts') {
                    app.showView('receipts', data)
                } else if (formId === 'send') {
                    app.showView('send', data)
                } else {
                    app.showView('download', data)
                }
                app.fire('removeWaiting')
            }
        }, {
            ATTRS: {
                container: {
                    valueFn: function () {
                        return Y.Node.create('<div id="case-filing-documents"/>')
                    }
                },
                receiptsList: {
                    getter: function () {
                        var text = Y.UserCase.getValueFromCase("OF0011199", "Standard", 1)
                        var receipts = []

                        if (text && text.value && text.value != "") {
                            receipts = text.value.trim().split("\n\n")
                        }

                        return receipts
                    }
                },
                filingState: {}
            }
        })


}, '1.0.0', {
    requires: [
        'app',
        'base-build',
        'smart-handlebars-templates',
        'case-editor-app-base',
        'io-base',
        'json-parse',
        'node',
        'smst-lang',
    ],
})
