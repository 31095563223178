YUI.add('case-editor-app-interview-page-form', function (Y) {

    "use strict";

    var VERBOSE = false;

    /**
     * This class represents a form or page in the interview.
     *
     * @class OnseBase
     * @extends Base
     * @constructor
     * @cfg {object} configuration attributes
     */
    Y.CaseEditorAppInterviewPageForm =
        Y.Base.create('CaseEditorAppInterviewPageForm', Y.CaseEditorAppInterviewPageView, [Y.AppOverlays], {

            events: {
                '.case-editor-interview-field-buttonpicker-item': {click: '_handleImageListClick'}
            },

            overlays: {},

            // --- lifecycle methods ---------------------------------------------------------------------------------------

            initializer: function () {
                var that = this,
                    handlers = that.get('handlers');
                // set some inital values
                that._smst_modified = false;
                that.fields = {};
                that.__fieldRules = new Y.interview.FieldRules();

                // for easy access we will store the current state object in a class variable.
                that._pageState = that.get('pageState');
                that._pageData = null;

                handlers.push(that.on("interview:showArea", that._afterShowArea, that));

                handlers.push(that.on("interview:hideInlineArea", that._checkDisappearedFields, that));

                handlers.push(that.after('interview:fieldChanged',
                    that._processInlineAreaRulesAfterFieldChanged, that));

                handlers.push(that.after('interview:fieldChanged', function (e) {
                    that.__fieldRules.checkRules(this._pageState, e);
                }, that));

                handlers.push(that.after('*:field-blur', function (e) {
                    that.__fieldRules.checkRules(this._pageState, e);
                }, that));

                handlers.push(Y.on('*:load', that._prepareUnload, that));
                handlers.push(Y.on('*:unload', that._prepareUnload, that));
                handlers.push(Y.on('*:next', that._prepareNext, that));

                that.publish('interview:hideInlineArea', {
                    emitFacade: true,
                    preventable: false
                });

                that.publish("interview:rendering", {
                    emitFacade: true,
                    preventable: false
                });

                that.publish("interview:rendered", {
                    emitFacade: true,
                    preventable: false
                });

                that.publish('interview:showArea', {
                    emitFacade: true,
                    preventable: false
                });

                Y.once('smartsteuer:ready', function (config) {
                    that.smstConfig = config
                }, that);
            },

            destructor: function () {
                this._mobilefielddecorator && this._mobilefielddecorator.destroy() && (this._mobilefielddecorator = null);

                Y.Object.each(this.fields, function (it) {
                    it.destroy();
                }, this);
            },

            _initForm: function () {
                var that = this;

                Y.log("### interview form render");

                var pageData = that._pageData = JSON.parse(that._pageState.interviewPage);
                var page = "";

                //console.log(pageData);

                for (var x = 0; x < pageData.interviewElements.length; x++) {
                    var el = pageData.interviewElements[x];

                    if (el.interviewElementType == "interviewArea") {

                        //add an area caption or info text
                        page = page + Y.HandlebarsTemplates.lookup('case-editor-interview-area-area')(
                            {
                                "area": el
                            }
                        );
                    } else if (el.interviewElementType == "interviewField") {

                        //determine the right field template
                        var template = "case-editor-interview-field-" + el.interviewFieldType;

                        if (el.interviewFieldType == "imagelist") {

                            var found = false;

                            for (var i = 0; i < el.nativeField.list.data.length; i++) {
                                //console.log(el.nativeField.list.data[i]);

                                el.nativeField.list.data[i].label = el.nativeField.list.rawData[0][i][1];
                                el.nativeField.list.data[i].label2 = el.nativeField.list.rawData[0][i][4];
                                el.nativeField.list.data[i].path = el.nativeField.list.rawData[0][i][2];
                                el.nativeField.list.data[i].value = el.nativeField.list.rawData[0][i][0];

                                if (el.nativeField.list.data[i].value == el.value) {
                                    el.nativeField.list.data[i].selected = true;

                                    found = true;
                                }

                                //using this to open/close additional containers
                                if (i > 0 && i % 2 == 0) {
                                    el.nativeField.list.data[i].br = true;
                                }
                            }
                        } else if (el.interviewFieldType == "select") {

                            for (var i = 0; i < el.nativeField.list.data.length; i++) {
                                //console.log(el.nativeField.list.rawData[0][i][el.nativeField.list.valueColumnIndex]);
                                //ONSE-12579 this doesn't work for dynamic lists with less elements, so list.data holds the real value now
                                //el.nativeField.list.data[i].value = el.nativeField.list.rawData[0][i][el.nativeField.list.valueColumnIndex];

                                if (el.nativeField.list.data[i].value == el.value) {
                                    el.nativeField.list.data[i].selected = true;
                                }

                                if (Array.isArray(el.nativeField.list.data[i].label)) {
                                    el.nativeField.list.data[i].label = el.nativeField.list.data[i].label[0];
                                } else {
                                    var l = el.nativeField.list.data[i].label;
                                    l = l.replace("[\"", "");
                                    l = l.replace("\"]", "");

                                    el.nativeField.list.data[i].label = l;
                                }
                            }
                        }

                        //TODO: refactor the backend parameter parsing, change interview-fieldrules.js and remove this
                        if (that._pageState.isMultiFieldArea) {
                            el.name = el.name + "[" + that._pageState.multiFieldIndex + "]";
                            //TODO: this is used for shallow / template values and still has to be set, too
                            el.nativeField.mfaIndex = that._pageState.multiFieldIndex;
                        }

                        //render field into element...
                        el.content = Y.HandlebarsTemplates.lookup(template)(
                            {
                                "field": el
                            }
                        );

                        //...add some convenient helpers for handlebars...
                        el.imagelist = el.interviewFieldType == "imagelist";
                        el.checkbox = el.interviewFieldType == "checkbox" || el.interviewFieldType == "togglewidget";

                        //...and put it into a line which is added to the page
                        page = page + Y.HandlebarsTemplates.lookup('case-editor-interview-area-line')(
                            {
                                "field": el
                            }
                        );
                    }
                }


                var content = Y.HandlebarsTemplates.lookup('case-editor-interview-area-form')(
                    {
                        "formIndex": that._pageState.formIndex,
                        "formId": that._pageState.formId != -1 ? that._pageState.formId : "",
                        "multiFieldAreaIndex": that._pageState.multiAreaMeta.multiFieldAreaIndex,
                        "maxMultiFieldAreaIndex": that._pageState.multiAreaMeta.maxMultiFieldAreaIndex ?
                            that._pageState.multiAreaMeta.maxMultiFieldAreaIndex
                            :
                            0,
                        "content": page
                    }
                );

                that.set('pageContent', content);

                //init inline mapping
                Y.all('.case-editor-interview-inline-area').each(
                    function (it) {
                        if (it.getAttribute('data-parentInlineId')) {
                            if (that.linkedInlineTable.filter(function (e) {
                                return e.inlineAreaId === it.get('id');
                            }).length <= 0) {

                                that.linkedInlineTable.push(
                                    {
                                        inlineAreaId: "#" + it.get('id'),
                                        parent: "#" + it.getAttribute('data-parentInlineId')
                                    }
                                );
                            }

                        }
                    }
                );

                //render responsive checkbox container, including some minor functionality tweaks
                that.handleCheckboxArea(pageData);

                //hide navigation for image picker areas
                if (pageData.hideNavigation) {
                    Y.one('#case-interview-form').one('#case-editor-continue-container').setStyle('display', 'none');
                    Y.one('#case-editor-main-header').setStyle('minHeight', '0px');
                    Y.one('#case-editor-section-title').setStyle('display', 'none');

                } else {
                    Y.one('#case-editor-main-header').setStyle('minHeight', '180px');
                    Y.one('#case-editor-section-title').setStyle('display', 'block');
                }

                that.fire('removeWaiting');
                that.fire('interview:viewRendered');
            },

            handleCheckboxArea: function (pageData) {
                if (pageData.checkboxArea) {
                    var that = this;

                    //reskin area
                    Y.one('#case-interview-field-help').addClass('no-triangle');
                    Y.one('.case-interview-field-checkbox').ancestor('ul').addClass('case-interview-field-group-checkbox-only');
                    Y.all('.case-interview-field-checkbox').each(
                        function (f) {
                            f.addClass("case-interview-field-checkbox-only");
                        }
                    );

                    that.defaultNextLabel = Y.one('#case-editor-continue-container').one('.ui-case-editor-next').getContent();
                    that.setCheckboxSelectionClasses();
                    that.alterNextLabelDependingOnSelection();

                    that.get('handlers').push(Y.delegate('click', function (e) {
                        that.passClickEventToCheckbox(e);
                        that.setCheckboxSelectionClasses();
                        that.alterNextLabelDependingOnSelection();
                    }, 'body', '.case-interview-field-checkbox', that));
                }
            },

            alterNextLabelDependingOnSelection: function () {
                var nextButton = Y.one('#case-editor-continue-container .ui-case-editor-next');
                var isAnyFieldSelected = Y.some(Y.all('.checkbox-field'), function (f) {
                    return f.get('checked');
                }, this);
                var nextLabel = isAnyFieldSelected ? this.defaultNextLabel : 'Keine davon <i class="fa fa-chevron-right"></i>';
                nextButton.setContent(nextLabel);
            },

            setCheckboxSelectionClasses: function () {
                Y.one('#case-interview-form').all('.checkbox-field').each(
                    function (f) {
                        if (f.get('checked')) {
                            f.ancestor('li').addClass('case-interview-field-selected');
                        } else {
                            f.ancestor('li').removeClass('case-interview-field-selected');
                        }
                    }
                );
            },

            passClickEventToCheckbox: function (e) {
                if (e.target.get('nodeName').toLowerCase() !== 'input') {
                    var cb = Y.one(e.currentTarget).one('.checkbox-field');
                    cb.simulate('click');
                }
            },

            /**
             * Implementation of the required API. This will initialize this page.
             *
             * @param e The event containing the new content which has to be processed.
             *
             * @protected
             */
            _initPage: function (e) {
                var that = this,
                    container = that.get('container');

                Y.log("Initializing page...", "DEBUG", "CaseEditorAppInterviewPageForm");

                container.setHTML(e.newVal);
                that.addNavigationButtons(container);

                that.fire('interview:rendering', {state: this._pageState});

                // initialize the rest. - some of these methods need the content already in the DOM.
                that.setupAfterFormInit();
            },

            setupAfterFormInit: function () {

                if (!this.linkedInlineTable) {
                    this.linkedInlineTable = new Array();
                }

                Y.log("### interview form enhancement: " + this._pageState.multiAreaMeta.multiFieldAreaIndex);

                //making sure all fields are initialized
                Y.one("#case-interview-form").all(".native-field").each(function (field) {
                    if (!field.hasClass('half')) {
                        field.set('value', Y.smst.Utils.textAreaEscape(field.get('value')));

                        var f = Y.interview.FieldFactory.newField(field, this._pageState.formIndex, this);
                        this.fields[f.nodeId] = f;

                        //console.log("Setting up field "+f.nodeId);

                        f.render();
                    }
                }, this);

                //show the main area...
                this.fire("interview:showArea", {
                    area: 'inline_' + this._pageState.area,
                    isNew: false
                });

                this.__fieldRules.updateFieldRules(
                    this._pageState,
                    this.fields,
                    this._pageState.multiAreaMeta.maxMultiFieldAreaIndex
                );

                this._setupLauncher();
                this._updateErrors();
                this._activateFieldHelp();
                this._setSmartCheckMode();
                this._mobilefielddecorator = new Y.MobileFieldDecorator();

                this._ready = true;

                Y.log("firing interview:rendered");

                this.fire('interview:rendered', {fields: this.fields, state: this._pageState});

                //sanity-check for inline-area-rendering
                if (VERBOSE) {
                    var rules = this._pageData.inlineAreaRules;
                    for (var i = 0; i < rules.length; i++) {
                        if (Y.one('#inline_' + rules[i].id) == null) {
                            Y.error("Inline-Area not rendered: " + rules[i].id);
                        } else {
                            Y.log("Inline-Area present: " + rules[i].id);
                        }
                    }
                }
            },

            /**
             * Two things have to be done when an inline area appears:
             * 1. initialize all fields of this area
             * 2. touch each field once to trigger any visibility- or field-rule.
             *
             * @param e The event. This contains the now visible area and the inlineId of its dom-node.
             * @private
             */
            _afterShowArea: function (e) {
                var appearedFields = this._checkAppearedFields(e);
                this._touchFields(appearedFields); // this was part of content-interview.
            },

        /**
         * Wenn ein Inline-Area angezeigt wird, müssen die enthaltenen Felder überprüft werden.
         * @param e Das Event - mit dem area als property
         */
        _checkAppearedFields: function(e) {
            var appearedFields = [];

                Y.log("Check after area (" + e.area + ") appeared.");
                //console.log("Check after area (" + e.area  + ") appeared.");

                if (e.isNew) { // set in addMFA
                    this._pageState.areaIsProcessed = false;
                }

                Y.one("#case-interview-form").all(".native-field").each(function (field) {
                    var ancestor = field.ancestor('li');

                    //if top level, show all visible fields, otherwise only the ones using the inlineid
                    if ((e.area == "inline_" + this._pageState.area && ancestor.getStyle('display') != "none")
                        || ancestor.getAttribute('data-inlineid') == e.area) {

                        //console.log("display li "+ancestor.get('id')+ " / field "+field.get('id'));

                        ancestor.setStyle('display', 'block');

                        var f = this.fields[field.get("id")];

                        // we have to collect all native-fields in this area.
                        if (f && (f.get('node').hasClass('native-field'))) {
                            f.show();

                            if (this._pageState.areaIsProcessed) {
                                f.check();
                            }

                            appearedFields.push(f);
                        }

                        if (e.value) {
                            Y.log("val for: " + this.fields[field.get("id")] + " : " + e.value);
                            f && f.set('value', e.value, {src: 'tool'});
                        }
                    }
                }, this);

                return appearedFields;
            },

            _touchFields: function (fields) {
                Y.Array.each(fields, function (it) {
                    it.touch()
                });
            },

            /**
             * Wenn ein Inline-Area ausgeblendet wird müssen die enthaltenen Felder überprüft werden.
             * @param e Das Event - mit dem area als property
             */
            _checkDisappearedFields: function (e) {

                Y.log("Check after area (" + e.area + ") disappeared.");
                //console.log("Check after area (" + e.area + ") disappeared.");

                Y.one("#case-interview-form").all(".native-field").each(function (field) {
                    var ancestor = field.ancestor('li');

                    if (ancestor.getAttribute('data-inlineid') == e.area) {
                        //console.log("hide li "+ancestor.get('id'));

                        ancestor.setStyle('display', 'none');

                        var fId = field.get("id"),
                            f = this.fields[fId];

                        //Y.log("Field "+""+"("+field.get("id")+") disappeared.");
                        if (f) {
                            f.hide();
                            //Y.log(f.nodeId+", notVisible: "+f.notVisible, "DEBUG", "CaseEditorAppInterviewPageForm");
                        }
                    }
                }, this);
            },

            hasChanges: function () {
                var hasChanges = false,
                    that = this;

                if (this._smst_modified) {
                    return true;
                }

                Y.some(that.fields, function (f) {
                    if (f && f.isChanged()) {
                        hasChanges = true;
                        return true;
                    }
                    return false;
                }, that);
                return hasChanges;
            },

            /**
             * Before loading a new page this will check if the current page has any changes which would get lost in case
             * of a normal navigation. If changes are found this will prevent the next event.
             *
             * @param e the 'load' event which will get prevented if changes are found.
             * @private
             */
            _prepareUnload: function (e) {
                var that = this;
                if (!that._checkDisabled) {
                    Y.log("Checking for changes...", "DEBUG", "CaseEditorAppInterviewPageForm");
                    if (that.hasChanges()) {
                        e.preventDefault();
                    }
                }
            },

            /**
             * Like _prepareUnload but additionally checks if the current area is new or invalid.
             *
             * @param e The 'next' event which will get prevented if changes are found
             * @private
             */
            _prepareNext: function (e) {
                var that = this;
                Y.log("Checking area states...", "DEBUG", "CaseEditorAppInterviewPageForm");

                //ONSE-6335 never submit on *base
                if (that._pageState.isMultiFieldBase || that._pageState.isMultiFormBase) {

                    var cnt = Y.one('#ui-case-editor-interview-multif-add-multiple-count');
                    if (cnt != null && cnt.getStyle('display') != 'none' && cnt.get('value') != '0') {
                        e.preventDefault();

                        this._createMultiEntries(cnt.get('value'));
                    }

                    return;
                }

                if (!that._pageState.areaIsValid || !that._pageState.areaIsProcessed) {
                    e.preventDefault();
                } else {
                    that._prepareUnload(e);
                }

            },

// --------------------------------------------------------------------------------------------------------------------
// ----- Save the current form ----------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------

            /**
             * This is called when the user clicks on the next button and there are changes on the page or the page has
             * never been saved before.
             *
             * @param e The save event. When prevented this will pull up an overlay asking the user what to do.
             *          (save or abort)
             * @param saveQueue This method is part of a queue which is run during submit. If we prevent the event we also
             *                  need to stop the queue.
             * @private
             */
            _checkFields: function (e, saveQueue) {
                var that = this,
                    ok = true;

                Y.log("save:: check before submit...", 'DEBUG', "CaseEditorAppInterviewPageForm");
                // short cut
                if (e.force) {
                    return;
                }

                Y.each(that.fields, function (f) {

                    var visibleInliner = f.node.ancestor('li').getStyle('display') != 'none';

                    Y.log(">>>>>>>> " + f.get('label') + ": " + visibleInliner);

                    if (visibleInliner) {

                        //Y.log(">>>>>>>> "+f.get('label')+": "+ f.readyForSubmit());

                        if (ok && !f.readyForSubmit()) {
                            Y.log("found error -> preventing default: " + f.nodeId + "/" + f.notVisible, 'INFO',
                                "CaseEditorAppInterviewPageForm");
                            ok = false;
                        } else if (!ok) {
                            // um alle Fehler auf einer Seite anzuzeigen testen wir nur noch schnell den Rest durch.
                            f.readyForSubmit();
                        }
                    }
                }, that);

                var messages = this.__fieldRules.checkRules(this._pageState);
                if (messages != null) {
                    ok = false;
                }

                if (!ok) {
                    e.errorDescriptions && e.errorDescriptions.push(messages);
                    e.preventDefault();
                    // stop clears the queue
                    // run will fire its complete event
                    saveQueue.stop().run();
                }
            },

            _insertPlainValues: function () {
                Y.log("save:: inserting plain values before submit...", "DEBUG", "CaseEditorAppInterviewPageForm");
                Y.each(this.fields, function (f) {
                    if (f && f.plainValue) {
                        f.plainValue();
                    }
                }, this);
            },

            _setProcessedInlineAreaIds: function () {
                var that = this,
                    pa = Y.one('#processedInlineAreas');

                for (var i = 0; i < that._pageData.inlineAreaRules.length; i++) {
                    try {
                        var shown = false;
                        var areaId = that._pageData.inlineAreaRules[i].id;
                        var id = '#inline_' + areaId;
                        var area = Y.one(id);

                        if (area == null) {
                            continue;
                        }

                        if (VERBOSE) {
                            Y.log(">>> " + areaId + " / " + id);
                            Y.log("inlineArea: " + area + ":" + area.get('id'));
                        }

                        if (area.getStyle('display') == 'none') {
                            if (VERBOSE) {
                                Y.log('remove inlineArea: ' + area + ":" + area.get('id'));
                            }
                            area.remove().destroy();
                        } else {
                            shown = true;
                        }

                        if (shown) {
                            pa.set('value', pa.get('value') + ',' + areaId);
                        }
                    } catch (ex) {
                        //Y.app.alert(ex);
                        //Y.log(ex);
                    }
                }

                if (VERBOSE) {
                    Y.log("processedInlineAreas: " + pa.get('value'));
                }
            },

            _submit: function (e) {
                var that = this,
                    cfg,
                    navInfo = e.navInfo;

                Y.log("save:: submitting...", "DEBUG", "CaseEditorAppInterviewPageForm");

                cfg = {
                    on: {
                        success: function (id, res) {
                            Y.log('save:: successfully saved.', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                            // before moving on we have to disable any further testing.
                            that._checkDisabled = true;
                            if (navInfo) {
                                Y.log('save:: found navinfo - redirecting...', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                                that.fire('updateState', {raw: res.responseText, src: 'save', navInfo: navInfo});
                            } else {
                                Y.log('save:: updating state', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                                that.fire('updateState', {
                                    raw: res.responseText,
                                    src: 'save',
                                    activateSmartCheck: e.activateSmartCheck
                                });
                            }
                        },
                        failure: function (id, o) {
                            Y.log('save:: failed to save changes: \n' + o.responseText, 'ERROR', 'CaseEditorAppInterviewPageForm');
                            that.fire('alert', {
                                msg: "Es gab einen Fehler bei der Kommunikation mit dem Server. " +
                                    "Bitte prüfen Sie Ihre Internetverbindung, aktualisieren Sie anschließend die Seite " +
                                    "und versuchen es erneut."
                            });
                        }
                    },
                    context: that,
                    method: 'POST',
                    timeout: 20000,
                    form: {
                        id: 'case-interview-form',
                        useDisabled: true
                    }
                };

                Y.io("api/state", cfg);
            },

            _save: function (e) {
                var that = this,
                    queue = new Y.AsyncQueue();

                if (!that._ready) {
                    Y.log('Not yet ready for submit.', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                    return;
                }

                if (that.__saveQueueRunning) {
                    Y.log('Save queue still running.', 'DEBUG', 'CaseEditorAppInterviewPageForm');
                } else {
                    Y.log('Starting save queue...', 'INFO', 'CaseEditorAppInterviewPageForm');
                    that.__saveQueueRunning = true;

                    queue.onceAfter('complete', function () {
                        that.__saveQueueRunning = false;
                    });

                    // check for valid values
                    queue.add({
                        fn: that._checkFields,
                        args: [e, queue],
                        context: that,
                        timeout: -1 // this first call has to be synchronized. That way we are still able to prevent the
                                    // save event.
                    });

                    // Bring in the waiting mask, so the user sees that something is happening.
                    queue.add({
                        fn: function () {
                            this.fire('waiting', {msg: 'Ihr Fall wird berechnet…'});
                        },
                        context: that
                    });

                    // replace the pretty printed values with their counterparts.
                    queue.add({
                        fn: that._insertPlainValues,
                        context: that
                    });

                    // replace the pretty printed values with their counterparts.
                    queue.add({
                        fn: that._setProcessedInlineAreaIds,
                        context: that
                    });

                    // do submit
                    queue.add({
                        fn: that._submit,
                        args: [e], // the event can have a navInfo inside which dictates the next page to show.
                        context: that
                    });

                    queue.run();
                }

            },

            _setSmartCheckMode: function () {
                if (Y.smartCheckMode) {
                    var node = Y.one('.ui-case-editor-next-and-smartcheck');

                    if (node) node.removeClass('ui-display-none');
                }
            },

            _showErrorMessage: function (msg) {
                var that = this,
                    node = Y.one('#case-interview-form');

                if (node && msg) {

                    node.insert('<div class="ui-case-interview-inline-error app-alert app-alert-problem"><i class="fa fa-exclamation-circle"></i><div>' + msg + '</div></div>', 0);
                }
            },

            _updateErrors: function () {
                var that = this;

                if (!Y.globalErrors || !that._pageState || !Y.smartCheckMode) {
                    return;
                }

                Y.Array.each(Y.globalErrors, function (it, idx) {

                    if (it.fields) {
                        Y.each(it.fields, function (it2) {
                            Y.some(this.fields, function (f) {
                                if (!f._hasError && f.node.getAttribute("name").indexOf(it2) >= 0) {
                                    if ((that._pageState.multiFieldIndex >= 0 ? it.index == that._pageState.multiFieldIndex : true) && it.formIndex == that._pageState.formIndex) {
                                        f.parentNode.addClass('smartcheck-field-attention-status');
                                    }
                                }
                            }, this);
                        }, this);
                    }
                }, that);

            },

            _activateFieldHelp: function () {
                var node = null;

                if (!this.fields || this.fields.length == 0 || this._pageData.checkboxArea) {
                    return;
                }

                Y.some(this.fields, function (f) {
                    if (f._hasError) {
                        if ('hidden' != f.node.get('type')) {
                            var anc = f.node.ancestor('fieldset');

                            if (!anc || anc.getStyle('display') != "none") {
                                node = f.node;
                                return true;
                            }
                        }
                    }
                }, this);

                if (!node) {
                    Y.some(Y.all('.onse-field'), function (f) {
                        if ('hidden' != f.get('type')) {
                            var anc = f.ancestor('fieldset');

                            if (!anc || anc.getStyle('display') != "none") {
                                node = f;
                                return true;
                            }
                        }
                    }, this);
                }

                if (node) {
                    var f = this.fields[node.get('id')]; //due to inline positioning this.fields might not be ordered. position using the first onse field

                    if (f && !Y.one('body').hasClass('ui-device-mobile')) {
                        // we disable the automatic focus for touch-devices, see NA-91
                        Y.later(100, this, function () {
                            f.node.focus();
                        });
                    }
                }
            },

            fixEncoding: function (str) {
                if (str == null) {
                    return str;
                }
                var ret = Y.smst.Utils.textAreaEscape(str);

                return Y.Escape.html(ret);
            },

            // -----------------------------------------------------------------------------------------------------------
            // --- inline areas ------------------------------------------------------------------------------------------
            // -----------------------------------------------------------------------------------------------------------

            linkedInlineTable: [],

            _processInlineAreaRulesAfterFieldChanged: function (e) {
                var fieldId = e.field.get('id'),
                    rules = this._pageData.inlineAreaRules,
                    rule,
                    format;

                for (var i = 0; i < rules.length; i++) {
                    rule = rules[i].visibility;
                    format = rule.substring(rule.indexOf('@') + 1);
                    format = format.substring(0, format.indexOf('/'));

                    if (VERBOSE) Y.log(">>> " + fieldId + ":" + rule + ":" + ":" + format);

                    if (rule.indexOf(fieldId) >= 0) {
                        var that = this,
                            //yui-element, expression parsen
                            ruleStart = rule.substring(0, rule.indexOf(" ")),
                            ruleEnd = rule.substring(rule.indexOf(" ") + 1),
                            value = null,
                            yuiField = Y.one('#' + fieldId),
                            useFallback = false;

                        if (VERBOSE) Y.log("EVAL_DYNAMIC:  MATCH: " + fieldId + ", format: " + format);

                        if (!yuiField) {
                            Y.error('Field is null: ' + fieldId);
                            continue;
                        }

                        if (yuiField.hasClass('case-editor-interview-field-empty')) {
                            if (VERBOSE) Y.log("EVAL_DYNAMIC: EMPTY: " + fieldId);

                            useFallback = true;
                        } else {
                            //ausdruck anpassen und ggf. im eval den wert entsprechend des formates parsen lassen
                            if (format == 'X' || format == 'Y' || format == 'J') {
                                if (yuiField.hasClass('select')) {
                                    value = eval(ruleStart + '.get("value") != ""');
                                } else {
                                    value = eval(ruleStart + '.get("checked") ');
                                }
                                //if(VERBOSE) Y.log('EVAL_DYNAMIC CHECKBOX: '+value);
                            } else {
                                value = eval('Y.UserCase.realValue(' + ruleStart + '.get("value"),"' + format + '") ');
                                //if(VERBOSE) Y.log("EVAL_DYNAMIC: "+value);

                                if (Y.UserCase.isInvalidNumber(value, format)) {
                                    useFallback = true;
                                    //if(VERBOSE) Y.log("EVAL_DYNAMIC: NaN");
                                }
                            }
                        }

                        //defaultvalues
                        if (useFallback) {
                            var defaultValue = yuiField.getAttribute('data-defaultValue');
                            var format = yuiField.getAttribute('data-format');

                            value = Y.UserCase.realValue(defaultValue, format);

                            if (VERBOSE) Y.log("EVAL_DYNAMIC: FALLBACK: " + fieldId + " -> " + value + "@" + defaultValue + ":" + format);
                        }

                        //finally construct and evaluate
                        rule = value + ' ' + ruleEnd;

                        if (VERBOSE) Y.log("EVAL_DYNAMIC: " + rule + " >>> " + eval(rule) + " , fallback: " + useFallback);

                        var ok = false;

                        try {
                            ok = eval(rule);
                        } catch (ex) {
                            Y.log('EVAL_DYNAMIC: Exception: ' + rule);
                        }

                        if (ok) {
                            this._toggleInlineArea(rules[i].id, true, fieldId);
                        } else {
                            this._toggleInlineArea(rules[i].id, false);
                        }
                    }
                }
            },

            _toggleInlineArea: function (inlineAreaIdx, active, fieldId, preventCleanup) {
                var that = this;

                var modifiedInlineAreaId = '#inline_' + inlineAreaIdx,
                    inlineArea = Y.one(modifiedInlineAreaId);

                if (!inlineArea) {
                    Y.log("TOGGLE_INLINE -> AREA NOT FOUND: " + modifiedInlineAreaId + ":" + active + ":" + fieldId);
                    return;
                }

                if (VERBOSE) Y.log("TOGGLE_INLINE: " + modifiedInlineAreaId + ":" + active + ":" + fieldId + ":" + String(inlineArea));

                //ONSE-5913: store inline area dependencies so the can be safely unwound
                if (fieldId) {

                    //on page initialization values of the dirtycase are checked, so we need to make sure all inlines this one depends on are visible
                    var lastInline = that._getDependencyInlinerParent(modifiedInlineAreaId);

                    while (lastInline != modifiedInlineAreaId) {
                        if (VERBOSE) Y.log("checking visibility of inline dependency: " + lastInline);

                        if (!Y.one(lastInline)) {
                            if (VERBOSE) Y.log("skipping, main area reached");

                            break;
                        }

                        if (Y.one(lastInline).getStyle("display") == 'none') {
                            if (VERBOSE) Y.log("skipping this inliner due to invisible dependency");

                            //this is the important part: just return here if any parent inliner inliner is not visible!
                            return;
                        }

                        //fetch parent of this one
                        var nextLastInline = that._getDependencyInlinerParent(lastInline);
                        if (nextLastInline == lastInline) {
                            break;
                        } else {
                            lastInline = nextLastInline;
                        }
                    }
                } else if (!preventCleanup) {
                    var lastInline = that._getLastDependencyInliner(modifiedInlineAreaId);

                    if (VERBOSE) Y.log("fetched last inline dependency: " + lastInline);

                    while (lastInline != modifiedInlineAreaId) {
                        if (VERBOSE) Y.log("toggling off inline dependency: " + lastInline);

                        //toggle out, but prevent this loop from running again
                        that._toggleInlineArea(lastInline.substring(lastInline.lastIndexOf("_") + 1), false, null, true);

                        //fetch parent of this one
                        lastInline = that._getDependencyInlinerParent(lastInline);
                    }
                }
                //end ONSE-5913 / inline dependency management

                if (active) {
                    inlineArea.setStyle("display", "block");

                    if (VERBOSE) {
                        Y.log("TOGGLE_INLINE: STYLES_PARENT: " + stylesParent + ", prevLi: " + prevLi);
                    }

                    that.fire("interview:showArea", {area: inlineArea.get('id')});
                } else {
                    inlineArea.setStyle("display", "none");

                    that.fire("interview:hideInlineArea", {area: inlineArea.get('id')});
                }
            },

            /**
             * Returns the leaf node for the given id, so we can start there to unwind.
             *
             * @param id
             * @returns {*}
             * @private
             */
            _getLastDependencyInliner: function (id) {
                var that = this;

                for (var i = 0; i < that.linkedInlineTable.length; i++) {

                    if (that.linkedInlineTable[i].parent == id) {
                        return that._getLastDependencyInliner(that.linkedInlineTable[i].inlineAreaId);
                    }
                }

                return id;
            },

            /**
             * Returns the parent id for a given inlineAreaId.
             *
             * @param id
             * @returns {*}
             * @private
             */
            _getDependencyInlinerParent: function (id) {
                var that = this;

                for (var i = 0; i < that.linkedInlineTable.length; i++) {

                    if (that.linkedInlineTable[i].inlineAreaId == id) {
                        return that.linkedInlineTable[i].parent;
                    }
                }

                return id;
            },

            _handleImageListClick: function (e) {
                e.halt();
                this._syncImageList(e);
                Y.later(300, this, function () {
                    this.fire('internalNext');
                });
            },

            _syncImageList: function (e) {
                var box = e.currentTarget.get('parentNode').get('parentNode'),
                    select = box.one('.case-editor-interview-field-buttonpicker-select');

                select.all('option').each(function (opt) {
                    if (opt.get('value') == e.currentTarget.getAttribute('data-key')) {
                        opt.set('selected', 'selected');
                        this._smst_modified = true;
                    }
                }, this);
            },

            _setupLauncher: function () {
                Y.Array.each(this._pageState.launcher, function (it) {
                    if (it.link) {
                        for (var key in this.fields) {
                            var f = this.fields[key];
                            if (f.get('name') == it.field) {
                                //TODO: refactor me into field and include result update
                                f.node.removeClass('ui-display-none');
                                f._buttonContainer.one('.ui-launcher-delete').removeClass('ui-display-none');
                            }
                        }
                    }
                }, this);
            },

            addNavigationButtons: function (container) {
                var that = this;

                var node = container.one('#case-interview-form');
                Y.HandlebarsTemplates.append('case-editor-interview-navigation-form', node);

                //multifield+multiform: last area shows a small widget to jump to overview
                if (that._pageState && that._pageState.showAddLink) {
                    var nl = "";

                    if (that._pageState.nextLabel) {
                        nl = "<em>" + that._pageState.nextLabel.trim() + "</em>: ";
                    }

                    Y.one('#case-interview-form').append(
                        Y.HandlebarsTemplates.lookup('case-editor-interview-navigation-addMoreEntries')(
                            {
                                "nl": nl,
                                "showAddLink": that._pageState.showAddLink
                            }
                        )
                    )
                }
            },


        }, {
            ATTRS: {
                ready: {
                    readOnly: true
                }
            }
        });


}, '1.0.0', {
    requires: [
        'app-overlays',
        'async-queue',
        'base-build',
        'case-editor-app-interview-page-view',
        // the chapters view is needed as long as multi-base-areas are handled by this class aswell.
        'case-editor-app-interview-page-chapters',
        'case-editor-app-interview-page-multioverview',
        'case-editor-app-interview-locked-area',
        'case-editor-app-interview-field-help',
        'escape',
        'event',
        'mobilefield',
        'node-event-simulate',
        'interview-field-factory',
        'interview-fieldrules',
        'smst-lang',
        "smst-utils",
        'view'
    ]
});