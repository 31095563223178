YUI.add('smart-formats', function (Y, NAME) {

"use strict";
	/**
	 * Constructor
	 * @param node
	 */
	var ns = Y.namespace('interview'),
		IS_STRING = Y.Lang.isString,
		DEFAULT_DATE_FORMAT = {format:'%d.%m.%Y'},

		PARSER_FLOAT = function(value) {
			if( Y.Lang.isNumber(value) ) {
				return value;
			}
			var v = value.replace(/\./g, "");
			v = v.replace(/,/, '.');
			// an empty string will result in zero.
			if(0 === v.length ) {
				return 0;
			}
			return parseFloat( v );
		},
		PARSER_INT = function(value) {
			if( Y.Lang.isNumber(value) ) {
				return parseInt( value, 10 );
			}

			var v = value.replace(/\./g, "");
			return parseInt( v, 10 );
		},

		DATE_REG_EXP_DE = /(\d{2})\.(\d{2})\.(\d{4})/,

		PARSER_DATE = function(value) {
			if( Y.Lang.isDate(value) ) {
				return value;
			}

			if( DATE_REG_EXP_DE.test(value )) {
				var v = DATE_REG_EXP_DE.exec(value);
				// months are zero based.
				return Date.UTC(parseInt(v[3], 10),parseInt(v[2], 10)-1,parseInt(v[1], 10));
			} else {
				// try to simply create a date
				return Y.DataType.Date.parse(value);
			}
		};

(function() {
	function Format( name, syntax ) {
		this.name = name;
		this.syntax = syntax || "";
		this.className = Format.CLASS_PREFIX+this.name;
	}

	// all static properties and methods
	Y.mix(Format, {
		CLASS_PREFIX: "field-format-"
	});

	// all instance properties and methods (prototype)
	Y.mix( Format.prototype, {

		name: "",

		className: "",

		syntax: "",

		errorMsg: "Dieser Wert ist ung\u00FCltig.",

		// default validator
		validator: function() {
			return true;
		},

		/**
		 * Macht aus einen zwei- ein vierstelliges Jahr.
		 *
		 * @param y Das Ausgangsjahr.
		 */
		_getValidYear: function(y) {
			var year = y;
			if( 4 > year.length ) {
				year = parseInt(year,10);
				if( isNaN(year)) {
					return null;
				}
				if( 40 > year ) {
					year += 2000;
				} else {
					year += 1900;
				}
			}
			return year;
		},

		_getZeroPaddedValue: function(v, size) {
			while( size > v.length ) {
				v = "0".concat(v);
			}
			return v;
		},

		isValid: function( value ) {
			if( Y.Lang.isFunction( this.validator )) {
				return this.validator( value );
			} else if( Y.Lang.isObject( this.validator )) {
				// has to be an regexp
				return this.validator.test( value );
			}
			return false;
		},

		/**
		 * Formatiert den Wert.
		 *
		 * @param value
		 */
		prettify: function( value ) {
			if( this.prettifyer ) {
				return this.prettifyer(value);
			}
			return value; // default -> do nothing
		},

		/**
		 * Bringt den Wert des Feldes in eine normalisierte Form.
		 * @param value
		 */
		normalize: function( value, truncate, roundUp ) {
			if( Y.Lang.isString(value) ) {
				value = Y.Lang.trim(value);
				// Any browser could insert the last value into the field. And this could be the syntax hint.
				if( value === this.syntax ) {
					value = "";
				}
				if( this.normalizer ) {
					return this.normalizer(value, truncate, roundUp);
				}
			}
			return value; // default -> do nothing
		},

		/**
		 * This will create an object out of the normalized value.
		 *
		 * @param value
		 */
		parse: function(value) {
			var v = this.normalize(value);
			if( this.parser ) {
				return this.parser.call(this,value);
			}
			return v;
		}

	});

	ns.Format = Format;

})();

// --- CLASS FORMAT-D ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatD = function() {
		// Chain the constructors
		FormatD.superclass.constructor.call(this, 'D');

        delete this.syntax;
	};

	Y.extend( FormatD, ns.Format, {
		syntax:"TTMMJJJJ",

		errorMsg: "Geben Sie bitte ein gültiges Datum im Format TT.MM.JJJJ oder TTMMJJJJ ein.",

		/* Der Validator setzt einen erfolgreichen Lauf des Normalizers voraus. */
		validator: function(value) {
//			console.log( "validator ###############################" );
//			console.log( "value: " + value );
			if( null === value || "" ===  value ) {
				return true;
			}

//                        console.log( "value.length: " + value.length );
			if( 10 !== value.length ) {
				return false;
			}
			var parts = value.split("."),
				d, m, y, date;
			if(isNaN(parts[0]) || isNaN(parts[1]) || isNaN(parts[2])) {
				return false;
			}
			// parseInt zur Basis 10.
			d = parseInt(parts[0],10);
			m = parseInt(parts[1],10);
			y = parseInt(parts[2],10);
			date = new Date( y, m-1 /*Monate fangen bei 0 an.*/, d );

			// Wenn Tag und Jahr jetzt noch die gleichen sind -> ist's valide.
			//console.log("d: " + d);
			//console.log("date.getDate(): " + date.getDate());
			//console.log("y: " + y);
			//console.log("date.getFullYear(): " + date.getFullYear());
			return d === date.getDate() && y === date.getFullYear();
		},

		normalizer: function(value) {
			var d, m, y, parts;
			// bei einem leeren String gibt's nix zu tun.
			if("" === value ) {
				return value;
			} else if( -1 === value.indexOf(".") && 4 < value.length ) {

				// das Value kommt als fünf- bis achtstellige Zahl oder als deutsches Datum mit zwei Punkten.
				// zahl
				// die ersten zwei Stellen sind der Tag.
				d = value.substring(0,2);
				// dann der Monat
				m = value.substring(2,4);
				// der Rest ist das Jahr
				y = value.substring(4);

				// das Jahr könnte noch ein wenig mehr Aufmerksamkeit benötigen...
				y = this._getValidYear(y);
				if( null === y) {
					return value;
				}
//                            console.log( d, m, y);
				return d.concat(".",m,".",y);
			} else {
				// datum
				parts = value.split(".");
				if( 3 !== parts.length ) {
					return value;
				}
				y = this._getValidYear(parts[2]);
				if( null === y) {
					return value;
				}
//                            console.log( parts[0], parts[1], y);
				return (2===parts[0].length?"":"0").concat(parts[0],".",
						(2===parts[1].length?"":"0"), parts[1],".",y);
			}
		},

		prettifyer : function(value) {
			// FIXME: when we changed from string- to object-representation we can remove this if statement.
			if(IS_STRING(value)){
				return value;
			}
			// Simply format the date.
			return Y.DataType.Date.format(new Date(value), DEFAULT_DATE_FORMAT);
		},

		parser: PARSER_DATE
	});

	ns.FormatD = FormatD;

})();

// --- CLASS FORMAT-T ------------------------------------------------------------------------------------------------

(function() {

	/**
	 * Creates this format.
	 */
	var FormatT = function() {
		// Chain the constructors
		FormatT.superclass.constructor.call(this, 'T');

        delete this.syntax;
	};

	Y.extend( FormatT, ns.Format, {
		syntax: "TT.MM.",

		errorMsg: "Geben Sie bitte einen Tag im Format TT.MM. oder TTMM an.",

		/* Der Validator setzt einen erfolgreichen Lauf des Normalizers voraus. */
		validator: function(value) {
			var parts, d, m, y, date;
			if( null === value || "" ===  value ) {
				return true;
			}
			if( 6 !== value.length ) {
				return false;
			}
			parts = value.split(".");

			if( 3 !== parts.length ) {
				return false;
			}

			if(isNaN(parts[0]) || isNaN(parts[1]) ) {
				return false;
			}
			// parseInt zur Basis 10.
			d = parseInt(parts[0],10);
			m = parseInt(parts[1],10);
			y = parseInt(Y._config.vz,10);
			date = new Date( y, m-1 /*Monate fangen bei 0 an.*/, d );

			// Wenn Tag und Jahr jetzt noch die gleichen sind -> ist's valide.
			return d === date.getDate() && y === date.getFullYear();
		},

		normalizer: function(value) {
			var d, m, parts;
			// bei einem leeren String gibt's nix zu tun.
			if("" === value ) {
				return value;
			} else if( -1 === value.indexOf(".") && 4 === value.length ) {
				// zahl
				// die ersten zwei Stellen sind der Tag.
				d = value.substring(0,2);
				// dann der Monat
				m = value.substring(2,4);
//                            console.log( d, m);
				if(isNaN(d) || isNaN(m) ){
					return value;
				}
				return d.concat(".",m,".");
			} else {
				// datum
				parts = value.split(".");

//                            console.log( parts );
				if( 2 !== parts.length && 3 !== parts.length ) {
					return value;
				}
				if( 2 < parts[0].length || 2 < parts[1].length
						|| (isNaN(parts[0]) || isNaN(parts[1])) ) {
					return value;
				}
//                            console.log( parts[0], parts[1], y);
				return (2===parts[0].length?"":"0").concat(parts[0],".",
						(2===parts[1].length?"":"0"), parts[1],".");
			}
		}
	});

	ns.FormatT = FormatT;
})();

	// --- CLASS FORMAT-B ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatB = function() {
		// Chain the constructors
		FormatB.superclass.constructor.call(this, 'B');

        delete this.syntax;
	};

	Y.extend( FormatB, ns.Format, {
		syntax: "TT.MM",

		errorMsg: "Geben Sie bitte einen Tag im Format TT.MM oder TTMM an.",

		/* Der Validator setzt einen erfolgreichen Lauf des Normalizers voraus. */
		validator: function(value) {
			var parts, d, m, y, date;
			if( null === value || "" ===  value ) {
				return true;
			}
			if( 5 !== value.length ) {
				return false;
			}
			parts = value.split(".");

			if( 2 !== parts.length ) {
				return false;
			}

			if(isNaN(parts[0]) || isNaN(parts[1]) ) {
				return false;
			}
			// parseInt zur Basis 10.
			d = parseInt(parts[0],10);
			m = parseInt(parts[1],10);
			y = parseInt(Y._config.vz,10);
			date = new Date( y, m-1 /*Monate fangen bei 0 an.*/, d );

			// Wenn Tag und Jahr jetzt noch die gleichen sind -> ist's valide.
			return d === date.getDate() && y === date.getFullYear();
		},

		normalizer: function(value) {
			var d, m, parts;
			// bei einem leeren String gibt's nix zu tun.
			if("" === value ) {
				return value;
			} else if( -1 === value.indexOf(".") && 4 === value.length ) {
				// zahl
				// die ersten zwei Stellen sind der Tag.
				d = value.substring(0,2);
				// dann der Monat
				m = value.substring(2,4);
//                            console.log( d, m);
				if(isNaN(d) || isNaN(m) ){
					return value;
				}
				return d.concat(".",m);
			} else {
				// datum
				parts = value.split(".");

//                            console.log( parts );
				if( 2 !== parts.length && 3 !== parts.length ) {
					return value;
				}
				if( 2 < parts[0].length || 2 < parts[1].length
						|| (isNaN(parts[0]) || isNaN(parts[1])) ) {
					return value;
				}
//                            console.log( parts[0], parts[1], y);
				return (2===parts[0].length?"":"0").concat(parts[0],".",
						(2===parts[1].length?"":"0"), parts[1]);
			}
		}
	});

	ns.FormatB = FormatB;

})();

	// --- CLASS FORMAT-E ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatE = function() {
		// Chain the constructors
		FormatE.superclass.constructor.call(this, 'E');

        delete this.syntax;
	};

	Y.extend( FormatE, ns.Format, {
		syntax: "TT.MM.JJ",

		errorMsg: "Geben Sie bitte ein gültiges Datum im Format TT.MM.JJ oder TTMMJJ ein.",

		validator: function( value ) {
			var regexp = /^([0-3]\d)\.([0,1]\d)\.(\d\d)$/,
				parts, d, m, y, date;
			//                        console.log( regexp.test( value ),regexp.exec( value ) );
			if( null === value || "" === value ) {
				return true;
			}
			if( regexp.test( value )) {
				parts = regexp.exec( value );
				d = parseInt(parts[1],10);
				m = parseInt(parts[2],10);
				y = parts[3];
				y = this._getValidYear(y);
				date = new Date( y, m-1 /*Monate fangen bei 0 an.*/, d );
				// Wenn Tag und Jahr jetzt noch die gleichen sind -> ist's valide.
				return d === date.getDate() && y === date.getFullYear();
			}
			return false;
		},

		normalizer: function( value ) {
			var parts;
			if( null === value || "" === value ) {
				return "";
			}
			if( -1 === value.indexOf(".") && 6 === value.length ) {
				return value.substring(0,2).concat(".",value.substring(2,4), ".", value.substring(4));
			}
			parts = value.split(".");
			if( 3 === parts.length ) {
				return  this._getZeroPaddedValue( parts[0], 2).concat(
						".", this._getZeroPaddedValue( parts[1], 2),
						".", this._getZeroPaddedValue( parts[2], 2)
				);
			}
			return value;
		}
	});

	ns.FormatE = FormatE;
})();


	// --- CLASS FORMAT-L ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatL = function() {
		// Chain the constructors
		FormatL.superclass.constructor.call(this, 'L');

        delete this.syntax;
	};

	Y.extend( FormatL, ns.Format, {
		syntax: "MM.JJ",

		errorMsg: "Geben Sie bitte einen Monat im Format MM.JJ oder MMJJ ein.",

		validator: function( value ) {
			var regexp = /^([0,1]\d)\.(\d\d)$/,
				parts, m, date, y;
			//                        console.log( regexp.test( value ),regexp.exec( value ) );
			if( null === value || "" === value ) {
				return true;
			}
			if( regexp.test( value )) {
				parts = regexp.exec( value );
				m = parseInt(parts[1],10), y = this._getValidYear(parts[2]);
				date = new Date( y, m-1 /*Monate fangen bei 0 an.*/, 1 );
				//                                console.log( y, m, date.getFullYear() );
				// Wenn Tag und Jahr jetzt noch die gleichen sind -> ist's valide.
				return y === date.getFullYear();
			}
			return false;
		},

		normalizer: function( value ) {
			var parts;
			if( null === value || "" === value ) {
				return "";
			}
			if( -1 === value.indexOf(".") && 4 === value.length ) {
				return value.substring(0,2).concat(".",value.substring(2,4));
			}
			parts = value.split(".");
			if( 2 === parts.length && (3 > parts[0].length && 3 > parts[1].length )) {
				return  this._getZeroPaddedValue( parts[0], 2).concat(
						".", this._getZeroPaddedValue( parts[1], 2)
				);
			}
			return value;
		}
	});

	ns.FormatL = FormatL;

})();

	// --- CLASS FORMAT-K ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatK = function() {
		// Chain the constructors
		FormatK.superclass.constructor.call(this, 'K');

        delete this.syntax;
	};

	Y.extend( FormatK, ns.Format, {
		syntax: "MM",

		errorMsg: "Geben Sie bitte einen Monat im Format MM oder M ein.",

		validator: function( value ) {
			var regexp = /^([0,1]\d)$/,
				i;
//                        console.log( regexp.test( value ),regexp.exec( value ) );
			if( null === value || "" === value ) {
				return true;
			}
			if( regexp.test( value )) {
				i = parseInt( value, 10 );
//                                console.log( i , 0 < i , i < 13 );
				return 0 < i && i < 13;
			}
			return false;
		},

		normalizer: function( value ) {
			if( null === value || "" === value ) {
				return "";
			}
			if( 1 === value.length ) {
				return this._getZeroPaddedValue( value, 2);
			}
			return value;
		}
	});

	ns.FormatK = FormatK;
})();


	// --- CLASS FORMAT-W ------------------------------------------------------------------------------------------------


(function() {
	/**
	 * Creates this format.
	 */
	var FormatW = function() {
		// Chain the constructors
		FormatW.superclass.constructor.call(this, 'W');

        delete this.syntax;
	};

	Y.extend( FormatW, ns.Format, {
		syntax: "JJJJ/JJJJ",

		errorMsg: "Geben Sie bitte ein Wirtschaftsjahr im Format JJJJ/JJJJ oder JJJJ ein.",

		validator: function( value ) {
			var regexp = /^(\d{4})\/(\d{4})$/,
				parts, i, j;
//                        console.log( regexp.test( value ),regexp.exec( value ) );
			if( null === value || "" === value ) {
				return true;
			}
			if( regexp.test( value )) {
				parts = value.split("/");
				i = parseInt( parts[0], 10 );
				j = parseInt( parts[1], 10 );
//                                console.log( i , j , i+1 == j );
				return i+1 === j;
			}
			return false;
		},

		normalizer: function( value ) {
			if( null === value || "" === value ) {
				return "";
			}

			var j1, j2, parts;
			if( -1 === value.indexOf("/")) {
				if( isNaN(value)) {
					return value;
				}
				if( 2 >= value.length ) {
					j1 = this._getValidYear(value);
					j2 = j1+1;
				} else if( 4 === value.length ) {
					j1 = this._getValidYear( value.substring(0,2) );
					j2 = this._getValidYear( value.substring(2) );
				} else if( 8 === value.length ) {
					j1 = value.substring(0,4);
					j2 = value.substring(4);
				} else {
					return value;
				}
				return String(j1).concat("/",j2);
			} else {
				parts = value.split("/");
				if( 2 === parts.length ) {
					j1 = this._getValidYear( parts[0] );
					j2 = this._getValidYear( parts[1] );
				}
				return String(j1).concat("/",j2);
			}
		}
	});

	ns.FormatW = FormatW;
})();

	// --- CLASS FORMAT-JAHR ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatJAHR = function() {
		// Chain the constructors
		FormatJAHR.superclass.constructor.call(this, 'JAHR');

		delete this.syntax;
	};

	Y.extend( FormatJAHR, ns.Format, {
		syntax: "JJJJ",

		errorMsg: "Geben Sie bitte eine Jahreszahl im Format JJJJ ein.",

		validator: function( value ) {
			var regexp = /^(\d{4})$/,
				result;
			if( null === value || "" === value ) {
				return true;
			}
			result = regexp.exec(value);
			return null !== result;
		}
	});

	ns.FormatJAHR = FormatJAHR;
})();


	// --- CLASS FORMAT-ZEIT ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatZEIT = function() {
		// Chain the constructors
		FormatZEIT.superclass.constructor.call(this, 'ZEIT');

		delete this.syntax;
	};

	Y.extend( FormatZEIT, ns.Format, {
		syntax: "HH:MM",

		errorMsg: "Geben Sie bitte eine Zeit im Format HH:MM ein.",

		validator: function( value ) {
			var regexp = /^([2][0-3]|[01]?[0-9])([:][0-5][0-9])?$/,
				result;
			if( null === value || "" === value ) {
				return true;
			}
			result = regexp.exec(value);
			return null !== result;
		},

		normalizer: function( value ) {
			if( null === value || "" === value ) {
				return "";
			}
			if(value.length == 1 && value.indexOf(":") < 0) {
				value = this._getZeroPaddedValue(value, 2);
			}
			if(value.length == 2 && value.indexOf(":") < 0) {
				if(!isNaN(value)) {
					mm = parseInt(value);

					if(mm > 24) return value;
				}
				value = value+"00";
			}
			if(value.length == 3 && value.indexOf(":") < 0) {
				var mm = value.substring(value.length-2);
				if(!isNaN(mm)) {
					mm = parseInt(mm);

					if(mm > 59) return value;
				}
				value = this._getZeroPaddedValue(value, 4);
			}
			if(value.length == 4 && value.indexOf(":") < 0) {
				value = value.substring(0,value.length-2)+":"+value.substring(value.length-2);
			}
			if(value.length > 3 && value.length < 5 && value.indexOf(":") > 0) {
				value = this._getZeroPaddedValue(value, 5);
			}
			return value;
		}
	});

	ns.FormatZEIT = FormatZEIT;
})();


	// --- CLASS NUMBER-FORMAT --------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var NumberFormat = function(name) {
		// Chain the constructors
		NumberFormat.superclass.constructor.call(this, name);
	};

	Y.extend( NumberFormat, ns.Format, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte eine Zahl ein.",

		validator: /^(-?\d+(\,\d+)?)?$/,

		// should be overwritten as needed.
		prettifyerConfig: {},

		normalizerConfig: {},

		prettifyer: function(value) {
			// FIXME: value should allways be a number. So remove the string parsing part! (sometime in the future).
			var value2;
			if( Y.Lang.isNumber( value )) {
				value2 = value;
			}  else {
				// TODO: löschen wenn keine Strings mehr hier rein kommen!
				// das Value kommt immer als deutsche Zahl
				// 1. Komma umwandeln
				value2 = value.replace('.', '').replace(',', '.');
				// 2. Nach Vorgabe formatieren.
				value2 = Y.DataType.Number.parse(value2);
			}
			// TODO: the config should be created only once at creation time.
			value2 = Y.DataType.Number.format(value2, Y.mix( this.prettifyerConfig,
					NumberFormat.DEFAULT_PRETTIFYER_FORMAT ));

			//ONSE-3050: value kann auch fehlerhaft sein, dann original zurueckliefern
			return (undefined !== value2 && null !== value2) ? value2 : value;
		},

		normalizer: function(value, truncate, roundUp) {
			// Gruppierungen entfernen
			if( "" === value ) {
				return value;
			}
			var normalized = value.replace(/\./g, "");
			normalized = normalized.replace(/,/, '.');

			//nicht kaufmaennisch runden sondern abschneiden
			if(truncate && normalized.indexOf('.') > 0) {
				normalized = normalized.substr(0, normalized.indexOf('.'));
			}

			//nicht kaufmaennisch runden sondern aufrunden
			if(roundUp && normalized.indexOf('.') > 0) {
				try {
					normalized = Math.ceil(normalized);
				}
				catch(ex) {
				}
			}

			// 2. Nach Vorgabe formatieren.
			normalized = Y.DataType.Number.parse(normalized);
			if( null === normalized ) {
				return value;
			}
			normalized = Y.DataType.Number.format(normalized, Y.mix( this.normalizerConfig,
					NumberFormat.DEFAULT_NORMALIZER_FORMAT ));
			return normalized;
		},

		parser: PARSER_FLOAT


	}, {
		DEFAULT_PRETTIFYER_FORMAT: {
			thousandsSeparator: ".",
			decimalSeparator: ","
		},
		DEFAULT_NORMALIZER_FORMAT: {
			decimalSeparator: ","
		}

	});

	ns.NumberFormat = NumberFormat;
})();

// --- CLASS FORMAT-N ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatN = function() {
		// Chain the constructors
		FormatN.superclass.constructor.call(this, 'N');
	};

	Y.extend( FormatN, ns.NumberFormat, {

		validator: /^(-?\d+)?$/,

		prettifyerConfig: {
			decimalPlaces: 0
		},

		normalizerConfig: {
			decimalPlaces: 0
		},

		parser : PARSER_INT
	});

	ns.FormatN = FormatN;
})();

	// --- CLASS FORMAT-N ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatM = function() {
		// Chain the constructors
		FormatM.superclass.constructor.call(this, 'M');
	};

	Y.extend( FormatM, ns.NumberFormat, {

        errorMsg: "Geben Sie hier bitte eine ganze Zahl ein. Eine Null ist nicht erlaubt.",

        validator: function( value ) {
            var regexp = /^(\d+)?$/;

            if( null === value || "" === value ) {
                return true;
            }

            if( regexp.test( value ) ) {
                return 0 !== parseInt( value, 10 );
            }
            return false;
        },


		prettifyerConfig: {
			decimalPlaces: 0
		},

		normalizerConfig: {
			decimalPlaces: 0
		},

		parser : PARSER_INT
	});

	ns.FormatM = FormatM;

})();


	// --- CLASS FORMAT-P ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatP = function() {
		// Chain the constructors
		FormatP.superclass.constructor.call(this, 'P');
	};

	Y.extend( FormatP, ns.NumberFormat, {

		errorMsg: "Geben Sie hier bitte eine Zahl mit bis zu zwei Nachkommastellen ein.",

		validator: /^(-?\d+\,\d{2})?$/,

		prettifyerConfig: {
			decimalPlaces: 2
		},

		normalizerConfig: {
			decimalSeparator: ",",
			decimalPlaces: 2
		}

	} );

	ns.FormatP = FormatP;

})();

	// --- CLASS FORMAT-POS ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatPOS = function() {
		// Chain the constructors
		FormatPOS.superclass.constructor.call(this, 'POS');
	};

	Y.extend( FormatPOS, ns.NumberFormat, {

		errorMsg: "Geben Sie hier bitte eine positive Zahl mit bis zu zwei Nachkommastellen ein.",

		validator: /^(\d+\,\d{2})?$/,

		prettifyerConfig: {
			decimalPlaces: 2
		},

		normalizerConfig: {
			decimalSeparator: ",",
			decimalPlaces: 2
		}

	} );

	ns.FormatPOS = FormatPOS;
})();


	// --- CLASS FORMAT-G ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatG = function() {
		// Chain the constructors
		FormatG.superclass.constructor.call(this, 'G');
	};

	Y.extend( FormatG, ns.NumberFormat, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte eine positive Zahl ohne Nachkommastellen ein. Eine Null ist nicht erlaubt.",

		validator: function( value ) {
			var regexp = /^(\d+)?$/;
//            console.log( value );
			if( null === value || "" === value ) {
				return true;
			}
			//console.log( "regexp.test( value )", regexp.test( value ));
			if( regexp.test( value ) ) {
				return 0 !== parseInt( value, 10 );
			}
			return false;
		},

		prettifyerConfig: {
			decimalPlaces: 0
		},

		normalizerConfig: {
			decimalSeparator: ",",
			decimalPlaces: 0
		}

	} );

	ns.FormatG = FormatG;
})();

	// --- CLASS FORMAT-U ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatU = function() {
		// Chain the constructors
		FormatU.superclass.constructor.call(this, 'U');
	};

	Y.extend( FormatU, ns.NumberFormat, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte eine positive Zahl ohne Nachkommastellen ein.",

		validator: function( value ) {
			var regexp = /^(\d+)?$/;
			if( null === value || "" === value ) {
				return true;
			}
			return regexp.test( value );
		},

		prettifyerConfig: {
			decimalPlaces: 0
		},

		normalizerConfig: {
			decimalPlaces: 0
		}

	} );

	ns.FormatU = FormatU;

})();

	// --- CLASS FORMAT-R ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatR = function() {
		// Chain the constructors
		FormatR.superclass.constructor.call(this, 'R');
	};

	Y.extend( FormatR, ns.NumberFormat, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte eine positive Zahl mit bis zu zwei Nachkommastellen ein. Eine Null ist nicht erlaubt.",

		validator: function( value ) {
			var regexp = /^(\d+\,\d{2})?$/,
				normalized;
//            console.log( value );
			if( null === value || "" === value ) {
				return true;
			}
//            console.log( "regexp.test( value )", regexp.test( value ));
			if( regexp.test( value ) ) {
				normalized = value.replace(/\./g, "");
				normalized = normalized.replace(/,/, '.');
//                console.log( "parseFloat( normalized )", parseFloat( normalized ));
//                console.log( "0 != parseFloat( normalized )", 0 != parseFloat( normalized ));
				return 0 !== parseFloat( normalized );
			}
			return false;
		},

		prettifyerConfig: {
			decimalPlaces: 2
		},

		normalizerConfig: {
			decimalSeparator: ",",
			decimalPlaces: 2
		}

	} );

	ns.FormatR = FormatR;
})();


	// --- CLASS FORMAT-S ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatS = function() {
		// Chain the constructors
		FormatS.superclass.constructor.call(this, 'S');
	};

	Y.extend( FormatS, ns.NumberFormat, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte eine Zahl mit bis zu zwei Nachkommastellen ein. Eine Null ist nicht erlaubt.",

		validator: function( value ) {
			var regexp = /^(-?\d+\,\d{2})?$/,
				normalized;
//            console.log( value );
			if( null === value || "" === value ) {
				return true;
			}
//            console.log( "regexp.test( value )", regexp.test( value ));
			if( regexp.test( value ) ) {
				normalized = value.replace(/\./g, "");
				normalized = normalized.replace(/,/, '.');
//                console.log( "parseFloat( normalized )", parseFloat( normalized ));
//                console.log( "0 != parseFloat( normalized )", 0 != parseFloat( normalized ));
				return 0 !== parseFloat( normalized );
			}
			return false;
		},

		prettifyerConfig: {
			decimalPlaces: 2
		},

		normalizerConfig: {
			decimalSeparator: ",",
			decimalPlaces: 2
		}

	} );

	ns.FormatS = FormatS;
})();


	// --- CLASS FORMAT-F ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatF = function() {
		// Chain the constructors
		FormatF.superclass.constructor.call(this, 'F');
	};

	Y.extend( FormatF, ns.NumberFormat, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte einen positiven Wert mit bis zu vier Nachkommastellen ein.",

		validator: /^(\d+(\,\d{0,4})?)?$/

	} );

	ns.FormatF = FormatF;
})();


	// --- CLASS FORMAT-V ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatV = function() {
		// Chain the constructors
		FormatV.superclass.constructor.call(this, 'V');
	};

	Y.extend( FormatV, ns.NumberFormat, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte einen positiven Wert mit bis zu vier Nachkommastellen ein.",

		validator: /^(-?\d+(\,\d{0,4})?)?$/

	} );

	ns.FormatV = FormatV;

})();


	// --- SPECIAL-FORMATS ------------------------------------------------------------------------------------------------
// --------------------------------------------------------------------------------------------------------------------


// --- CLASS FORMAT-O (PLZ) -------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatO = function() {
		// Chain the constructors
		FormatO.superclass.constructor.call(this, 'O');
	};

	Y.extend( FormatO, ns.Format, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte einen positiven Wert ein.",
		//msg darf nicht postleitzahl enthalten da das format auch an anderer stelle benutzt wird
		// TODO: hier können wir via AJAX die PLZ überprüfen und dann auch den Ort setzen.
		validator: /^(\d*)?$/
	});

	ns.FormatO = FormatO;
})();


	// --- CLASS FORMAT-A (Steuernummer) ----------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatA = function() {
		// Chain the constructors
		FormatA.superclass.constructor.call(this, 'A');
	};

	Y.extend( FormatA, ns.Format, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte eine gültige Steuernummer an.",

		// TODO: hier können wir via AJAX die Steuernummer überprüfen.
		validator: /^(\d{4}0\d{8})?$/
	});

	ns.FormatA = FormatA;
})();



	// --- CLASS FORMAT-I (Identifikationsnummer) -------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatI = function() {
		// Chain the constructors
		FormatI.superclass.constructor.call(this, 'I');
	};

	Y.extend( FormatI, ns.Format, {
		syntax: "",

		errorMsg: "Geben Sie hier bitte eine Identifikationsnummer an.",

		// TODO: hier können wir via AJAX die Identifikationsnummer überprüfen.
		validator: /^(\d{11})?$/
	});

	ns.FormatI = FormatI;
})();

	// ----------------------------------------------------------------------------------------------
// --- ALL NEW FORMATS --------------------------------------------------------------------------
// --- Here we expect object values. No longer strings to prettify.                           ---
// ----------------------------------------------------------------------------------------------
// ----------------------------------------------------------------------------------------------

// --- CLASS EMPTY-FORMAT -------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var EmptyFormat = function() {
		// Chain the constructors
		EmptyFormat.superclass.constructor.call(this, 'EMPTY');
	};

	Y.extend( EmptyFormat, ns.Format, {
		errorMsg: ""
	});

	ns.EmptyFormat = EmptyFormat;
})();

	// --- CLASS FORMAT-CUR -------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	var FormatCUR = function() {
		// Chain the constructors
		FormatCUR.superclass.constructor.call(this, 'CUR');

        delete this.syntax;
	};

	Y.extend( FormatCUR, ns.NumberFormat, {
		syntax: "0,00 €",

		errorMsg: "Geben Sie hier bitte einen Betrag ein.",

		prettifyerConfig: {
			decimalPlaces: 2,
			suffix: '\u00A0\u20AC'
		},

		normalizerConfig: {
			decimalPlaces: 2
		},

		parser: PARSER_FLOAT

	});

	ns.FormatCUR = FormatCUR;
})();

// --- CLASS FORMAT-OD ------------------------------------------------------------------------------------------------

(function() {
	/**
	 * Creates this format.
	 */
	function FormatOD(config) {
		// Chain the constructors
		FormatOD.superclass.constructor.call(this, 'OD');

		if(config && config.prettifyerConfig) {
			this.prettifyerConfig = config.prettifyerConfig;
		}
	}

	// für od müssen wir nichts überschreiben - die defaults sind ok so.
	Y.extend( FormatOD, ns.NumberFormat, {

		prettifyerConfig: {
			decimalPlaces: 2
		},

		parser: PARSER_FLOAT

	} );

	ns.FormatOD = FormatOD;
})();


}, '1.0.0', {"requires": ["datatype-number", "datatype-date"]});
