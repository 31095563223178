YUI.add('interview-format-factory', function(Y) {

	/**
	 * This factory does not have any instance variables. Everything is done statically.
	 */
	var FormatFactory = function() {
		// nothing to do here.
	};

	// all static properties and methods
	Y.mix(FormatFactory, {
		CLASS_PREFIX: "field-format-",

		_cache: {},

		getInstance: function( f, syntax ) {

            if(!syntax) {
                var format = FormatFactory._cache[f];
                if( format ) {
                    return format;
                }
            }
			format = FormatFactory._newInstance(f, syntax);

            if(!syntax) {
			    FormatFactory._cache[f] = format;
            }
			return format;
		},

		_newInstance: function(name, syntax) {

			switch( name ) {
				case "A":
					return new Y.interview.FormatA();
				case "B": /* "B" ist eigentlich ein Zeitraum wir fragen die beiden Felder aber einzeln ab. */
					return new Y.interview.FormatB();
				case "D":
				case "Z": /* "Z" ist eigentlich ein Zeitraum wir fragen die beiden Felder aber einzeln ab. */
					return new Y.interview.FormatD();
				case "E":
					return new Y.interview.FormatE();
				case "F":
					return new Y.interview.FormatF();
				case "G":
					return new Y.interview.FormatG();
				case "I":
					return new Y.interview.FormatI();
				case "K":
					return new Y.interview.FormatK();
				case "JAHR":
					return new Y.interview.FormatJAHR();
                case "ZEIT":
                    return new Y.interview.FormatZEIT();
				case "L":
					return new Y.interview.FormatL();
                case "N":
                    return new Y.interview.FormatN();
                case "M":
                    return new Y.interview.FormatM();
				case "T":
					return new Y.interview.FormatT();
				case "O":
					return new Y.interview.FormatO();
				case "OD":
					return new Y.interview.FormatOD();
				case "P":
					return new Y.interview.FormatP();
				case "POS":
					return new Y.interview.FormatPOS();
				case "R":
					return new Y.interview.FormatR();
				case "S":
					return new Y.interview.FormatS();
				case "U":
					return new Y.interview.FormatU();
				case "V":
					return new Y.interview.FormatV();
				case "W":
					return new Y.interview.FormatW();
				default:
					return new Y.interview.Format(name, syntax);
			}
		}
	});

	Y.namespace("interview").FormatFactory = FormatFactory;

}, '1.0.0', {requires:[
		'smart-formats'
]} );