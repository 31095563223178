YUI.add('interview-fields', function(Y) {

	var VERBOSE         = false,
		YCNM            = Y.ClassNameManager,
		NODE            = 'node',
		VALUE           = 'value',
		ERROR           = 'error',
		SAVED_VALUE     = 'savedValue',

		ERROR_CHANGE      = 'errorChange',
		READ_ONLY_CHANGE  = 'readOnlyChange',
		MANDATORY_CHANGE  = 'mandatoryChange',
		FOCUS_CHANGE      = 'focusChange',

		DISABLED        = 'disabled',
		READ_ONLY       = 'readOnly',
		MANDATORY       = 'mandatory',

		CLASS_ONSE_FIELD  = "onse-field",
		CLASS_VISIBLE     = "visible",
		CLASS_MANDATORY   = 'case-interview-field-mandatory',
		CLASS_READ_ONLY   = 'case-interview-field-readonly',
		CLASS_EMPTY_FIELD = "case-editor-interview-field-empty",
        CLASS_SHALLOW_FIELD = "case-editor-interview-field-autofilled",
        CLASS_HIDDEN_FIELD = "case-editor-interview-field-hidden",

		MANDATORY_LABEL_TEMPLATE = '<strong class="ui-case-interview-field-mandatory-strong">{text}<span class="case-interview-field-mandatory-marker">*</span></strong>',
		UNIT_NODE_TEMPLATE = '<span class="case-interview-field-unit ui-case-interview-field-unit">{unit}</span>';


// --- CLASS FIELD   --------------------------------------------------------------------------------------------------


	/**
	 * This is the base class for all our Fields.
	 *
	 * @class Field
	 * @extends Y.Base
	 * @constructor
	 * @cfg {object} configuration attributes
	 */
	Y.namespace("interview").Field = Y.Base.create("Field", Y.Base, [], {

		/**
		 * Standardfehlermeldung eines Pflichtfelds.
		 */
		ERROR_MANDATORY: "Dies ist ein Pflichtfeld",

		/**
		 * Das Feld.
		 */
		node: null,

		/**
		 * Die id des DOM-Knotens
		 */
		nodeId: null,


		/**
		 * Das Label zu diesem Feld.
		 */
		label: null,

		/**
		 * Hier kann die ganze Zeile als fehlerhaft markiert werden.
		 * Kann ein LI, TR oder auch TD sein - je nach Anwendungsfall.
		 */
		parentNode: null,

		/**
		 * Alle Daten, die aus der HTML-Datei gelesen wurden.
		 */
		data: null,


		_hasError : false,

		/**
		 * Gibt an ob dies Feld gerade nicht sichtbar ist.
		 */
		notVisible: false,

		/**
		 * Ein Ort um alle Eventlistener dieses Feldes zu sammeln.
		 */
		_listenerHandlers: [],

		/**
		 * Spreadsheets for example can modify text, but return the same value. Calcualtions should be done then, so mark the field as dirty.
		 */
		markDirty : false,

		/**
		 * Überprüft den value auf Syntaxkonformität und malt den Inhalt schön oder signalisiert gefundene Fehler.
		 */
		check: function(doNotPrettify) {
			if(VERBOSE) {
				Y.log("checking field");
			}
			var instance = this;
			// den Wert aus dem Feld lesen.
			instance._syncValue();
			instance.set(ERROR, null);
			// erstmal kurz aufraeumen
			return instance._check(doNotPrettify);

		},

		isChanged : function() {
			if(this.markDirty) {
				return true;
			}

			var instance = this;
			instance._blur();

            //Y.log("saved value/value "+instance.get(SAVED_VALUE)+"/"+instance.get(VALUE));

			return (instance.get(SAVED_VALUE) != instance.get(VALUE)  );
		},


		_fireChange: function() {
            this.fire('interview:fieldChanged', {field:this.node, yuiInstance:this});
		},

        _fireValueChange: function() {
            this.fire('interview:fieldValueChanged', {field:this.node, yuiInstance:this});
        },

        /**
         * public api for the change event.
         *
         * We need to touch every field when ever an inline area is shown or hidden. That way we make sure to show/hide
         * all cascaded inline areas which might depend on values of a field in the just shown/hidden area.
         *
         * Note: This is not the best way of solving this Problem as we can not separate between user changes and touch
         * events. A better way would be to have the form manage the visibility rules whenever an inline area changes
         * its visibility.
         *
         * There is a bug depending on this note (ONSE-7076) with an repro case descripted in its comments.
         */
        touch : function() {
            this._fireChange();
        },

		_syncValue: function() {
			// nearly emtpy. simply return the value here.
			this.set(VALUE, this.node.get(VALUE));
		},

		/**
		 * Überprüft den value auf Syntaxkonformität und malt den Inhalt schön oder signalisiert gefundene Fehler.
		 */
		_check: function(doNotPrettify) {
			if( this.notVisible ) {
				return true;
			}
			// Test auf Pflichtfeld.
			return this._checkMandatory(doNotPrettify);
		},


		_checkMandatory: function( doNotPrettify ) {
			if(this.get(MANDATORY) && "" == this.get(VALUE)) {
				this.set(ERROR, this.ERROR_MANDATORY);
				if( !doNotPrettify ) {
					this.prettifyValue();
				}
				return false;
			}
			return true;
		},


		prettifyValue: function() {
			if(VERBOSE) {
				Y.log("Field: prettifyValue");
			}
			// empty implementation can be overwritten by specific classes.
		},

		show: function() {
			this.notVisible = false;
		},

		hide: function() {
			this.notVisible = true;
			this.set(ERROR,null);
		},

		/**
		 * Bereitet den Versandt dieses Feldes vor.
		 */
		readyForSubmit: function() {
			var instance = this;
			if( instance.notVisible ) {
				return true;
			}
			instance._blur();
            instance._check(true);
			return !instance._hasError;
		},


		/**
		 * This will extract, trim and strore the label text of this field.
		 *
		 * @param labelNode The node containing the text.
		 * @private
		 */
		_processLabel: function() {
			var that = this, labelNode, labelText = "";
			labelNode = that._labelTextNode = that.parentNode.one('.ui-case-interview-field-label-text');
			if(labelNode) {
				labelText = labelNode.getHTML();
				labelText = Y.Lang.trim(labelText);
				labelNode.setHTML(labelText);
			}
			this.set('label', labelText);
		},

		/**
		 * YUI3 initializer.
		 */
		initializer: function(config) {
			if( null == config.node ) {
				Y.error("Das Attribute \"node\" wird zwingend benoetigt.");
			}
			var instance = this,
					node = instance.get(NODE);



			// publish the events that could be thrown by a field.
			instance.publish(ERROR, {
				emitFacade: true,
				preventable: false,
				defaultFn: instance._handleError,
				context: instance
			});

			instance.publish('clear-error', {
				emitFacade: true,
				preventable: false,
				defaultFn: instance._handleClearError,
				context: instance
			});

			instance.publish('field-focus', {
				emitFacade: true,
				defaultFn : instance._focus,
				context   : instance
			});

			instance.publish('field-blur', {
				emitFacade: true,
				context   : instance
			});

			instance.publish('field-click', {
				emitFacade: true,
				context   : instance
			});

			instance.node = node;
			node.addClass( CLASS_ONSE_FIELD );
			node.addClass( CLASS_VISIBLE );
			instance.nodeId = node.get("id");
			instance.set('node-name', node.get("name"));
			instance.parentNode = node.ancestor('.ui-case-interview-field') || node.get('parentNode').get('parentNode');
			instance.parentNode.addClass(instance.nodeId);
			instance.set('container', instance.parentNode);

			// find, trim and store the label text and its node.
			instance._processLabel();

			instance.set('defaultMandatory', instance.get(MANDATORY));
			// add the event target.
			instance.addTarget( config.form );

			//console.log(this.get('name')+":"+this.get('formName')+":"+this.get('mfIndex')+":"+this.get('mfaIndex'));

            this.set(
            	'templateValue',
				!Y.UserCase.hasValueSet(
					this.get('name'),
					this.get('formName'),
					this.get('mfIndex'),
					this.get('mfaIndex')
				)
			);

			// listen for changes of the hidden attribute
			instance._listenerHandlers.push( instance.after('hiddenChange', instance._syncHidden, instance));
		},


		_handleError : function() {
			this._hasError = true;
			this.parentNode.addClass("field-invalid");
		},

		_handleClearError : function() {
			this._hasError = false;
			this.parentNode.removeClass("field-invalid");
		},


		/**
		 * As long as this is not a widget, we have to have our own render method.
		 */
		render: function() {
			var instance = this;
			instance._renderUI();
			instance._bindUI();
			instance._syncUI();
		},

		_renderUI: function() {
			// empty
		},

		/**
		 * Listener für wichtige allgemeine Ereignisse. Muss von abgeleiteten Klassen aufgerufen werden.
		 */
		_bindUI: function() {
			var instance = this;
			instance._listenerHandlers.push(instance.after(READ_ONLY_CHANGE, instance._handleReadOnlyChanged, instance ));
			instance._listenerHandlers.push(instance.after(MANDATORY_CHANGE, instance._handleMandatoryChanged, instance ));
			instance._listenerHandlers.push(instance.after(ERROR_CHANGE, instance._handleErrorChanged, instance ));
			instance._listenerHandlers.push(instance.node.on("focus", instance._fireFocus, instance));
			instance._listenerHandlers.push(instance.node.on("blur", instance._blur, instance));
			instance._listenerHandlers.push(instance.node.on("click", instance._fireClick, instance));
			instance._listenerHandlers.push(instance.after(FOCUS_CHANGE, instance._checkForBlur, instance ));
            if( instance.get('templateValue') ) {
                instance._listenerHandlers.push( instance.node.on("keyup", instance._removeShallow, instance ));
            }
            instance._listenerHandlers.push( instance.node.on("keydown", instance._checkValidKeys, instance ));

		},

		_handleReadOnlyChanged : function(e) {
			this._syncReadOnly(e.newVal);
		},

		_handleMandatoryChanged : function(e) {
			this._syncMandatory(e.newVal);
		},

		_handleErrorChanged : function(e) {
			var instance = this;
			if( e.newVal == null ) {
				instance.fire("clear-error", {field:instance});
			} else {
				instance.fire(ERROR, {field:instance});
			}
		},

		_syncReadOnly : function(ro) {
			var instance = this,
					node = instance.get(NODE),
					parent = instance.parentNode;
			if(ro) {
				node.setAttribute(DISABLED,DISABLED);
				parent.addClass(CLASS_READ_ONLY);

				//ONSE-11858 fix for readonly fieldrule radios
				var node2 = node.ancestor('.case-interview-field-input-checkbox-radio-container');

				if(node2) {
                    node2.setStyle('width', '40px');

                    if (node2.one('input[type="radio"]')) {
                        node2.one('input[type="radio"]').setAttribute(DISABLED, DISABLED);
                    }
                }

			} else {
				node.removeAttribute(DISABLED);
				parent.removeClass(CLASS_READ_ONLY);

                //ONSE-11858 fix for readonly fieldrule radios
                var node2 = node.ancestor('.case-interview-field-input-checkbox-radio-container');

                if(node2 && node2.one('input[type="radio"]')) {
                	node2.one('input[type="radio"]').removeAttribute(DISABLED);
                }
			}
		},

		_syncMandatory : function(mandatory) {
			var that = this,
				parent = that.parentNode,
				labelNode = that._labelTextNode;

			if(mandatory) {
				parent.addClass(CLASS_MANDATORY);
				labelNode.setHTML(Y.Lang.sub(MANDATORY_LABEL_TEMPLATE, {text:that.get('label')}));
			} else {
				parent.removeClass(CLASS_MANDATORY);
				labelNode.setHTML(that.get('label'));
			}
			// FIXME: das muss weg wenn in fieldrules.js direkt set('mandatory',...) aufgerufen wird.
			this.set(MANDATORY, mandatory);
		},

		_syncUnit : function() {
			var that = this,
				unit = that.get('unit'),
				parent = that.parentNode,
				node;

			if(that._unitNode) {
				that._unitNode.remove(true);
				parent.removeClass('case-interview-field-has-unit');
			}

			if( unit ) {
				node = that.node;
				that._unitNode = Y.Node.create(Y.Lang.sub(UNIT_NODE_TEMPLATE, {unit:unit}));
				node.insert(that._unitNode, 'after');
				parent.addClass('case-interview-field-has-unit');
			}
		},

		/**
		 * Gets called when ever the value of this fields attribute "hidden" changes.
		 *
		 * @param e The change event.
		 * @private
		 */
		_syncHidden: function() {
			var that = this,
				hidden = that.get('hidden'),
				container = that.get('container');

			if(hidden) {
				container.addClass(CLASS_HIDDEN_FIELD);
			} else {
				container.removeClass(CLASS_HIDDEN_FIELD);
			}
		},


		_syncUI: function() {
			var instance = this;
			instance._syncReadOnly(instance.get(READ_ONLY));
			instance._syncMandatory(instance.get(MANDATORY));
			instance._syncUnit();
			instance._syncHidden();
		},

		_setNodeValue: function(value) {
			this.node.set(VALUE,value,{src:'field'});
            //FIXME: this is ugly here but can't be on init. refactor so EMPTY_CLASS is set on all nodes, not only on textfields, then init after that
            this._initShallow();
		},


		setAutoSuggestValue: function(value) {
			if(!this.overwrite) {
				this.plug(Y.interview.AutosuggestOverwritePlugin);
			}
			this.overwrite.set('value', value);
		},

        _setTemplateValueAfterInit : function(value) {
            var instance = this;

            if(instance.node.hasClass(CLASS_EMPTY_FIELD)) {
                instance.node.removeClass(CLASS_EMPTY_FIELD);
            }

            instance.set('templateValue', true);

            instance._listenerHandlers.push(instance.node.on("keyup", instance._removeShallow, instance));

            instance._setNodeValue(value);
            instance._syncValue();

            instance.touch();
        },

        _initShallow: function() {
            if(this.get('templateValue') && !this.get('templateValueHadFocus') && !this.node.hasClass(CLASS_SHALLOW_FIELD) && !this.node.hasClass(CLASS_EMPTY_FIELD)) {
                Y.log('template node: non-empty value set, adding shallow');

                this.node.addClass(CLASS_SHALLOW_FIELD);
            }
        },

        _removeShallow : function() {
            if(this.get('templateValueHadFocus') && this.node.hasClass(CLASS_SHALLOW_FIELD)) {
                Y.log('template node had focus, removing shallow');

                this.node.removeClass(CLASS_SHALLOW_FIELD);
            }
        },

        _checkValidKeys : function(e) {
            var that = this;

            if(that.format instanceof Y.interview.NumberFormat && e.keyCode == 190) { //PREVENT "." as of ONSE-8656
                e.preventDefault();
            }
        },

		_fireFocus : function() {
			this.fire('field-focus', {fieldNode: this.node, field:this.node, yuiInstance: this});
		},

		_fireClick : function() {
			this.fire('field-click', {fieldNode: this.node, field:this.node, yuiInstance: this});
		},

		_focus : function() {
			var instance = this,
				parent = instance.parentNode;
			instance.set('focus', true);
			parent.addClass('field-focused');

		},

		_blur : function() {
			var instance = this,
				parent = instance.parentNode;
			instance.set('focus', false);
			parent.removeClass('field-focused');

            instance.fire('field-blur', {fieldNode: this.node, field:this.node, yuiInstance: this});
		},

		// dieses Event hat nichts mit der methode _blur zu tun!
		_checkForBlur : function(e) {
            this.set('templateValueHadFocus', true);
		},

		/**
		 * clean up all listeners
		 */
		destructor: function() {
			var instance = this;

			Y.Array.each( instance._listenerHandlers, function(it) {
				it.detach();
			});
			if( this.tools ) {
				this.tools.destroy()
			}
			instance.node.remove(true);
		}


	}, {
		NAME: "Field",

		CLASS_EMPTY_FIELD: CLASS_EMPTY_FIELD,

		ATTRS: {
			node: {
				writeOnce: 'initOnly'
			},
			// the form in which this field is rendered and our event target.
			form: {
				writeOnce: true
			},
			// The real value of the field.
			value: {
				value: null
			},
			// the name of the field
			name: {},
			// the name of the form this field is on.
			formName: {},
			// the name of the field node
			'node-name': {},
			// the label text
			label: {
				value: ""
			},
			unit: {
				value: null
			},
			// the data format
			format: {
				value : null
			},
			maxSize: {
				value : null
			},
			savedValue : {
				value : null
			},

			mfIndex : {
			},
			mfaIndex : {
				value : 0
			},
			/**
			 *  nur Anzeigen - nicht schreiben?
			 */
			readOnly: {
				value : false,
				validator: Y.Lang.isBoolean
			},
			/**
			 *  Pflichtfeld?
			 */
			mandatory: {
				value : false,
				validator: Y.Lang.isBoolean
			},
			defaultMandatory: {
				value : false,
				validator: Y.Lang.isBoolean
			},
            /**
             * Nur Schabloneneintrag?
             */
            templateValue: {
				value : false,
				validator: Y.Lang.isBoolean
			},
            templateValueHadFocus: {
				value : false,
				validator: Y.Lang.isBoolean
			},
			/**
			 * Sollte das Feld nicht valide sein, steht hier eine Fehlermeldung.
			 */
			error : {
				value : null
			},
			/**
			 * Die Feldhilfe zu diesem Feld. Bleibt leer wenn es keine Hilfe gibt.
			 */
			help : {
				value : ''
			},
            /**
			 * Overwrite preconfigured format error.
             */
			overwriteError : {
				value: ''
			},
			/**
			 * Dieses Feld hat den Fokus.
			 */
			focus : {
				value : false,
				validator: Y.Lang.isBoolean
			},
			/**
			 * Der alles umschließende Container.
			 */
			container: {
				value : null
			},

			/**
			 * If set to true, this will hide the entire field together with its container and any extra elements.
			 */
			hidden: { value:false },

			/**
			 * Any number of tools to help finding the right value for this field, or to fill other fields when this
			 * fields value is changed.
			 */
			// TODO: Tools should be refactored into an extension.
			tools: {}

		}
	} );


// --- CLASS TEXT FIELD   ---------------------------------------------------------------------------------------------

	/**
	 * Constructor
	 */
	var TextField = function( ) {
		TextField.superclass.constructor.apply(this, arguments);
	};


	Y.mix(TextField, {
		NAME: "TextField"
	});


	Y.extend(TextField, Y.interview.Field,

			/**
			 * Prototype fields/methods
			 */
			{

				/**
				 * Standardfehlermeldung eines Text-Pflichtfelds.
				 */
				ERROR_MANDATORY: "Bitte f\u00FCllen Sie dieses Feld aus.",

				/**
				 * Jedes Textfeld braucht ein Format.
				 */
				format: null,

				/**
				 * Der Wert, der angezeigt wird, wenn das Feld keinen Focus hat.
				 */
				prettyValue: "",

				/**
				 * Timer zum verzögerten Aufruf von check.
				 */
				_delayTimer: null,

				_handlers: [],

				truncate : false,

				roundUp : false,

				/**
				 * Initialisiert dieses Feld.
				 */
				initializer: function(config) {
					if(VERBOSE) {
						Y.log("init", null, "TextField");
					}

					this.truncate = config.truncate;
					this.roundUp = config.roundUp;
                    this.format = Y.interview.FormatFactory.getInstance(this.get('format'), config.overrideFormat);

					this.node.addClass( this.format.className );
				},

				/**
				 * Erzeugt eine schöne Darstellung des Werts (value).
				 */
				prettifyValue: function() {
					if(VERBOSE) {
						Y.log("prettifyValue", null, "TextField");
					}
					if( "" == this.get(VALUE) ) {
						this.prettyValue = this.format.syntax;
					} else {
						this.prettyValue = this.format.prettify(this.get(VALUE));
					}

					this._setNodeValue(this.prettyValue);

				},

				/**
				 * Beim Eintreten in des Feld und beim Verschicken des Formulars muss der Wert des Feldes in eine
				 * Form gebracht werde, die sich verarbeiten lässt.
				 */
				plainValue: function() {
					this._setNodeValue( this.get(VALUE) );
				},

				/**
				 * This will do any extra markup work for this field.
				 *
				 * @private
				 */
				_renderUI : function() {
					var that = this,
						maxSize = that.get('maxSize'),
						orgMaxSize = maxSize,
						format = that.format,
						cfg;

					TextField.superclass._renderUI.apply(that, arguments );




					if( null != maxSize && 0 < maxSize ) {
						// check for numbers
						if( format instanceof Y.interview.NumberFormat ) {
							// in case of decimal places we will need some extra room
							cfg = format.prettifyerConfig;
							if( cfg ) {
								// add the extra places
								// in case there is no limitation we'll take 20 which should be sufficient.
								maxSize += (cfg.decimalPlaces?cfg.decimalPlaces:20);
								// add on extra place for the separator
								maxSize++;
							}
							// when binding the ui we might have to deal with this special arrangement.
							if(maxSize !== orgMaxSize) {
								that._dynamicMaxLength = Y.rbind(that._checkDynamicMaxLength, that,
												orgMaxSize,(cfg.decimalSeparator || ',') );
							}
						}

						that.node.setAttribute("maxlength", maxSize );
					}
				},

				_bindUI: function() {
					var that = this;
					TextField.superclass._bindUI.apply(that, arguments );
					that.after("valueChange", that._afterValueChange, that);
					that.node.after("valueChange", that._afterNodeValueChange, that);
					that.after("focusChange", that._handleFocusChange, that);
				},

				_syncUI : function() {
					var instance = this;
					TextField.superclass._syncUI.apply(instance, arguments);
					//read the current value
					instance._syncValue(); // sync the value
					instance.set(SAVED_VALUE, instance.get(VALUE));
					instance.prettifyValue();
				},

				_syncValue: function() {
					var instance = this;
					instance.set(VALUE, instance.format ? instance.format.normalize( instance.node.get(VALUE) ,
							instance.truncate, instance.roundUp ) : instance.node.get(VALUE));
				},

				/**
				 * Überprüft den value auf Syntaxkonformität und malt den Inhalt schön oder signalisiert gefundene Fehler.
				 *
				 * Da wir hier in bestimmten Situationen den Wert noch anhübschen wollen, müssen wir ein bisschen
				 * Funktionalität kopieren.
				 */
				_check: function(doNotPrettify) {
					var that = this;
//                Y.log("######## check #######");
					if( that.notVisible ) {
						return true;
					}

					// Test auf Pflichtfeld.
					if( !that._checkMandatory(doNotPrettify) ) {
						return false;
					}

					if( that._dynamicMaxLength && !that._dynamicMaxLength()) {
						return false;
					}

					// validieren
					return that._checkFormat(doNotPrettify);
				},

				 _checkDynamicMaxLength : function(max, separator) {
					var that = this,
						val = that.get('value');
					// we have to manually watch after the max length as long as there is no decimal
					// separator in the value.
					if( val && 0 < val.length &&
							(-1 === val.indexOf(separator) || max < val.indexOf(separator))) {
						// now only when the maximum is reached we will prevent the change.
						if(val.length > max ) {
							that.set('error', 'Sie können maximal ' + max +
									' Stellen (+ Nachkommastellen) angeben.');
							return false;
						}
					}
					return true;
				},

				_checkFormat: function( doNotPrettify ) {
					if( this.format.isValid( this.get('value') )) {
						// schön machen...
						if( !doNotPrettify ) {
							this.prettifyValue();
						}

						return true;
					} else {
						// ... oder Fehler anzeigen

                        if(this._customMobileFormatInitialized) {
                            //console.log("skipping error while mobile format is active...");
                            return;
                        }

                        if(this.get('overwriteError')) {
                            this.set(ERROR, this.get('overwriteError'));
                        }
                        else {
                            this.set(ERROR, this.format.errorMsg);
                        }
						return false;
					}
				},


				_afterValueChange : function(e) {
					var instance = this,
						node = instance.get(NODE);

                    //console.log("_afterValueChange called");

					// check where the change came from.
					if( "" == e.newVal && !instance.get('focus')) {
						// check the emptyness
						if(VERBOSE) {
							Y.log("setting empty class", null, "TextField");
						}
						node.addClass(CLASS_EMPTY_FIELD);
					}

					// The change came from one of our tools. (A spreadsheet for example.)
					if( e.src && 'tool' === e.src ) {
						if( "" != e.newVal ) {
							node.removeClass(CLASS_EMPTY_FIELD);
						}
						this._setNodeValue(e.newVal);

                        if(!e.skipCheck) {
                            instance.check();
                        }
					}
                    else {
                        this._fireValueChange();
                    }
				},


				_afterNodeValueChange : function(e) {
					if( e.src && 'field' === e.src ) {
						Y.log('_afterNodeValueChange - nothing to do');
						return;
					}
					Y.log('_afterNodeValueChange - fire change event');
					this._fireChange();
				},

				_handleFocusChange : function(e) {
					if(e.newVal) {
						this.focus();
					} else {
						this.blur();
					}
				},

				/**
				 * Entfernt den Focus vom DOM-Knoten dieses Feldes.
				 */
				focus: function() {
					var that = this,
						n = that.node,
						handler = that._handlers;

					if(VERBOSE) {
						Y.log("FOCUS", null, "TextField");
					}
					that.node.removeClass(CLASS_EMPTY_FIELD);
					that.plainValue();

					handler.push(n.on("keydown", that._handleKeyUp, that,
							// textareas and inputs are handled this the same js class for now. We need to
							// differ for this case.
							that.node.get('nodeName').toLowerCase() != 'textarea'));
				},

				_handleKeyUp: function(e, isNoArea) {
					var that = this;

					if( that._delayTimer ) {
						clearTimeout(that._delayTimer);
					}

                    that.lastKeyCode = e.keyCode;

					// check for enter in inputs
					if (isNoArea && e.keyCode && 13 === e.keyCode) {
						e.preventDefault();
						return;
					}
					if( that._hasError ) {
						that._delayTimer = setTimeout(Y.bind(that.check, that, true), 300);
					}
				},

				blur: function() {
					this._unbindUI();
					this.check();
					if( "" == this.get('value') ) {
						this.node.addClass(CLASS_EMPTY_FIELD);
					}
				},

				_unbindUI: function() {
					Y.Array.each( this._handlers, function(it) { it.detach();});
				}


			}
	);


	Y.namespace("interview").TextField = TextField;


// --- CLASS SHEET TEXT FIELD   ---------------------------------------------------------------------------------------

	// TODO: wenn ein FormIndex gelöscht wird, müssen die Indices der TabellenDaten angepasst werden - das muss zuerst
	// auf dem Server passieren - danach auch gerne im Script.

	var OPEN_SHEET     = 'openSheet',
		DELETE_SHEET   = 'deleteSheet',

		DIV_TEMPLATE        = '<div></div>',


		BUTTON_CONTAINER_TEMPLATE = '<div class="case-interview-field-input-tool-container">' +
				'<div class="case-interview-field-input-tool-btns ' +
				'ui-case-interview-field-input-tool-btns"></div></div>',

		// Markup für das Tabellentool, so lange noch keine Tabellendaten vorliegen oder die Tabelle wieder gelöscht
		// wurde (Input ungelockt für freie Eingabe)
		OPEN_SHEET_TEMPLATE = '<button type="button" class="btn btn-s ui-spreadsheet-open" ' +
				'title="Inhalt tabellarisch erfassen">In Tabelle erfassen</button>',

		// Markup, wenn Tabellendaten existieren und das Feld mit der Tabelle verknüpft wurde. Das Input muss gelockt
		// (read-only) sein.
		EDIT_SHEET_TEMPLATE = '<span>Tabelle:</span>' +
		'<button type="button" class="btn btn-s ui-spreadsheet-open" ' +
			'title="Tabelle bearbeiten"><i class="fa fa-pencil"></i><span class="app-a11y-label">Tabelle bearbeiten</span></button>' +
		'<button type="button" class="btn btn-s btn-destructive ui-spreadsheet-delete" ' +
			'title="Gesamte Tabelle löschen"><i class="fa fa-trash-o"></i><span class="app-a11y-label">Tabelle löschen</span></button>';

	/**
	 * Constructor
	 */
	function SheetTextField() {
		SheetTextField.superclass.constructor.apply(this, arguments);
	}

	Y.mix(SheetTextField, {
		NAME: "SheetTextField",

		ATTRS: {
			// spreadsheetId, spreadsheetTitle and spreadsheetRefFieldName are not needed anymore as the
			// information is also stored in the spreadsheetConfig but we let them be for the moment.
			// We don't want to introduce any bugs.
			spreadsheetId : { },
			spreadsheetTitle : { },
			spreadsheetRefFieldName : { },
			spreadsheetConfig : {
				setter: function(val) {
					if(Y.Lang.isString(val)) {
						val = Y.JSON.parse(val);
					}
					return val;
				}
			},
			refFieldId : { },
			sheet : {},
			sheetHasData : { }
		},

		MFA_REGEXP: /\[(\d*)\]/
	});


	Y.extend(SheetTextField, Y.interview.TextField, {

		initializer : function() {
			var that = this,
				sheet;

			that.publish(OPEN_SHEET, {
				emitFacade: true
			});
			that.publish(DELETE_SHEET, {
				emitFacade: true
			});

			that.set('sheet', Y.UserCase.getSpreadsheetByField(that));  // get the data - if any.

			that._eventHandlers = [];
			that._eventHandlers.push( that.after('sheetChange', that._syncUI, that) );
		},

		_renderUI : function() {
			var instance = this,
                sheet = instance.get('sheet'),
				node = instance.get(NODE),
				box = Y.Node.create(DIV_TEMPLATE),
				buttonContainer = Y.Node.create(BUTTON_CONTAINER_TEMPLATE),
				refFieldName = instance.get('spreadsheetRefFieldName'),
				refName, refField, ref, name = instance.get('node-name'),
				labelNode,
                sheetHasData = (sheet && sheet.data && 0 != sheet.data.length);

            if(!sheetHasData) {
                node.set('value','');
            }

			SheetTextField.superclass._renderUI.apply(instance, arguments);

			box.addClass( YCNM.getClassName(SheetTextField.NAME, 'fieldBox') );

			node.replace(box);
			box.append(buttonContainer);
			box.append(node);

			labelNode = instance.parentNode.one('label');
			labelNode.setAttribute('for', instance.node.get('id'));

			if( refFieldName ) {
				refName = 'field_' + refFieldName;
				if( SheetTextField.MFA_REGEXP.test(name) ) {
					refName += SheetTextField.MFA_REGEXP.exec(name)[0];
				}
				ref = instance.parentNode.ancestor('.interviewFormContainer').one('input[name="'+refName+'"]');
				instance.set('refFieldId', ref.get('id') );
			}

			// prepare the ref field
			if(refFieldName) {
				instance._refField = refField = instance.get('form').fields[instance.get('refFieldId')];

                if(!sheetHasData) {
                    refField.node.set('value', '');
                    refField._syncValue();
                }

				if(!refField.ref ) {
					refField.plug(Y.interview.RefFieldPlugin);
				}
			}

			// keep a reference to the button container for later use.
			instance._buttonContainer = buttonContainer;
		},

		_fireOpenSheet : function(e) {
			var that = this;
			e.preventDefault();
			Y.log('fire: ' + OPEN_SHEET);

			that.fire(OPEN_SHEET, {
				'resultField' : that,
				'caption': that.get('spreadsheetTitle'), // mandatory
				'sheetConfig': that.get('spreadsheetConfig'),
				'refField' : that.get('form').fields[that.get('refFieldId')] // might be undefined
			});
		},

		_fireDeleteSheet : function(e) {
			var that = this;
			e.preventDefault();

			that.fire('confirmDelete', {
				msg:'Sind Sie sicher, dass Sie diese Tabelle l\u00F6schen m\u00F6chten?',
				callback : function(ok) {
					if( ok ) {
						Y.log('fire: ' + DELETE_SHEET);
						that.fire(DELETE_SHEET, {'field': that});
					}
				}
			});

		},

		_bindUI : function() {
			var that = this;

			SheetTextField.superclass._bindUI.apply(that, arguments);

			// The field is not visible or accessible we need to set the focus on our own.
			that._eventHandlers.push(that.parentNode.on('click', that._fireFocus, that));

			// fire open and delete actions on button clicks.
			that._eventHandlers.push(that._buttonContainer.delegate('click', that._fireOpenSheet,
					'.ui-spreadsheet-open', that));
			that._eventHandlers.push(that._buttonContainer.delegate('click', that._fireDeleteSheet,
					'.ui-spreadsheet-delete', that));
		},

		_getHint : function(hasData) {
			// We'll try without the hint text for now, assuming that it is self-explanatory.
			// Also, on pages with 10+ tables fields (abundant in EÜR), the repeated hint text is very irritating.
			return '';
			// if( hasData ) {
			// 	return 'Mit den Buttons k\u00F6nnen Sie den Tabelleninhalt bearbeiten oder entfernen.';
			// } else {
			// 	return 'Mit \u00BBIn Tabelle erfassen\u00AB k\u00F6nnen Sie mehrere Positionen tabellarisch eintragen.';
			// }
		},

		_syncUI : function() {
			// find the 'real' ref field - if one is configured.
			var that = this,
				sheet = that.get('sheet'),
				sheetHasData = (sheet && sheet.data && 0 != sheet.data.length),
				refField = that._refField,
				innerButtonContainer = that._buttonContainer.one('div');

            //this sync is non-standard, we have to restore the saved state to get modifications on existing sheets correctly
            var tmp = that.get(SAVED_VALUE);

			SheetTextField.superclass._syncUI.apply(that, arguments);
            if(tmp != null) {
                that.set(SAVED_VALUE, tmp);
            }

			// update the status
			if( sheetHasData ) {
				that.set(READ_ONLY, true);
				if(refField) {
					// if we have data in the spreadsheet add the caption as reference
					refField.ref.setRefValue(sheet.id, sheet.caption);

				}
				// insert the button(s) depending on the state.
				innerButtonContainer.setHTML(EDIT_SHEET_TEMPLATE);
				innerButtonContainer.addClass('case-interview-field-input-tool-btns-attached');
				// show the input node
				that.node.removeClass('ui-display-none');
				// mark the button container to not be inline
				that._buttonContainer.removeClass('case-interview-field-input-tool-container-inline');
			} else {
				that.set(READ_ONLY, false);
				if(refField && sheet) {
					refField.ref.removeRefValue(sheet.id);
				}
				// insert the button(s) depending on the state.
				innerButtonContainer.setHTML(OPEN_SHEET_TEMPLATE);
				innerButtonContainer.removeClass('case-interview-field-input-tool-btns-attached');
				// hide the input node
				that.node.addClass('ui-display-none');
				// mark the button container to be inline
				that._buttonContainer.addClass('case-interview-field-input-tool-container-inline');

			}

		},

		destructor : function() {
			Y.Array.each(this._eventHandlers, function(it){
				it.detach();
			});
		}
	});

	Y.namespace("interview").SheetTextField = SheetTextField;

// --- CLASS LAUNCHER FIELD   ---------------------------------------------------------------------------------------

	var OPEN_LAUNCHER     = 'openLauncher',
		DELETE_LAUNCHER   = 'deleteLauncher';

	/**
	 * Constructor
	 */
	function LauncherField() {
        LauncherField.superclass.constructor.apply(this, arguments);
	}

	Y.mix(LauncherField, {
		NAME: "LauncherField"
	});


	Y.extend(LauncherField, Y.interview.TextField, {

		initializer : function(config) {
			var that = this;

			that.publish(OPEN_LAUNCHER, {
				emitFacade: true
			});
			that.publish(DELETE_LAUNCHER, {
				emitFacade: true
			});

            that._eventHandlers = [];

            that.label = config.launcher + ' öffnen';
			that._launcherName = config.launcher;
		},

		_renderUI : function() {
			var instance = this,
				node = instance.get(NODE),
				box = Y.Node.create('<div></div>'),
				buttonContainer = instance._buttonContainer = Y.Node.create('<div class="case-interview-field-input-tool-btns case-interview-field-input-tool-btns-attached ui-case-interview-field-input-tool-btns"></div>'),
                labelNode;

            LauncherField.superclass._renderUI.apply(instance, arguments);

			box.addClass( YCNM.getClassName(LauncherField.NAME, 'fieldBox') );

			node.replace(box);
			box.append(buttonContainer);
			box.append(node);

			labelNode = instance.parentNode.one('label');
			labelNode.setAttribute('for', instance.node.get('id'));
		},

		_fireOpen : function(e) {
			var that = this;
			e.preventDefault();

			Y.log('fire: ' + OPEN_LAUNCHER);

			that.fire(OPEN_LAUNCHER, {
				'field' : that,
				'label' : that._launcherName
			});
		},

		_fireDelete : function(e) {
			var that = this;
			e.preventDefault();

			that.fire('confirmDelete', {
				msg:'Sind Sie sicher, dass Sie diese "'+that._launcherName+'" l\u00F6schen m\u00F6chten?',
				callback : function(ok) {
					if( ok ) {
						Y.log('fire: ' + DELETE_LAUNCHER);
						that.fire(DELETE_LAUNCHER, {'field': that});
					}
				}
			});

		},

		_bindUI : function() {
			var that = this;

            LauncherField.superclass._bindUI.apply(that, arguments);

			that._eventHandlers.push(that.parentNode.on('click', that._fireFocus, that));
			that._eventHandlers.push(that._buttonContainer.delegate('click', that._fireOpen, '.ui-launcher-open', that));
			that._eventHandlers.push(that._buttonContainer.delegate('click', that._fireDelete, '.ui-launcher-delete', that));
		},

        _syncUI : function() {
            // find the 'real' ref field - if one is configured.
            var instance = this;

            LauncherField.superclass._syncUI.apply(instance, arguments);

            instance.set(READ_ONLY, true);
            instance._buttonContainer.setHTML('<button type="button" class="btn btn-orange ui-launcher-open">'+instance.label+'</button>'+
                '<button type="button" class="btn btn-s btn-destructive ui-launcher-delete" title="Löschen"><i class="fa fa-trash-o"></i><span class="app-a11y-label">Löschen</span></button>');

            instance.node.addClass('ui-display-none');
            instance._buttonContainer.one('.ui-launcher-delete').addClass('ui-display-none');
        },

		destructor : function() {
			Y.Array.each(this._eventHandlers, function(it){
				it.detach();
			});
		}
	});

	Y.namespace("interview").LauncherField = LauncherField;


// --- CLASS SELECT FIELD   -------------------------------------------------------------------------------------------


	/**
	 * Constructor
	 */
	var SelectField = function( config ) {
		SelectField.superclass.constructor.apply(this, arguments );
	};


	Y.extend(SelectField, Y.interview.Field,

			/**
			 * Prototype fields/methods
			 */
			{
				/**
				 * Standardfehlermeldung eines Select-Pflichtfelds.
				 */
				ERROR_MANDATORY: "Bitte w\u00E4hlen Sie aus.",

				/**
				 * Selected index.
				 */
				selectedIndex: 0,


				/**
				 * Initialisiert dieses Feld.
				 */
				initializer: function() {
					if(VERBOSE) {
						Y.log("init", null, "SelectField");
					}
				},

				_syncUI: function() {
					var instance = this;
					// call the parents sync method
					SelectField.superclass._syncUI.apply(instance,arguments);
					//read the current state
					instance._syncValue(); // sync the value
					instance.set(SAVED_VALUE, this.get(VALUE));
				},

				_syncValue: function() {
					this.selectedIndex = this.node.get('selectedIndex');
                    var nodeList = this.node.all("option");

					if( this.selectedIndex < nodeList.size() ) {
						this.set('value', nodeList.item(this.selectedIndex).get('value') );
					}
				},

				_handleChange: function() {
					this.check();
					this._fireChange();
				},

				_bindUI: function() {
					SelectField.superclass._bindUI.apply(this, arguments );
					this.node.on('change', this._handleChange, this );
					this.on('valueChange', this._handleValueChange, this);
				},
				 _handleValueChange: function(e) {
					 Y.log("Value changed! Source of change was: " + e.src, 'debug', 'SelectField');
					 // only set the value if the change came from the inside.
					 if( e.src ) {
						 // The new value might be a value or an label.
						 var val = e.newVal, nodeList = this.node.all("option"), found = false;

						 for (var i = 0; i < nodeList.size(); i++) {
							 var option = nodeList.item(i), oValue = option.get('value');
							 // first run: try to set the value as value
							 if( (option.get('value') && option.get('value') == val) ||
								     // second run: now try to find a matching label
								     (option.get('innerHTML') && option.get('innerHTML') == val) ) {
								 this._selectIndex(i);
								 found = true;
								 break;
							 }
						 }

						 if(!found && val == "") {
							 this._selectIndex(0);
						 }

                         if('tool' === e.src ) {
                            this.check();
                         }
					 }

                     this._removeShallow();
				 },

				 _selectIndex: function (index) {
					 this.node.set('selectedIndex', index, {src:'field'});
					 this._syncValue();
				 }

			},

			{
				NAME: "SelectField"
			}
	);

	Y.namespace("interview").SelectField = SelectField;




// --- CLASS PICK LIST FIELD   -------------------------------------------------------------------------------------------

	/**
	 * Eine Erweiterung der "normalen" select-Felder.
	 *
	 * Constructor
	 */
	var PickListField = function( config ) {
		PickListField.superclass.constructor.apply(this, arguments );
	};


	Y.mix(PickListField, {

		NAME: "PickListField",

		ATTRS: {
			// if the data list is present there won't be any ajax request
			list: {
				data: {
					value : null
				},
				uri : {
					value : null
				}
			},

			datasource : {
				value : null
			},
			schema : {
				value : {
					resultListLocator: 'data',
					resultFields: ["label", "value"]
				}
			},
			boundingBox : {
				value : null
			},
			autocomplete : {
				writeOnce : true
			}
		}
	});


	Y.extend(PickListField, Y.interview.TextField, {
				/**
				 * Standardfehlermeldung eines Choose-Pflichtfelds.
				 */
				ERROR_MANDATORY: "Bitte tragen Sie einen Wert ein oder w\u00E4hlen Sie einen aus der Liste aus.",

				/**
				 * Initialisiert dieses Feld.
				 */
				initializer: function() {
					if(VERBOSE) {
						Y.log("init", null, "PickListField");
					}

					// create a datasource
					this._createDataSource();
				},

				_createDataSource: function() {
					var instance = this,
						schema = instance.get('schema'),
							ds;
					// local or remote?
					if( null != instance.get('list.data') ) {
						// local ds
						ds = new Y.DataSource.Local({source:instance.get('list')});
					    ds.plug({fn: Y.Plugin.DataSourceJSONSchema, cfg: { schema: schema }});
						instance.set('datasource', ds);
					}
				},

				_renderUI : function() {
					this._renderAutoComplete();
					this.parentNode.addClass("yui3-skin-sam");
				},

//
				_renderAutoComplete: function() {
					var instance = this,
						ds = instance.get('datasource'),
						input = instance.get(NODE);

					input.plug(Y.Plugin.AutoComplete, {
						align : {
							node : input,
							points: [Y.WidgetPositionAlign.TL, Y.WidgetPositionAlign.BL]
						},
						resultHighlighter: 'phraseMatch',
						resultFilters: 'phraseMatch',
						resultTextLocator: 'value',
						minQueryLength: 0,
						source: ds
					});
				},

				_bindUI : function() {
					PickListField.superclass._bindUI.apply(this,arguments);
					this.node.on('click', function() {
						this.node.ac.sendRequest('');
					}, this);
				},

                _blur : function() {

                    if(!this.get(NODE).ac.get('visible')) {
                        PickListField.superclass._blur.apply(this,arguments);
                    }
                },

				_syncUI : function() {
					var instance = this;
					PickListField.superclass._syncUI.apply(instance, arguments);
					//read the current value
					instance._syncValue(); // sync the value
					instance.set(SAVED_VALUE, instance.get(VALUE));
				},

                //use this for autocomplete-entries, not for normal values (spaces are removed)
				_setNodeValue : function(value) {
					value = value ? value.replace(/^\s+|\s+$/g, '') : value;
					this.node.ac.set(VALUE,value,{src:'field'});

                    Y.log("_setNodeValue called");
				},

				_syncValue: function() {
					this.set(VALUE, this.node.ac.get(VALUE));
				},

				_focus: function() {
					var instance = this,
						n = instance.node;
					PickListField.superclass._focus.apply(instance,arguments);
					if(VERBOSE) {
						Y.log("FOCUS", null, PickListField.NAME);
					}
					n.removeClass(CLASS_EMPTY_FIELD);
					n.select();
				},

                //TODO: SUPPORT-1186 / ONSE-5183 / ONSE-9498 --- TEST FOR RACE CONDITION!
                _afterNodeValueChange : function(e) {
                    var instance = this;

                    Y.log("PickListField: _afterNodeValueChange called. newVal: "+ e.newVal);

                    //handle normal value changes here
                    instance.node.ac.set(VALUE,e.newVal,{src:'field'});
                    instance._syncValue();
                    instance.check(true);

                    PickListField.superclass._afterNodeValueChange.apply(instance,arguments);
                }

			}
	);


	Y.namespace("interview").PickListField = PickListField;


// --- CLASS CHECKBOX FIELD   -------------------------------------------------------------------------------------------


	/**
	 * Constructor
	 */
	var CheckboxField = function( config ) {
		CheckboxField.superclass.constructor.apply(this, arguments );
	};


	Y.extend(CheckboxField, Y.interview.Field,

			/**
			 * Prototype fields/methods
			 */
			{
				checked: false,

				_radioNode: null,
				_radiosHandlers : [],

				/**
				 * Standardfehlermeldung eines Checkbox-Pflichtfelds.
				 */
				ERROR_MANDATORY: "Bitte w\u00E4hlen Sie dieses Feld an.",


				/**
				 * This will transfer any events from a radio button to our actual chceckbox and vis versa.
				 *
				 * @param radio The radio button.
				 * @private
				 */
				_bindRadio: function(radio) {
					var that = this,
						checkbox = that.node;

					radio.on('click', function(){
                        if(checkbox.get('checked')) {
                            radio.set('checked', false);
                        }

						checkbox.set('checked', radio.get('checked'));
						that._syncValue();
						that.touch();
					});

					that._radiosHandlers.push( that.after('valueChange', function(e) {
						if(""=== e.newVal) {
							radio.set('checked', false);
						}
					}));

					// The field is not visible or accessible we need to set the focus on our own.
					that._radiosHandlers.push( that.parentNode.on('click', that._fireFocus, that));


				},

				/**
				 * This will render or destroy out radio node and show/hide the checkbox node.
				 *
				 * @param e
				 * @private
				 */
				_afterRadioChange : function(e) {
					var that = this,
						checkbox = that.node,
						radio = that._radioNode;

					if(e.newVal) {
						// radio is turned on.
						// create a radio button
						radio = that._radioNode = Y.Node.create('<input type="radio">');
						// sync the current value
						radio.set('checked', checkbox.get('checked'));
						//honor readonly state
						if(checkbox.get("disabled")) {
							radio.setAttribute('disabled',"disabled");
						}
						// bind it to our checkbox
						that._bindRadio(radio);
						// render it.
						checkbox.get('parentNode').insertBefore(radio, checkbox);
						checkbox.hide();
					} else {
						// radio is turned off.
						if( radio ) {
							radio.remove(true);
						}
						Y.Array.each(that._radiosHandlers, function(it){it.detach();});
						checkbox.show();
					}
				},

				/**
				 * Initialisiert dieses Feld.
				 */
				initializer: function() {
					var that = this;
					if(VERBOSE) {
						Y.log("init", null,"CheckboxField");
					}
					that._listenerHandlers.push( that.after('radioChange',  that._afterRadioChange, that));
				},

				/**
				 * Da eine checkbox auch über script gesetzt werden kann - und wir das derzeit noch nicht
				 * mitbekommen. Müssen wir vor dem Abschicken noch einmal schnell den Status synchronisieren.
				 */
				readyForSubmit : function() {
					var instance = this;
					instance._syncValue();
					return CheckboxField.superclass.readyForSubmit.apply(instance,arguments);
				},

				_syncUI: function() {
					var instance = this;
					CheckboxField.superclass._syncUI.apply(instance, arguments);
					instance._syncValue();
					instance.set(SAVED_VALUE, instance.get(VALUE));
				},

				_syncValue: function() {
					var instance = this;
					instance.checked = instance.node.get('checked');

					var toggleNode = instance.node.ancestor('.case-interview-field-input-chooser');

                    if(toggleNode && instance.parentNode.hasClass(CLASS_READ_ONLY)) {
                    	toggleNode.setStyle('opacity', '0.5');
                    }

					if(instance.checked) {
						instance.set(VALUE, instance.node.get(VALUE));

						if(toggleNode) {
							if (!toggleNode.one('.case-interview-field-input-chooser__positive').hasClass('is-active')) {
								toggleNode.one('.case-interview-field-input-chooser__positive').addClass('is-active');
							}
							toggleNode.one('.case-interview-field-input-chooser__negative').removeClass('is-active');

                            toggleNode.one('.case-interview-field-input-chooser__positive').setAttribute('aria-checked', 'true');
                            toggleNode.one('.case-interview-field-input-chooser__negative').setAttribute('aria-checked', 'false');
						}
					} else {
						instance.set(VALUE, "");

						if(toggleNode) {
							toggleNode.one('.case-interview-field-input-chooser__positive').removeClass('is-active');

							if (!toggleNode.one('.case-interview-field-input-chooser__negative').hasClass('is-active')) {
								toggleNode.one('.case-interview-field-input-chooser__negative').addClass('is-active');
							}

                            toggleNode.one('.case-interview-field-input-chooser__positive').setAttribute('aria-checked', 'false');
                            toggleNode.one('.case-interview-field-input-chooser__negative').setAttribute('aria-checked', 'true');
						}
					}

				},

				_handleChange: function() {
					this.check();
					this._fireChange();
				},

				_bindUI: function() {
					CheckboxField.superclass._bindUI.apply(this, arguments );
					this._listenerHandlers.push( this.node.after('click',  this._handleChange, this));

                    //ONSE-11839: registering another handle for the surrounding box so tapping is easier
                    var cn = this.node.ancestor('.case-interview-field-input-checkbox-radio-container');

					if(cn) {
                        this._listenerHandlers.push( cn.after('click',  function(e) {

                        	if(e.target.hasClass('case-interview-field-input-checkbox-radio-container')) {
                                console.log("Synthetic click action commenced.");

                                e.target.one('input').getDOMNode().click();
							}

						},this));
					}
					///additional listener
				}


			},

			{
				NAME: "CheckboxField",
				ATTRS : {
					/**
					 * This is a special marker which - when set to true - will render a radio button instead of the
					 * checkbox.
					 */
					radio : {
						value: false,
						validator: Y.Lang.isBoolean
					}
				}

			}
	);


	Y.namespace("interview").CheckboxField = CheckboxField;



}, '1.0.0', {requires:[
	'autocomplete',
	'autocomplete-filters',
	'autocomplete-highlighters',
	'datasource-jsonschema',
	'datasource-local',
	'event',
	'event-custom',
	'event-focus',
	'event-valuechange',
	'interview-format-factory',
	'interview-ref-field-plugin',
	'interview-autosuggest-overwrite-plugin',
	'json',
	'smart-formats',
	'user-case'
]} );

