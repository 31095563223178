YUI.add('smart-handlebars-templates', function (Y, NAME) {

"use strict";

Y.namespace("interview").HandlebarsTemplates = Y.Base.create(NAME, Y.Base, [], {

	initializer : function() {

		//console.log("handlebars init");

		Y.io('/js/templates-'+SMST_config.onseClientName+'.js', {
			on: {
				success: function (id, res) {
					eval(res.responseText);
				},
				failure: function () {
					Y.log('Error loading handlebars js!');
				}
			},
			context: this,
			sync: true //first load templates, then create views
		});
	},

	lookup: function(name, isPartial) {

		// check if we have to register this template as a partial
		if(isPartial && !Y.Handlebars.partials[name]) {
			Y.Handlebars.registerPartial(name, this.HBS[name]);
		}

		return this.HBS[name];
	},

	append: function( templateName, node, args ) {
		var template = this.lookup(templateName);
		node.append(args ? template(args) : template());
	}

});

// register common helpers
Y.Handlebars.registerHelper('json', function(obj) {
	return JSON.stringify(obj, null, 2);
});

Y.Handlebars.registerHelper('with', function(context, options) {
	return options.fn(context);
});

Y.Handlebars.registerHelper('toJSON', function(object){
	return new Y.Handlebars.SafeString(JSON.stringify(object));
});

// remove any headlines, tables or lists
Y.Handlebars.registerHelper('chopHtml', function(passedString, maxIndex) {
	var cleanString = passedString
			.replace(/\n/g, ' ')
			.replace(/<table[\s\S]*<\/table>/g, '@@')
			.replace(/<ul[\s\S]*<\/ul>/g, '@@')
			.replace(/<ol[\s\S]*<\/ol>/g, '@@')
			.replace(/<h\d>.*<\/h\d>/g, ' ')
			.replace(/<[^>]*>/g, ' ');
	var firstIndexOfRemovedElement = cleanString.indexOf('@@');
	var lastIndexOfSpace = cleanString.lastIndexOf(' ', maxIndex);
	var usedIndex = Math.min(firstIndexOfRemovedElement, lastIndexOfSpace);
	var toIndex = usedIndex == -1 ? maxIndex : usedIndex;
	var theString = cleanString.substring(0,toIndex);
	return new Y.Handlebars.SafeString(theString + "…")
});


}, '1.0.0', {"requires": ["base-build", "handlebars-base", "handlebars-compiler", "io"]});
