/**
 * Widget to render the preview of a report.
 */
YUI.add('analysis-preview', function(Y) {

    /**
     * The Constructor
     * @param cfg
     */
    var AnalysisPreview = function(cfg) {
        AnalysisPreview.superclass.constructor.apply(this,arguments);
    };

    /**
     * Static properties and methods
     */
    Y.mix( AnalysisPreview, {
        // name required by YUI
        NAME: 'analysisPreview',
        // our attributes
        ATTRS : {
            // The data of this preview
            data : {
                value : '',
            }
        }
    });

    // now we will create the widget.
    Y.extend( AnalysisPreview, Y.Widget, {

	    _handler: [],

	    _reportPreview : true,

	    destructor : function() {
		    Y.Array.each( this._handler, function(it) {it.detach()});
	    },

        renderUI: function() {
        },

        bindUI: function() {
	        this._handler.push( this.after('dataChange', this._destroySpreadsheet, this));
        },

	    syncUI: function() {
		    this.update('<div id="case-editor-analysis-report-preview-body-placeholder">Wählen Sie eine Berechnungsliste, einen Musterbrief oder ' +
				    'eine Tabelle aus, um hier eine Vorschau zu erhalten.</div>');
        },


	    /**
         * Used to update this preview.
         * @param data
         */
        update : function(data) {
			var box =  this.get('contentBox');
			box.setContent('');
			if( Y.Lang.isObject(data) && data.render ) {
				// is an appendix
				this._reportPreview = false;
				data.render(box);
			} else {
				// is report
				this._reportPreview = true;
				box.setContent(data);
			}
			return data;
		},

		selectSheet: function (originalSheet) {
			var sheetId = originalSheet.id,
				sheetDefinitionId = sheetId.substring(0, sheetId.indexOf('-')),
				data = Y.UserCase.getSpreadsheet(sheetId);
			if (!data) {
				data = originalSheet
			}

			var definition = Y.Spreadsheets.get('definitions').getDefinition(sheetDefinitionId, data.caption);
			definition.readOnly = true;

			var tmp;

			if(sheetId.indexOf("[") >= 0 && sheetId.indexOf("]") >= 0) {
				tmp = sheetId.substring(sheetId.indexOf("[")+1, sheetId.indexOf("]"));
			}

			//ONSE-11585
			Y.log("Overriding sheet mfaIndex: "+tmp);

			Y._overrideSheetMultiFieldIndex = tmp;

			var sheet = Y.Spreadsheets.createSpreadsheet(definition, data.data, data);

			Y._overrideSheetMultiFieldIndex = null;

			this.update(sheet);
		},

		selectReport:function (reportId, assessmentType , sessionId) {
			var widget = this,
				src = 'reports/render.html?useSessionCase=true&use'
				+ assessmentType.toUpperCase() + "=true&sessionId=" + sessionId + "&id=" + reportId

			var cfg = {
				on: {
					success: function (id, o) {
						var node = Y.Node.create(o.responseText),
							trimmedResponse = node.one('.case-editor-report-table-container');
						widget.update(trimmedResponse);

					}
				}
			}
			// set the preview in waiting mode...
			widget.wait();
			Y.io(src, cfg)
		},


		wait: function() {
			this.update('<h1 class="case-editor-waiting">Bitte warten…</h1>');
	    },

	    _destroySpreadsheet : function(e){
		    var prev = e.prevVal;
		    if( prev && prev.destroy )
			    prev.destroy();
	    }
    });

    // added to the right namespace
    Y.namespace("analysis").AnalysisPreview = AnalysisPreview;

}, '1.0.0', { requires:['widget']} );
