YUI.add('case-models', function(Y) {
    "use strict";

	var YNumberParse = Y.Number.parse,
		YNumberFormat = Y.Number.format,
		FORMAT_CFG = {decimalPlaces:2, decimalSeparator:',',thousandsSeparator:'.',suffix:' €'};

    Y.Case = Y.Base.create('Case', Y.Model, [Y.ModelSync.REST], {

        root: '/api/case',

		initializer : function() {
			this.on('Case:error', function(e) {
				//ONSE-10695 on error a reload can fix it. make it safer with a small timeout to migitate damage by a potential infinite loop
				if(e && e.src && e.src == 'load') {
					window.setTimeout(function() {window.location.href = '/app';}, 8000);
				}
			});
		},


		// we need to overwrite the serialization as we have to remove some extra attributes when it comes to talking
        // to the server.
        serialize: function () {
            var json = this.toJSON();

            delete json.active;
            delete json.edited;
            delete json.waiting;
            delete json.wasLocked;
            delete json.mayTransfer;
            delete json.activeYearCase;
            delete json.refund;
            delete json.caseTypeLabel;
            delete json.euer;
            delete json.isEST;
            delete json.calculationNum;
            delete json.showOptions;
			delete json.lastModified;
			delete json.autoOpen;
			delete json.transferDate;
			delete json.isVV;
            delete json.openURL;

            return Y.JSON.stringify(json);
        }
    }, {
        ATTRS: {
            // id is part of the parent class and does not need to be listed here.
            name :            {value : null },
            type :            {
                setter : function(val) {
                    this.set('isEST', val == 'EST');

                    return val;
                }
            },
            percentComplete : {value : 0 },
            year :            {value : '2020' },
            charged:          {value : false},
	        // calculation is stored as a string which is not very useful for us. We have to do some extra work here
	        // to make displaying the result easier.
	        calculation:      {
		        setter : function(val) {
                    if(!val) {
                        this.set('calculationNum',0);
                        return YNumberFormat(0, FORMAT_CFG);
                    }
			        // the result comes in as a german number. We have to change some chars.
			        var num = val.replace('.','').replace(',','.');
					num = YNumberParse(num);
			        if( null === num || 0 === num ) {
				        this.set('refund', true);
				        num = 0;
			        } else if ( 0 < num ) {
				        this.set('refund', true);
			        } else {
				        this.set('refund', false);
				        num = Math.abs(num);
			        }
                    this.set('calculationNum',num);
			        return YNumberFormat(num, FORMAT_CFG);
		        }

	        },
            calculationNum:   { // the calculation result as a number
                broadcast: 1,
                value: 0
            },
            locked:           {value : false},
            caze:             {value : null},
            dirtyCaze:        {value : null},
            errors:           {value : 0},
            hints:            {value : 0},
            todos:            {value : 0},
            isEST:            {value: true},
            currentAreaId:    {value : -1},
            showOptions :     {value : false},
			transferDate :	  {value: null},

	        // helper for the rendering only
            // This fields are removed before this case gets send to the server. (see serialize)
	        active : { value:false },
	        activeYearCase : {getter: function() { return this.get('year') == '2020'}},
	        edited: {
                getter : function() {
                    return this.get('percentComplete') > 0;
                }
            },
	        mayTransfer : {getter: function() { return this.get('year') == '2019'}},
	        refund : {value:true},
	        waiting : { value:false },
	        wasLocked : {value: false},
            caseTypeLabel: {
                getter : function() {
                    return this.get('type') == 'EUER' ? 'EÜR: ' : '';
                }
            },
			lastModified: {
				value:null
			},

	        childSessions: {
		        // check if there are child sessions of type euer assigned to this case.
		        setter: function(val) {
			        var i;
			        if(val) {
				        Y.Array.some(val, function(it) {
					        if( it.type == 'EUER' ) {
						        this.set('euer', true);
						        return true;
					        }
				        }, this);
			        }
		        }
	        },
	        euer: {},
			autoOpen: {
				value : false
			},
			allResults: [],
			isVV : false,
            openURL :	  {value: null},
        }
    });

    Y.CaseList = Y.Base.create('CaseList', Y.ModelList, [Y.ModelSync.REST], {

        // By convention Y.User's root will be used for the lists' URL.
        model: Y.Case,

        // we are going to listen for some events here, so we have to create an initializer for this model list.
        initializer : function() {
            this.on('Case:activeChange', function(e){
                // before a new case gets activated we are silently deactivating any other case.
                if(e.newVal) {
                    this.invoke('_set', 'active', false);
                }
            });
        },

        // Custom comparator to keep cases in alphabetical order.
        comparator: function (model) {
            return model.get('name');
        }

    }, {});

    Y.CaseLegacyList = Y.Base.create('CaseLegacyList', Y.ModelList, [Y.ModelSync.REST], {

        url : '/api/case/legacyList',

        model: Y.Case

    }, {});

	(function(){

		var Slang = Y.smst.Lang;

		/**
		 * This class represents the application state when a case is loaded and the editor is active.
		 */
		Y.CaseState = Y.Base.create('CaseState', Y.Base, [], {

			_eventHandler : [],

			initializer : function() {
				var that = this;
			},

			destructor : function() {
				Slang.detachEventListener(this._eventHandlers);
			},

			// --- event handlers -------------------------------------------------------------------------------------


			// --- public api -----------------------------------------------------------------------------------------

			load : function(callback, fetchCaseData) {
				var that = this,
					cfg = {
						on:{
							success:function (id, res) {
								that.parseAndUpdate( res.responseText );
								callback();
							},
							failure:function () {
								Y.log("Request failed or was aborted", 'warn', 'CaseState');
							}
						},
						data:{caze:(fetchCaseData===true)},
						context:that
					};

				Y.log('Loading…', 'DEBUG', 'CaseState');
				Y.io("api/state", cfg);

			},

			parseAndUpdate : function(rawData){
				var data = this._parse(rawData);
				this._set('parsed', data);
			},

			// --- protected api  -------------------------------------------------------------------------------------

			_parse : function(rawData) {
				var data;

				Y.log('Parsing…', 'DEBUG', 'CaseState');
				data = Y.JSON.parse(rawData);

				if(data.next) {
					data.next = Y.JSON.parse(data.next);
				}
				// if there is new user case in this response update the UserCase.
				if(data.usercase) {
					Y.log('Updating user case.', 'DEBUG', 'CaseState');
					Y.UserCase.update(Y.JSON.parse(data.usercase));
					// remove the user case from the current nav information
					delete data.usercase;

                    Y._currentState = data;

					// now let's mark this state as changed (this is equal to "hasPost" and "reloadHints")
					data.caseChanged = true;
				}

				return data;
			}

		}, {
			ATTRS : {
				parsed : {
					readOnly: true
				}
			}

		});
		/*
		 requires:
		 base-build,
		 io-base,
		 json-parse,
		 smst-lang,
		 user-case
		 */
	})();

}, '1.0.0', {
	requires:[
		"base-build",
		'datatype-number',
		"io-base",
		"json-parse",
		'model',
		'model-sync-rest',
		'smst-lang',
		'user-case'
	]
});