YUI.add('case-editor-app-summary', function(Y) {
    "use strict";

    var Slang = Y.smst.Lang;

    Y.CaseEditorSummaryView = Y.Base.create('CaseEditorSummaryView', Y.HandlebarsView, [], {

        template : 'case-editor-summary-container',
        partials : ['common-registerCTA', 'case-editor-issues-item']

    }, {
        ATTRS: {
            container: {
                valueFn : function(){
                    return Y.Node.create('<div id="case-editor-summary-view" />');
                }
            }
        }
    });

    Y.CaseEditorSummaryInitialView = Y.Base.create('CaseEditorSummaryInitialView', Y.HandlebarsView, [], {

        template : 'case-editor-summary-start'

    }, {
        ATTRS: {
            container: {
                valueFn : function(){
                    return Y.Node.create('<div id="case-editor-summary-initial" />');
                }
            }
        }
    });


    Y.CaseEditorAppSummary = Y.Base.create('CaseEditorAppSummary', Y.smst.CaseEditorAppBase, [], {
        views: {
   		    initial: {type:'CaseEditorSummaryInitialView'},
   		    smartCheck : {type:'CaseEditorSummaryView'}
   	    },
        _handler : [],
	    _anonymous : true,

        initializer : function() {
	        var that = this;

	        Y.onceAfter('smartsteuer:ready', function (e) {
		        that._anonymous = e.anonymous;

                if(that._anonymous) {
                    that._handler.push(Y.delegate('click', function(e) {
           		        that.fire(
           		            'alert',
                            {
                                msg: 'Bitte legen Sie ein Benutzerkonto an, um fortzufahren.',
                                callback: function() {
                                    that.fire('openRegistration');
                                }
                            }
                        );
           	        }, 'body', '.ui-case-editor-summary-disabled-next', that));
                }
                else {
                    that._handler.push(Y.delegate('click', function(e) {
           		        that.fire('alert', {msg: 'Bitte bearbeiten Sie erst alle Probleme und möglichen Probleme.'});
           	        }, 'body', '.ui-case-editor-summary-disabled-next', that));
                }

                that._handler.push(Y.delegate('click', function(e) { that._autoFill(e); }, 'body', '.ui-hint-autofill', that));
                that._handler.push(Y.delegate('click', function(e) { that._itemHide(e); }, 'body', '.ui-item-hide', that));
                that._handler.push(Y.delegate('click', function(e) { that._itemUnHide(e); }, 'body', '.ui-item-unhide', that));

                that._handler.push(Y.delegate('click', function(e) { that._startSmartCheck(e); }, 'body', '.ui-case-editor-summary-start', that));

                that._handler.push(Y.delegate('click', function(e) {
                    var node = e.target.get('parentNode').get('parentNode').one(".case-editor-smartcheck-issue-list-help");
                    if(node) {
                        if(node.getStyle('display') == 'none') {
                            node.setStyle('display', 'block');
                        }
                        else {
                            node.setStyle('display', 'none');
                        }
                    }

                }, 'body', '.ui-toggle-smartcheck-help', that));

                //that._handler.push(Y.delegate('click', function(e) { that._hintsUnHide(e); }, 'body', '.ui-hints-unhide', that));
                //that._handler.push(Y.delegate('click', function(e) { that._warningsUnHide(e); }, 'body', '.ui-warnings-unhide', that));
                //that._handler.push(Y.delegate('click', function(e) { that._hintsHideAll(e); }, 'body', '.ui-hints-hideall', that));
                //that._handler.push(Y.delegate('click', function(e) { that._warningsHideAll(e); }, 'body', '.ui-warnings-hideall', that));

	        }, that);

            that.publish('smartCheckActive', {
                emitFacade: true,
                preventable: false
            });

        },

        destructor : function() {
            Slang.detachEventListener(this._handler);
        },

        render: function () {
            this.constructor.superclass.render.apply(this);

            return this;
        },

        handleNavigation : function(navInfo) {

            if(!Y.smartCheckMode) {
                this.showView('initial');
                this.fire('removeWaiting');

                return;
            }

            this.fire('waiting',{msg:'Bitte warten…'});

            var cfg = {
                on: {
                    success: function(id, o) {
                        this._handleSuccess(id, o);

                        if(Y.one("#case-editor-main-controls")) {
                            Y.one("#case-editor-main-controls").setStyle('display', 'none');
                        }
                    },
                    failure: function() {
                        this.fire('alert', {msg:"Fehler bei der Anfrage an den Server!"});
                    }
                },
                context: this
            };

            Y.io("api/hints?logErrors=true&smartCheck=true", cfg);

        },

        _startSmartCheck : function() {
            Y.smartCheckMode = true;

            this.fire('open', {link:Y.smst.CaseEditor.SECTIONS.getByName('summary').get('link')});
        },


        _createLinks : function(arr, lst) {
            Y.Array.each(lst, function(it) {

                it.section = 'interview';
                it.area = it.id;
                it.id = Y.stamp(it);
                it.multiFieldIndex = it.multiFieldIndex;
                it.hmfas = it.type != "multiFieldBase";
                it.hmfoas = it.type != "multiFormBase";
                it.plausi = true;

                it.areaName = this.redecode(it.areaName);

                it.link = this._createLink(it);

                arr.push(it);

            }, this);

            return arr;
        },

        _createLink : function(e, id, forceFormIndex1, forceMultiFieldIndex1) {

            if(e.command && e.command != '') {
                return e.formIndex+"/"+ e.command;
            }

            var formIndex = (forceFormIndex1 ? 1 : e.formIndex);

            var mfaIdx = (e.multiFieldIndex != undefined ? e.multiFieldIndex : '-1');

            if(forceMultiFieldIndex1) {
                mfaIdx = 0;
            }

            return Y.smst.CaseEditor.SECTIONS.getByName('interview').get('link') +
                   "/"+(id ? id : e.area)+
                   "/"+e.formId+
                   "/"+formIndex+
                   "/"+mfaIdx+
                   "/" + (forceMultiFieldIndex1 ? false : e.hmfas) +
                   "/" + (forceFormIndex1 ? false : e.hmfoas);
        },

        redecode : function(str) {
            return Y.smst.Utils.textAreaEscape(str);
        },

        _parse : function(arr, lst, isWarning, suppressed) {

            Y.Array.each(lst, function(it) {
                // link, long text, short text, backofficeExtras
                it.section = 'interview';

                if(this.config.boUser && it.fieldName) {
                    it.message = it.message + " ["+it.fieldName+"]";
                }

                // some transcriptions to meet the requirements of "open" events.
                it.area = it.areaId;
                it.hmfoas = true;
                it.multiFieldIndex = it.index;
                it.multiFieldIndex = it.index;
                it.hmfas = true;

                it.id = Y.stamp(it);

                if(!it.forceFormIndex1) {
                    it.forceFormIndex1 = [false];
                }

                if(!it.forceMultiFieldIndex1) {
                    it.forceMultiFieldIndex1 = [false];
                }

                it.link = this._createLink(it, null, it.forceFormIndex1[0], it.forceMultiFieldIndex1[0]);

                it.error = it.type.indexOf("ERROR") >= 0;
                it.plausi = !it.error;
                it.warning = it.type.indexOf("WARNING") >= 0;

                it.areaName = this.redecode(it.areaName);
                it.multiEntryLabel = this.redecode(it.multiEntryLabel);
                it.message = this.redecode(it.message);

                if(it.type.indexOf("ELSTER_ERROR") >= 0) {
                    it.areaData = []

                    Y.Array.each(it.areaIds, function(it2, idx) {
                        it.areaData.push({link: this._createLink(it, it2, it.forceFormIndex1[idx], it.forceMultiFieldIndex1[idx]), name: it.areaNames[idx], message: this.redecode(it.areaMessages[idx]), visible : it.areaVisibilities[idx]});

                        if(idx > 0) {
                            it.multipleAreas = true;
                        }
                    }, this);
                }

                if(isWarning) {
                    it.warning = true;
                }

                if(suppressed) {
                    it.suppressed = true;
                }

                arr.push(it);

            }, this);


            return arr;
        },

        _distinctMandatory : function(errors) {
            var ret = []
            var match = "Dies ist ein Pflichtfeld."; //TODO: put messages.properties in config object

            Y.Array.each(errors, function(err) {
                var hasMatch = false;

                Y.Array.each(ret, function(it2) {
                    if(it2.areaId == err.areaId && err.message == match && it2.message == match) {
                        hasMatch = true;
                        return;
                    }
                }, this);

                if(!hasMatch) ret.push(err);

            }, this);

            Y.Array.each(ret, function(err) {
                if(err.message == match) {
                    err.message = "In dem Bereich wurden noch nicht alle Pflichtfelder ausgefüllt.";
                    err.field = "";
                }
            }, this);

            return ret;
        },

        _handleSuccess : function(id, o ) {
            var that = this,
                data = Y.JSON.parse(o.responseText),
                errors = Y.JSON.parse(data.errors),
                sessionLinksWithErrors = Y.JSON.parse(data.sessionLinksWithErrors),
                hints = Y.JSON.parse(data.hints),
                warnings = Y.JSON.parse(data.warnings),
                suppressedHints = data.suppressedHints ? Y.JSON.parse(data.suppressedHints) : [],
                suppressedWarnings = data.suppressedWarnings ? Y.JSON.parse(data.suppressedWarnings) : [],
                unsubmittedAreas = data.unsubmittedAreas ? data.unsubmittedAreas : [];

            errors = that._distinctMandatory(errors);

            errors = errors.concat(Y.JSON.parse(data.elsterErrors));
            Y.globalErrors = errors;

            Y.globalErrors = Y.globalErrors.concat(hints);
            Y.globalErrors = Y.globalErrors.concat(warnings);

            if(data.suppressedErrors) {
                errors = errors.concat(Y.JSON.parse(data.suppressedErrors));
            }

            if(!sessionLinksWithErrors) {
                sessionLinksWithErrors = [];
            }

            that.showView('smartCheck', {
                hints: that._parse([],hints),
                suppressedHints: that._parse([],suppressedHints, false, true),
                warnings: that._parse([],warnings, true),
                suppressedWarnings: that._parse([],suppressedWarnings, true, true),
                errors: that._parse([],errors),
	            anonymous: that._anonymous,
                sessionLinksWithErrors : sessionLinksWithErrors,
                hasErrors: errors.length > 0 || sessionLinksWithErrors.length > 0,
                isEUER : data.caseType == "EUER",
                hintsOrSuppressed : suppressedHints.length != 0 || hints.length != 0,
                warningsOrSuppressed : suppressedWarnings.length != 0 || warnings.length != 0,
                unsubmittedAreas : that._createLinks([],unsubmittedAreas)
            });

            if(errors.length == 0) {
                Y._errorMode = false;
                Y._savedNavState = null;
            }
            if(hints.length == 0) {
                Y._hintsMode = false;
                Y._savedNavState = null;
            }
            if(warnings.length == 0) {
                Y._warningsMode = false;
                Y._savedNavState = null;
            }

            if(errors.length == 0 && sessionLinksWithErrors.length == 0 && warnings.length == 0 && !that._anonymous) {

                if(hints.length == 0) {
                    Y.one('#ui-case-editor-summary-ok').setStyle('display', 'block');
                }

                if(Y.one('#ui-case-editor-summary-details-report')) {
                    that.report = new Y.CaseEditorAppInterviewPageSummary();
                    Slang.detachEventListener(that.report.get('handlers'));

                    Y.once('reports-rendered', function() {
                        that._view.get('container').one('#ui-case-editor-summary-details-report').appendChild(that.report.get('container').getContent());
                        that._view.get('container').one('#ui-case-editor-summary-details').setStyle('display', 'block');
                    }, that);

                    var id = data.caseType == "EST" ? 170 : 268;

                    that.report._initPage({newVal : '<li class="case-editor-inline-report" data-id="'+id+'" id="case-editor-inline-report-170"></li>'});
                }

                Y.one('#case-editor-continue-container').one('.ui-case-editor-next').removeClass('ui-display-none');
                Y.all('.ui-issues-present').addClass('ui-display-none');
            }
            else {
                if(that._anonymous) {
                    Y.one('.ui-case-editor-summary-disabled-next').set('title', 'Bitte legen Sie ein Benutzerkonto an, um fortzufahren.');
                }

                Y.one('#case-editor-continue-container').one('.ui-case-editor-summary-disabled-next').removeClass('ui-display-none');
            }

            that.fire('removeWaiting');
            that.fire('smartCheckActive');
        },

        _autoFill : function(e) {
            this._loadAndRefresh(e, "api/hints?commands="+ encodeURIComponent(e.currentTarget.getData('commands'))+"&smartCheck=true");
        },

        _itemHide : function(e) {
            var wn = e.currentTarget.getData('warning') ? '&warnings=true' : '';
            this._loadAndRefresh(e, "api/hints?item="+ encodeURIComponent(e.currentTarget.getData('unique-id'))+"&smartCheck=true"+wn);
        },

        _itemUnHide : function(e) {
            var wn = e.currentTarget.getData('warning') ? '&warnings=true' : '';
            this._loadAndRefresh(e, "api/hints?unhide=true&item="+ encodeURIComponent(e.currentTarget.getData('unique-id'))+"&smartCheck=true"+wn);
        },

        /*
        _hintsUnHide : function(e) {
            this._loadAndRefresh(e, "api/hints?unhide=true&smartCheck=true");
        },

        _warningsUnHide : function(e) {
            this._loadAndRefresh(e, "api/hints?unhide=true&smartCheck=true&warnings=true");
        },

        _hintsHideAll : function(e) {
               this._loadAndRefresh(e, "api/hints?hideall=true&smartCheck=true");
        },

        _warningsHideAll : function(e) {
            this._loadAndRefresh(e, "api/hints?hideall=true&smartCheck=true&warnings=true");
        },
        */

        _loadAndRefresh : function(e, url) {
           e.halt();
           this.fire('waiting',{msg:'Bitte warten…'});

           var cfg = {
               on: {
                   success: function(id, o) {
                       //render first request and do state update
                       var cfg2 = {
                           on:{
                               success:function (id2, res) {
                                   this._handleSuccess(id, o);

                                   this.fire('updateState', {raw:res.responseText,src:'navigation'});
                               },
                               failure:function () {
                                   Y.log("Request failed or was aborted", 'warn', 'CaseEditorAppSummary');
                               }
                           },
                           context:this
                       };
                       Y.io("api/state?caze=true", cfg2);
                   },
                   failure: function() {
                       this.fire('alert', {msg:"Fehler bei der Anfrage an den Server!"});
                   }
               },
               context: this
           };

           Y.io(url, cfg);
       }

    }, {
        ATTRS : {

            container: {
                valueFn: function(){
                    return Y.Node.create('<div id="case-editor-summary" />');
                }

            }
        }
    });


}, '1.0.0', {
    requires:[
        'app',
        'base-build',
        'case-editor-app-base',
        'case-editor-app-interview-page-summary',
        'io-base',
        'node',
        'smst-lang',
        'smst-utils',
        'oop',
        'datatype-number'
    ]});